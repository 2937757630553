import React from 'react';
import * as PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const RenderSwitch = ({ helperText, id, name, label, onChange, input, value, className })=>{
  const handleChange = value => {
    let val = !!value.target.checked;
    onChange(name, val);
  }

  let helperTextId = helperText && id ? ''.concat(id, '-helper-text') : '' . concat('radio-', Math.floor(Math.random() * 100) + 1);
  return (
    <FormControlLabel
      className={className}
      id={helperTextId}
      label={label}
      control={
        <Switch {...input} checked={value} onChange={handleChange} value={true} />
      }
    />
  );
}

RenderSwitch.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  style: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,

  meta: PropTypes.object
};


export default RenderSwitch;
