import React, { Fragment } from "react";
import Base from './Base';
import { getTypeTable, pointCallback } from "./ultil";
import { QRPointType } from '../qrcode';
export class EdgeCutsBase extends Base {

	/**
	 * listPoints
	 * @param $options
	 */
	public listPoints($options = {}): any[]{
		let { "isRotation" : $isRotation, "params" : $params, "out" : $out }: any = $options || {};
		let { "size" : $size, "opacity" : $opacity, "otherColor" : $otherColor, "width" : $width }: any = $params || [];
		if (!this.$qrcode) return [];
		let $attributesClone;
		let $type = this.$type;
		let $nCount = this.$qrcode.getModuleCount();
		let $typeTable = getTypeTable(this.$qrcode);
		let $pointList: any[] = [];


		$size = ($size || 100) / 100;
		$opacity = ($opacity || 100) / 100;
		$otherColor = $otherColor || 'black';
		if ($size <= 0) $size = 1;


		let $scale = $width * ($size/100);
		$scale = $scale || 1;

		pointCallback(this.$qrcode, $nCount, $out, ($i: number, $j: number, $x: number, $y: number) =>{
			let $posX 	= $width * (($isRotation? ($nCount - 1 - $i):$i + (1 - $size)/2));
			let $posY 	= $width * ($j + (1 - $size)/2);
			let $nscale = `${($scale / 5)}`;

			let	$attributes = {
				"opacity" 	: $opacity,
				"fill"			: $otherColor,
				"transform" : `translate(${$posX},${$posY}) scale(${$scale})`
			}
			

				if (
					!($typeTable[$x][$y] === QRPointType.$POS_CENTER || $typeTable[$x][$y] === QRPointType.$POS_OTHER) &&
					(
						$typeTable[$x][$y] === QRPointType.$ALIGN_CENTER ||
						$typeTable[$x][$y] === QRPointType.$ALIGN_OTHER ||
						$typeTable[$x][$y] === QRPointType.$TIMING ||
						$typeTable[$x][$y] !== QRPointType.$POS_OTHER
					)
				) {

					let $rs = { "i" : $x, "j" : $y, "count" : $nCount, "xOffset" : 0, "yOffset" : 0 };

					let $leftNeighbor 	= +this.getNeighbor({ ...$rs, "xOffset" : $isRotation ? 1: -1 });
					let $rightNeighbor 	= +this.getNeighbor({ ...$rs, "xOffset" : !$isRotation ? 1: -1 });
					let $topNeighbor 		= +this.getNeighbor({ ...$rs, "yOffset" : -1 });
					let $bottomNeighbor = +this.getNeighbor({ ...$rs, "yOffset" : 1 });

					let $neighborsCount = $leftNeighbor + $rightNeighbor + $topNeighbor + $bottomNeighbor;

					let $path;

					switch ($neighborsCount){
						case 0:
							switch ($type) {
								case 2:
								case 8:
								case 9:
								case 10:
								case 12:
								case 13:
									$path = "M95,64.062c0,8.556-3.019,15.851-9.053,21.885S72.618,95,64.062,95H35.938c-8.554,0-15.85-3.019-21.884-9.053   S5,72.618,5,64.062V35.938c0-8.554,3.018-15.85,9.053-21.884S27.383,5,35.938,5h28.125c8.556,0,15.851,3.018,21.885,9.053   S95,27.383,95,35.938V64.062z";
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;

								case 3:
									let $attributesClone ={
										...$attributes,
										"transform" : `translate(${$posX},${$posY}) scale(${$nscale})`
									}

									$path = "M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z";
									$pointList.push(<g {...$attributesClone}><path d={$path}/></g>);
									break;

								case 4:
									$path = "M95.338,83.975C120.428,37.136,29.47-30.196,4.645,16.13C-20.399,62.878,70.625,130.076,95.338,83.975";
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;

								case 0:
								case 6:
									$path = "99.999,49.999 99.998,49.999 49.999,0 0,49.999 -0.001,49.999 -0.001,50 0,50 49.999,99.999 99.998,50 99.999,50   99.998,50 ";
									$pointList.push(<g {...$attributes}><polygon points={$path} /></g>);
									break;

								default:
									$pointList.push(<g {...$attributes}><polygon points='0,15 85,0 100,85 15,100'/></g>);
									break;
							}

							break;
						case 1:
							switch ($type) {
								case 0:
									if ($topNeighbor)					{ $path = "0,-0.001 50,99.999 50,99.999 100,-0.001"; }
									else if ($rightNeighbor)	{ $path = "100,100 0,50 0,50 100,0"; }
									else if ($bottomNeighbor) { $path = "100,100 50,0 50,0 0,100"; }
									else											{ $path = "0,100 100,50 100,50 0,0"; }
									$pointList.push(<g {...$attributes}><polygon points={$path} /></g>);
									break;
								case 3:
									if ($topNeighbor) 				{ $path = "M-0.001,0l14,0c0,0-4.381,9.151-0.551,14C13.448,14-0.001,14.24-0.001,0z"; }
									else if ($rightNeighbor) 	{ $path = "M13.999,0v14c0,0-9.151-4.381-14-0.551C-0.001,13.449-0.242,0,13.999,0z";}
									else if ($bottomNeighbor) { $path = "M14,14H0c0,0,4.38-9.15,0.55-14C0.55,0,14-0.24,14,14z"; }
									else											{ $path = "M0,14V0c0,0,9.15,4.38,14,0.55C14,0.55,14.24,14,0,14z"; }
									let $scale_width = ($size / 14) * $width;
									$nscale = `${$scale_width}, ${$scale_width}`;

									$attributesClone = {
										...$attributes,
										"transform" : `translate(${$posX},${$posY}) scale(${$nscale})`
									};
									$pointList.push(<g {...$attributesClone}><path d={$path}/></g>);

									break;

								case 10:
									if ($topNeighbor){
										$path = "M99.805,32.031H100V0H0v32.031h0.195C0.065,33.073,0,33.854,0,34.375v31.25c0,9.505,3.353,17.611,10.059,24.316   C16.765,96.646,24.87,100,34.375,100h31.25c9.505,0,17.611-3.354,24.316-10.059C96.646,83.236,100,75.13,100,65.625v-31.25   C100,33.854,99.937,33.073,99.805,32.031z";
									}
									else if ($rightNeighbor){
										$path = "M100,34.375V0H34.375C24.87,0,16.764,3.353,10.059,10.059S0,24.87,0,34.375v31.25c0,9.505,3.353,17.611,10.059,24.316   C16.765,96.646,24.87,100,34.375,100H100V34.375z";
									}
									else if ($bottomNeighbor) {
										$path = "M100,34.375c0-9.505-3.354-17.611-10.059-24.316C83.236,3.354,75.13,0,65.625,0h-31.25C24.87,0,16.764,3.353,10.059,10.059   S0,24.87,0,34.375v31.25c0,0.521,0.065,1.303,0.195,2.344H0V100h100V67.969h-0.195c0.132-1.041,0.195-1.822,0.195-2.344V34.375z";
									}
									else{ $path = "M65.625,0H0v100h65.625c9.505,0,17.611-3.354,24.316-10.059C96.646,83.236,100,75.13,100,65.625v-31.25   c0-9.505-3.354-17.611-10.059-24.316C83.236,3.354,75.13,0,65.625,0z"; }
									$attributesClone = $attributes;
									$pointList.push(<g {...$attributesClone}><path d={$path} /></g>);
									break;

								case 4:
									if ($topNeighbor){
										$path = "M0.001,25.574C0.001,13.89,0-1,0-1s34.008,0,49.953,0.001c12.452-0.001,49.952,0,49.952,0v25.068  c0,0,2.101,65.501-13.486,73.267C62.305,109.353,0.001,53.264,0.001,25.574z";
									}
									else if ($rightNeighbor){
										$path = "M73.425,0.001C85.11,0.001,100,0,100,0s0,34.008-0.001,49.953c0.001,12.452,0,49.952,0,49.952H74.931  c0,0-65.501,2.1-73.268-13.486C-10.352,62.305,45.736,0.001,73.425,0.001z";
									}
									else if ($bottomNeighbor) {
										$path = "M99.999,73.426C99.999,85.11,100,100,100,100s-34.008,0-49.953-0.001c-12.452,0.001-49.952,0-49.952,0V74.931  c0,0-2.101-65.501,13.486-73.267C37.695-10.353,99.999,45.737,99.999,73.426z";
									}
									else{ $path = "M26.574,99.999C14.89,99.999,0,100,0,100s0-34.008,0.001-49.953c-0.001-12.453,0-49.953,0-49.953h25.068  c0,0,64.501-2.101,72.268,13.486C109.354,37.695,54.264,99.999,26.574,99.999z"; }
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;


								case 5:
								case 6:
								case 9:
									if ($topNeighbor)					{ $path = "M100,59.375V0H0v59.375L50,100L100,59.375z"; }
									else if ($rightNeighbor)	{ $path = "M40.625,0H100v100H40.625L0,50L40.625,0z"; }
									else if ($bottomNeighbor) { $path = "M100,40.625V100H0V40.625L50,0L100,40.625z"; }
									else											{ $path = "M59.375,0H0v100h59.375L100,50L59.375,0z"; }
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;


								case 11:
								case 12:
									if ($topNeighbor)					{ $path = "M100,100V0H0v100c0.651-0.65,1.595-1.465,2.832-2.441s3.744-2.701,7.52-5.176s7.552-4.688,11.328-6.641   s8.268-3.711,13.477-5.273S45.312,78.125,50,78.125s9.604,0.748,14.746,2.246s9.732,3.32,13.77,5.469s7.715,4.297,11.035,6.445   s5.893,3.939,7.715,5.371S100,99.869,100,100z"; }
									else if ($rightNeighbor)	{ $path = "M0,100h100V0H0c0.651,0.651,1.465,1.595,2.441,2.832s2.702,3.744,5.176,7.52s4.688,7.552,6.641,11.328   s3.711,8.268,5.273,13.477S21.875,45.312,21.875,50s-0.749,9.604-2.246,14.746s-3.32,9.732-5.469,13.77S9.863,86.23,7.715,89.551   s-3.939,5.893-5.371,7.715L0,100z"; }
									else if ($bottomNeighbor) { $path = "M0,0v100h100V0c-0.65,0.651-1.596,1.465-2.832,2.441s-3.744,2.702-7.52,5.176s-7.553,4.688-11.328,6.641   s-8.268,3.711-13.477,5.273S54.688,21.875,50,21.875s-9.603-0.749-14.746-2.246s-9.733-3.32-13.77-5.469S13.77,9.863,10.449,7.715   S4.557,3.776,2.734,2.344L0,0z"; }
									else											{ $path = "M100,0H0v100h100c-0.65-0.65-1.465-1.596-2.441-2.832s-2.701-3.744-5.176-7.52s-4.688-7.553-6.641-11.328   s-3.711-8.268-5.273-13.477S78.125,54.688,78.125,50s0.748-9.603,2.246-14.746s3.32-9.733,5.469-13.77s4.297-7.715,6.445-11.035   s3.939-5.892,5.371-7.715L100,0z"; }
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;


								case 13:
									if ($topNeighbor)					{ $path = "M0,250C0,191.729,0,0,0,0s170.201,0,250,0c62.324,0,250,0,250,0s0,174.261,0,250c0,138.064-111.936,250-250,250  C111.929,500,0,388.064,0,250z"; }
									else if ($rightNeighbor)	{ $path = "M250,0c58.27,0,250,0,250,0s0,170.182,0,250c0,62.305,0,250,0,250s-174.219,0-250,0  C111.881,500,0,388.021,0,250C0,111.963,111.881,0,250,0z"; }
									else if ($bottomNeighbor) { $path = "M500,250c0,58.266,0,250,0,250s-170.194,0-250-0.012c-62.324,0.012-250,0-250,0s0-174.241,0-249.994  C0.006,111.931,111.929,0,249.994,0C388.064,0,500,111.93,500,250z"; }
									else											{ $path = "M250,500c-58.271,0-250,0-250,0s0-170.207,0-250C0,187.676,0,0,0,0s174.261,0,250,0c138.064,0,250,111.929,250,250  C500,388.064,388.064,500,250,500z"; }

									$attributesClone = {
										...$attributes,
										"transform" : `translate(${$posX},${$posY}) scale(${$nscale})`
									};

									$pointList.push(<g {...$attributesClone}><path d={$path} /></g>);
									break;

								case 7:
								case 8:

									if ($topNeighbor)					{ $path = "M100,100V0H0v100l50-28.125L100,100z"; }
									else if ($rightNeighbor)	{ $path = "M0,100h100V0H0l28.125,50L0,100z"; }
									else if ($bottomNeighbor) { $path = "M0,0v100h100V0L50,28.125L0,0z"; }
									else											{ $path = "M100,0H0v100h100L71.875,50L100,0z"; }
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;

								default:
									$attributesClone = $attributes;
									if ($topNeighbor)					{ $path = "M0,100V0h100v59.375L0,100z"; }
									else if ($rightNeighbor)	{ $path = "M0,0h100v100H40.625L0,0z"; }
									else if ($bottomNeighbor) { $path = "M100,0v100H0V40.625L100,0z"; }
									else											{ $path = "M100,100H0V0h59.375L100,100z"; }
									$pointList.push(<g {...$attributesClone}><path d={$path} /></g>);
									break;
							}

							break;
						case 2:
							switch ($type) {
								case 2:
								case 3:
								case 4:
								case 5:
								case 9:
								case 0:
								case 10:
								case 12:
									if ($leftNeighbor && $topNeighbor) {
										$path = "M100,0H0v100h65.625c9.506,0,17.611-3.354,24.316-10.059S100,75.131,100,65.625V0z";
									}
									else if ($topNeighbor && $rightNeighbor) {
										$path = "M100,0H0v65.625c0,9.506,3.353,17.611,10.059,24.316S24.87,100,34.375,100H100V0z";
									}
									else if ($rightNeighbor && $bottomNeighbor ) {
										$path = "M100,34.375V0H34.375C24.87,0,16.764,3.353,10.059,10.059S0,24.87,0,34.375V100h100V34.375z";
									}
									else if ($bottomNeighbor && $leftNeighbor ) {
										$path = "M100,34.375c0-9.505-3.354-17.611-10.059-24.316S75.131,0,65.625,0H0v100h100V34.375z";
									}
									else{ $pointList.push(<g {...$attributes}><rect width="100" height="100"/></g>); break; }

									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;

								case 6:
									if ($leftNeighbor && $topNeighbor) 			{ $path = "M100,65.625V0H0v100h65.625"; }
									else if ($topNeighbor && $rightNeighbor) 	{ $path = "M34.375,100H100V0H0v65.625"; }
									else if ($bottomNeighbor && $rightNeighbor) { $path = "M0,34.375V100h100V34.375V0H34.375"; }
									else{ $pointList.push(<g {...$attributes}><rect width="100" height="100"/></g>); break; }
									$pointList.push(<g {...$attributes}><path d={$path} /></g>);
									break;


								case 13:
									if ($leftNeighbor && $topNeighbor) 					{ $path ="M500,0H0v500C276.142,500,500,276.142,500,0z"; }
									else if ($topNeighbor && $rightNeighbor) 		{ $path="M500,500V0H0C0.001,276.142,223.853,500,500,500z"; }
									else if ($rightNeighbor && $bottomNeighbor) { $path="M0,500h500V0C223.861,0.001,0,223.854,0,500z";}
									else if ($bottomNeighbor && $leftNeighbor ) { $path = "M0,0v500h500C500,223.853,276.142,0,0,0z";}
									else{ $pointList.push(<g {...$attributes}><rect width="100" height="100"/></g>); break;}
									let $attributesClone = { ...$attributes,
											"transform" : `translate(${$posX},${$posY}) scale(${$nscale})`,
											"fill" : $otherColor
									};

									$pointList.push(<g {...$attributesClone}><path d={$path} /></g>);
									break;
								default:
									$pointList.push(<g {...$attributes}><rect width="100" height="100"/></g>);
									break;
							}

							break;
						default:
							if ($neighborsCount > 2 || ($leftNeighbor && $rightNeighbor) || ($topNeighbor && $bottomNeighbor)) {
								$pointList.push(<g {...$attributes}><rect opacity={$opacity}  width="100" height="100" /></g>);
								break;
							}
					}
				}
			});

		return $pointList.map((e, key)=> <Fragment key={key}>{e}</Fragment>);
	}
}

export default EdgeCutsBase;
