import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";
import React from "react";
/**
 * @QrProvider(
 *   id = "diamond",
 *   title = @Translation("Diamond")
 * )
 */
export class RendererDiamond extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {

		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "x", "y", "scale"]);
			let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
			$scale = $scale * 1;
			delete $opts['x'];
			delete $opts['y'];
			delete $opts['scale'];

			$opts = {...$opts,
				"transform": `translate(${$x},${$y}) scale(${$scale})`
			}

			return <g {...$opts}><polygon points="0,50 50,100 100,50 50,0"/></g>;
		}

		$options = {...$options, "pos": pointRender, "align": pointRender,}

		super($options, $qrcode);
	}
}
