import React from 'react';
import {clamp, clamped, getAttribute, getPreview, getPoint} from "./Utils";
import Interactive from "@gqlapp/base/utils/Interactive";
import Zoom from "@xem/icon/base/Zoom";

let draggingStatus = false;

const CroppingBase = React.forwardRef(function CroppingBase(props: any, ref){
	let { onEnd, onMove, onStart, wrapWidth, wrapHeight, width, height, preview, x, y }:any = clamped(props);


	const get = function get(){
		return { x, y }
	}

	React.useImperativeHandle(ref, () => ({ get }));

	let [previewWidth, previewHeight ] = getPreview(preview);
	let [state, setStart ] = React.useState({ left: 0, top: 0, pointX: 0, pointY: 0, dragging: false });
	let pointWrapX = (wrapWidth - width)/2,
		pointWrapY= (wrapHeight - height)/2;

	let { pointX, pointY } = getPoint({ height ,y, width, x});

	const map = ({ top, left }: any) =>{
		const [[ maxX, minX, maxY, minY]] = getAttribute(preview, width, height);

		let deltaX = state.pointX + (left - state?.left) * previewWidth;
		let deltaY = state.pointY + (top - state?.top) * previewHeight;

		const clampedX = clamp(deltaX, minX, maxX);
		const clampedY = clamp(deltaY, minY, maxY);

		let x: number = 50 - (100 * (clampedX / width));
		let y: number = 50 - (100 * (clampedY / height));

		return { x, y, deltaX, deltaY, clampedX, clampedY, top, left }
	}

	const handleStart = (value: any)=>{
		// debug('handleStart', value)
		setStart({...value, pointX, pointY});
		draggingStatus = false;
		onStart && onStart(value);
	}

	const handleEnd = (value: any)=>{
		// debug('handleEnd', value);
		draggingStatus = false;
		value && onEnd && onEnd(map(value));
	}

	const handleMove = (value: any)=>{
		// debug('handleMove', value)
		if(!draggingStatus){
			draggingStatus = true;
		}else{
			value && onMove && onMove(map(value));
		}
	}

	const previewStyle: any = {
		width: `${previewWidth}px`,
		height: `${previewHeight}px`,
		overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto', position: 'relative', top: '50%', transform: 'translateY(-50%)'
	}


	const Dragging = () => {
		//@ts-ignore
		let str = 'Drag to reposition'.t();
		return <>
			{(!draggingStatus) && <div className={'text-center text-white d-flex align-items-center'} style={{
				zIndex: 3, margin: 'auto', position: 'absolute', top: '50%', left: '50%',
				borderRadius: '8px', padding: '4px', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap',
				backgroundColor: '#000', opacity: '.7', cursor: 'move'
			}}>
				<Zoom /><span className={'pl-2'}>{str}</span>
			</div>}
		</>
	}

	const Point = ({ styles, wrapStyles }: any) => {
		let { width, height, src }: any = props;
		return <div {...{ style: {
				...wrapStyles,
				transform: `translate(${pointWrapX}px, ${pointWrapY}px)`,
				backgroundSize: 'cover',
				width: width,
				height: height,
				cursor: 'move'
			} }}>

			<div {...{ className: 'point', style: {
					...styles,
					transform: `translate(${pointX}px, ${pointY}px)`,
					backgroundImage: `url(${src})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					width: width,
					height: height,
					cursor: 'move'
				} }}/>
		</div>
	}

	return (<>
		<div
			id={'zoom'}
			{...{
				style:{
					width: wrapWidth, height: wrapHeight, overflow: 'hidden', position: 'relative',
					backgroundImage: `url("data:image/svg+xml,%3Csvg fill='%23f2f2f2' xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Crect x='8' width='8' height='8'/%3E%3Crect y='8' width='8' height='8'/%3E%3C/svg%3E")`,
					backgroundColor: '#ffffff'
				}
			}}
		>
			<div style={{ pointerEvents: 'all',
				position: 'absolute',
				opacity: '0.5',
				top: 0, left: 0, right: 0, bottom: 0 }}>
				<Point styles={{ filter: 'blur(5px) grayscale(80%) brightness(1)', backgroundColor: '#00000040' }} />
			</div>
			<div
				className={'preview border'}
				style={previewStyle}>
				<Interactive
					onStart={handleStart}
					onMove={handleMove}
					onEnd={handleEnd}
					style={{
						...previewStyle,
						opacity: '0',
						zIndex: 1,
						position: 'absolute'
					}} />

				<div
					className={'backOverlay'}
					style={{
						zIndex: 0,
						position: 'relative',
						top: 0,
						left: 0,
						width: wrapWidth,
						height: wrapHeight,
						transform: `translate(${(previewWidth - wrapWidth) /2 }px, ${(previewHeight - wrapHeight) /2 }px)`,
					}}>
					<Dragging />
					<Point />
				</div>
			</div>
		</div>
	</>)
});


export default React.memo(CroppingBase);
