import formatNumber, { getOptions } from "../formatNumber";

export function getCurrency(input, currency = null) {
  let options = getOptions(currency);

  let type = options?.type;
  let toRound = options?.toRound;
  if(type){
    if(type.toUpperCase() === 'K'){
      input = input / 1000;
    }

    else if(type.toUpperCase() === 'M'){
      input = input / 1000000;
    }

    else if(type.toUpperCase() === 'B'){
      input = input / 1000000000;
    }
  }

  if(toRound){
    input = Math.round(input);
  }

  let number = formatNumber(input, currency)


  if(type){
    type = `${type}`.toLowerCase()
    number = `${number}${type}`
  }

  if(options.symbol){
    if(options.symbol_after){
      number = `${number} ${options.symbol}`
    } else{
      number = `${options.symbol} ${number}`
    }
  }

  return number;
}

export default getCurrency;