import React from "react";
import Box from "@mui/material/Box";
import clsx from "clsx";

type Props = {
	labelSX: any,
	sx: any
}


export default function Group(props: any & Props){
	let { label, children, sx: groupSX, labelSX, className, ...groupProps  } = props;

	let labelProps = {}

	if(labelSX){
		Object.assign(labelProps, { sx: labelSX })
	}

	let sx = {
		'& > label': {
			width: '100%',
			fontWeight: '500'
		},
		...groupSX
	}

	Object.assign(groupProps, { sx, className: clsx('group', className) })

	return <Box {...groupProps}>
		{(!!label) && <Box component={'label'} {...labelProps}>{label}</Box>}
		{children}
	</Box>
}