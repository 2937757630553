import React from 'react';
export default [
	{
		"icon": "Ball19",
		"size": 50,
		"data": <g transform="translate(0 -3)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path id="star" d="M162.243,116.783c-1.91,2.05-3.761,4.1-5.691,6.068-5.191,5.3-10.435,10.548-15.669,15.809-.823.83-1.7,1.624-2.457,2.537a2.1,2.1,0,0,0-.41,1.48q2.847,17.543,5.761,35.076a.573.573,0,0,1,0,.19c-.023.107-.5-.07-1.087-.39-2.024-1.09-4-2.167-6-3.26q-11.812-6.434-23.617-12.905a1.587,1.587,0,0,0-1.75.12q-14.762,8.071-29.538,16.116c-.17.093-.333.177-.527.26-.3.143-.447-.3-.333-.97.447-2.76.87-5.451,1.314-8.128,1.45-8.768,2.937-17.526,4.354-26.294a1.887,1.887,0,0,0-.527-1.35c-5.111-5.214-10.288-10.335-15.336-15.606-2.767-2.88-5.394-5.9-7.978-8.781-.447-.5-.287-1,.367-1.09,1.36-.213,2.937-.463,4.527-.7Q78.017,113.4,88.4,111.822c2.334-.357,4.631-.77,6.961-1.02a1.854,1.854,0,0,0,1.667-1.257q7.234-15.306,14.529-30.585c.1-.227.217-.44.333-.667.193-.367.49-.44.667-.13.113.19.217.367.31.557q7.354,15.446,14.7,30.918a1.8,1.8,0,0,0,1.577,1.184c10.152,1.5,20.29,3.057,30.438,4.6.767.12,1.53.227,2.3.333.667.07.81.543.353,1.043Z" transform="translate(-62.498 -79)"/></svg></g>
	},
	{
		"error": "Chua dc",
		"icon": "Ball20",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g><path d="M294.875,185.113c-13.521,0-38.9-22.13-38.9-22.847s25.379-22.847,38.9-22.847a10.707,10.707,0,0,1,11.3,11.1,16.657,16.657,0,0,1-7.194,11.764,16.653,16.653,0,0,1,7.194,11.765,10.707,10.707,0,0,1-11.3,11.069Z" transform="translate(-206.181 -112.259)"/><path d="M11.3,185.113c13.521,0,38.9-22.13,38.9-22.847S24.823,139.42,11.3,139.42a10.707,10.707,0,0,0-11.3,11.069A16.656,16.656,0,0,0,7.2,162.253,16.656,16.656,0,0,0,.005,174.017a10.707,10.707,0,0,0,11.3,11.1Z" transform="translate(0 -112.259)"/><path d="M139.412,294.875c0-13.521,22.13-38.9,22.847-38.9h0c.716,0,22.847,25.379,22.847,38.9a10.707,10.707,0,0,1-11.1,11.3,16.653,16.653,0,0,1-11.765-7.194,16.656,16.656,0,0,1-11.765,7.194,10.707,10.707,0,0,1-11.069-11.3Z" transform="translate(-112.277 -206.181)"/><path d="M139.412,11.3c0,13.521,22.131,38.894,22.847,38.894h0c.716,0,22.847-25.379,22.847-38.9A10.707,10.707,0,0,0,174.036.005,16.656,16.656,0,0,0,162.272,7.2,16.657,16.657,0,0,0,150.507.005a10.707,10.707,0,0,0-11.1,11.3Z" transform="translate(-112.277 0)"/></g></svg>
	},
	{
		"error": "Chua dc",
		"icon": "Ball21",
		"size": 50,
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g><path d="M49.442,126.483c-1.23-1.759-2.389-3.519-3.7-5.158a120.067,120.067,0,0,0-13.765-13.661c-5.664-5.128-11.436-10.186-16.863-15.574A51.12,51.12,0,0,1,2.16,72.431,32.621,32.621,0,0,1,.9,53.358,26.007,26.007,0,0,1,49.548,48.94c.06.13.12.26.19.4.11.22.28.28.4.14a1.41,1.41,0,0,0,.18-.27,25.427,25.427,0,0,1,18.6-14.661,24.787,24.787,0,0,1,21.762,5.588,25.677,25.677,0,0,1,9.146,23.031,42.86,42.86,0,0,1-9.686,22.921,134.585,134.585,0,0,1-16.853,16.993q-8.137,7.324-16.05,14.854a38.2,38.2,0,0,0-6.787,8.867,2.2,2.2,0,0,1-.19.3c-.11.16-.49-.12-.83-.62Z" transform="translate(-0.031 -33.956)"/></g></svg>
	},
	{
		"error": "Chua dc",
		"icon": "Ball23",
		"size": 50,
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g transform="translate(0,-2)"><path d="M68.144,9.623c-9,0-12.8,4.29-19.073,4.29-6.423,0-11.333-4.26-19.127-4.26A26.093,26.093,0,0,0,9,22.244c-7.26,11.189-6.023,32.271,5.73,50.212,4.207,6.426,9.82,13.631,17.187,13.71h.133c6.4,0,8.3-4.187,17.113-4.237H49.3c8.667,0,10.42,4.214,16.793,4.214h.133c7.363-.08,13.283-8.064,17.487-14.467a69.667,69.667,0,0,0,6.457-12.122,21.978,21.978,0,0,1-2.92-39.779A25.392,25.392,0,0,0,68.1,9.63Z" transform="translate(0 16.084)"/><path d="M37.93,2.25a23.63,23.63,0,0,0-15.3,8.245,20.359,20.359,0,0,0-4.991,15.863h.431A20.116,20.116,0,0,0,33.056,18.5,21.823,21.823,0,0,0,37.934,2.25Z" transform="translate(28.247 0)"/></g></svg>
	},
	{
		"error": "Chua dc",
		"icon": "Ball25",
		"size": 55,
		// "transform": { "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.109 84.749"><g id="ball-25" transform="translate(0 -39.279)"><g transform="translate(55.02 61.543)"><path d="M300.059,153.145,281.4,212.21l45.089-59.065Z" transform="translate(-281.402 -153.145)"/></g><g transform="translate(0.21 40.644)"><path d="M15.4,46.263,1.082,63.452h24.36Z" transform="translate(-1.082 -46.263)"/></g><g transform="translate(75.566 40.644)"><path d="M396.4,46.263,386.391,63.452h24.36Z" transform="translate(-386.391 -46.263)"/></g><g transform="translate(0 61.543)"><path d="M26.432,153.145H0L45.089,212.21Z" transform="translate(0 -153.145)"/></g><g transform="translate(54.533 39.279)"><path d="M278.906,39.279l17.4,17.4,10.148-17.4Z" transform="translate(-278.906 -39.279)"/></g><g transform="translate(18.033 39.279)"><path d="M92.232,39.279l10.148,17.4,17.4-17.4Z" transform="translate(-92.232 -39.279)"/></g><g transform="translate(30.32 61.543)"><path d="M155.076,153.145l19.735,62.485,19.735-62.485Z" transform="translate(-155.076 -153.145)"/></g><g transform="translate(32.269 40.047)"><path d="M182.826,43.208,165.04,60.994h35.572Z" transform="translate(-165.04 -43.208)"/></g></g></svg>
	},
	{
		"icon": "Ball28",
		"size": 55,
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.133 100"><path d="M42.567 0L0 50l42.567 50 42.567-50z"/></svg>
	},
	{
		"icon": "Ball29",
		"transform": { "tl": 0, "tr": 90, "bl": 270, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path id="ball-29" d="M100 100H8.333L0 0l100 8.333z"/></svg>
	},
	{
		"icon": "Ball30",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M50 85l15 15h35V65L85 50l15-15V0H65L50 15 35 0H0v35l15 15L0 65v35h35z"/></svg>
	},
	{
		"icon": "Ball31",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path id="ball-31" d="M100 25H75V0H25v25H0v50h25v25h50V75h25z"/></svg>
	},
	{
		"icon": "Ball32",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M50 85l6.667 6.667c5 5 11.667 8.333 18.333 8.333 13.333 0 25-11.667 25-25 0-6.667-3.333-13.333-6.667-18.333L85 50l6.667-6.667c5-5 8.333-11.667 8.333-18.333 0-13.333-11.667-25-25-25-6.667 0-13.333 3.333-18.333 6.667L50 15l-6.667-8.333C38.333 3.333 31.667 0 25 0 11.667 0 0 11.667 0 25c0 6.667 3.333 13.333 6.667 18.333L15 50l-8.333 6.667C3.333 61.667 0 68.333 0 75c0 13.333 11.667 25 25 25 6.667 0 13.333-3.333 18.333-6.667z"/></svg>
	},
	{
		"icon": "Ball33",
		"size": 50,
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M32.356,52.183,40.2,48.448v-3.04c0-2.044,1.935-3.7,4.321-3.7a4.269,4.269,0,0,1,4.158,2.705l6.6-3.143v-2.8c0-2.044,1.935-3.7,4.321-3.7a4.32,4.32,0,0,1,4.084,2.5l5.857-2.788A144.694,144.694,0,0,1,71.86,15.236,45.775,45.775,0,0,1,74.917,5.015C75.505,3.775,77.295,0,80.966,0s5.461,3.775,6.05,5.015a45.778,45.778,0,0,1,3.056,10.221A144.738,144.738,0,0,1,92.39,34.479l5.857,2.788a4.321,4.321,0,0,1,4.084-2.5c2.386,0,4.321,1.657,4.321,3.7v2.8l6.6,3.143a4.27,4.27,0,0,1,4.158-2.705c2.386,0,4.321,1.657,4.321,3.7v3.04l7.846,3.735a2.285,2.285,0,0,1,1.389,2.022v9.651a2.233,2.233,0,0,1-1.136,1.886,3.082,3.082,0,0,1-2.453.3L92.646,55.67a157.446,157.446,0,0,1-2.573,24.638q-.371,1.854-.782,3.484l18.085,8.61a1.24,1.24,0,0,1,.755,1.1v5.243a1.214,1.214,0,0,1-.617,1.025,1.677,1.677,0,0,1-1.333.162L85.041,93.618a5.271,5.271,0,0,1-8.149,0L55.753,99.93a1.71,1.71,0,0,1-.483.07,1.638,1.638,0,0,1-.85-.232,1.213,1.213,0,0,1-.617-1.025V93.5a1.24,1.24,0,0,1,.754-1.1l18.085-8.61q-.409-1.631-.782-3.484A157.379,157.379,0,0,1,69.288,55.67L34.556,66.041a3.105,3.105,0,0,1-.888.129,3.017,3.017,0,0,1-1.564-.428,2.232,2.232,0,0,1-1.137-1.886V54.205A2.283,2.283,0,0,1,32.356,52.183Z" transform="translate(-30.967)"/></svg>
	},
	{
		"icon": "Ball1",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M0 0h100v100H0z"/></svg>
	},
	{
		"icon": "Ball2", "transform": { "tl": 270, "tr": 0, "bl": 180, "br": 90 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M90 65.5v-41C90 11 78.8 0 65.1 0H24.9C11.2 0 0 11 0 24.5V90h65.1C78.8 90 90 79 90 65.5zm0 0"/></svg>
	},
	{
		"icon": "Ball3",
		"transform": { "tl": 0, "tr": 270, "bl": 90, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M65.4 90h-41c-2.1 0-4.2-.3-6.1-.8-4.3-1.1-8.1-3.4-11.2-6.5-.6-.6-1.1-1.2-1.6-1.8C2.1 76.6 0 71.1 0 65.1V0h65.4c13.5 0 24.4 11.2 24.4 24.9v40.3L90 90H65.4zm0 0"/></svg>
	},
	{
		"icon": "Ball4",
		"transform": { "tl": 0, "tr": 90, "bl": 270, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M90 0v90H0V24.9C0 11.2 11 0 24.4 0H90zm0 0"/></svg>
	},
	{
		"icon": "Ball5",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M.4 15.6C.4 7.2 7.2.5 15.5.5c8.4 0 15.1 6.8 15.1 15.1 0 8.4-6.8 15.1-15.1 15.1S.4 24 .4 15.6"/><path d="M60.1 15.6c0 8.4-6.8 15.1-15.1 15.1-8.4 0-15.1-6.8-15.1-15.1C29.9 7.2 36.7.5 45 .5c8.4-.1 15.1 6.7 15.1 15.1zm0 0"/><path d="M89.6 15.6c0 8.4-6.8 15.1-15.1 15.1-8.4 0-15.1-6.8-15.1-15.1C59.4 7.2 66.2.5 74.5.5c8.3-.1 15.1 6.7 15.1 15.1M.4 74.4c0-8.4 6.8-15.1 15.1-15.1 8.4 0 15.1 6.8 15.1 15.1 0 8.4-6.8 15.1-15.1 15.1C7.2 89.6.4 82.8.4 74.4"/><path d="M60.1 74.4c0 8.4-6.8 15.1-15.1 15.1-8.4 0-15.1-6.8-15.1-15.1 0-8.4 6.8-15.1 15.1-15.1 8.4 0 15.1 6.7 15.1 15.1zm0 0"/><path d="M89.6 74.4c0 8.4-6.8 15.1-15.1 15.1-8.4 0-15.1-6.8-15.1-15.1 0-8.4 6.8-15.1 15.1-15.1S89.6 66 89.6 74.4M.4 45c0-8.4 6.8-15.1 15.1-15.1 8.4 0 15.1 6.8 15.1 15.1 0 8.4-6.8 15.1-15.1 15.1S.4 53.4.4 45"/><path d="M60.1 45c0 8.4-6.8 15.1-15.1 15.1-8.4 0-15.1-6.8-15.1-15.1 0-8.4 6.8-15.1 15.1-15.1 8.4 0 15.1 6.7 15.1 15.1zm0 0"/><path d="M89.6 45c0 8.4-6.8 15.1-15.1 15.1-8.4 0-15.1-6.8-15.1-15.1 0-8.4 6.8-15.1 15.1-15.1S89.6 36.6 89.6 45"/></svg>
	},
	{
		"icon": "Ball6",
		"transform": { "tl": 0, "tr": 90, "bl": 270, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M90 72.3c-7.8 3.8-13.9 9.9-17.9 17.7H16.4c-.7-.2-1.4-.3-1.9-.5h-.2c-1.5-.4-3-.7-3.8-1.1-2.1-.9-4.1-2.8-5.9-4.8-1.7-1.8-2.8-3.8-3.4-6l-.1-.6c-.5-2.2-1-4.7-1.1-6.2l.1-29.2L0 17.7C7.8 13.9 13.9 7.9 17.9 0h55.7c.7.2 1.4.4 1.9.5h.2c1.5.4 3 .7 3.8 1.1 2.1.9 4 2.8 5.9 4.7 1.7 1.9 2.9 3.8 3.4 6l.1.6c.5 2.2 1 4.7 1.1 6.2l-.1 29.2.1 24zm0 0"/></svg>
	},
	{
		"icon": "Ball7",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M85.9 84.5l3.3-5.6-3.3-5.6 3.3-5.6-3.3-5.6 3.3-5.6-3.3-5.6 3.3-5.6-3.3-5.6 3.3-5.6-3.3-5.6 3.3-5.6-3.3-5.6 3.3-5.6-3.3-5.6 1.9-3.2-3.2 1.9L79 1.5l-5.6 3.3-5.6-3.3-5.6 3.3-5.6-3.3L51 4.8 45 .9l-5.6 3.3L33.8.9l-5.6 3.3L22.6.9 17 4.2 11.4.9 5.5 4.1 2.3 2.3l1.9 3.2-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6L.9 45l3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6L4.2 73 .9 78.6l3.3 5.6-1.9 3.2 3.2-1.9 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 5.6 3.3 5.6-3.3 3.2 1.9-1.2-2.9zm0 0"/></svg>
	},
	{
		"icon": "Ball8",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M90 81.8c-1.3-.2-1.2 2.7-1.4 4.7l-1.4-.4c1 .4 0 1.2-1.6 1.5-4.4.5-3.5-2.8-6.9-1.1.5 0 .6.2 1.1.1l-1.8.8c-.4-3.6-10.3 2.3-10.7-1.3-2.5 1.4-.2.9 3 2.5l-4.6-.3c.1 0-1.7-.4-.7-.1-3.5 1.5-1-.3-2.9-1.2-1.3.5 1 2.7-2.7 2.1-5.3-.7 1-2.6-2.2-3l-2.3 1.8c-4.1-1.4-7.2-.1-10.6.1-.8-.1-1.2-.3-1.4-.5-3.7.8-7.7-1.8-10.5-1.2.6.4-1.6.3-1.9.7l-5-1.1c2.2.5 2.1 1.5 2.5 2.6-1.6-.1-2.3-.7-3.4-.8.7.4 1.3.8.5 1.2l-5.3-1.8c.3-.2.9-.2 1.4-.3-1.3 0-4.2-.7-6 .2.2.4.8.8 1 1.2 1.6-1.2 2.5-.2 3.3.6-3.6-.2-.9.3-2.6 1-2.6 0-3.6-2.6-2.9-3.5-2.9.4-3.8 1.1-6.8 0l.6 2.2L5 89l2.2.9c-4.9.9-1.4-3.6-5.8-3.5-.2-2-.1-4.8-1.4-4.6.1-1.1.1-2.2 0-3 .5-.5 1-.5 1.1 2l1.2-4.2c4.8-2.4-.4-11.2 1.2-17.6-.2.4-.4.6-.6.6C4.4 54 1.6 47 2.3 45.7L1 45.2c2-4.8 2.2-5.4 1.4-11.2-.2.1-.6.7-.9-.2 1-1.4 1.9-2.8 1.7-6.7l-.4.2.2-6.2c-4 0 2.5-10.6-1.9-12.4.8-.8 1.2-3.1 1.6-5.2 2.4-.8.2-4.2 4.6-3.4L5 1l2.7.4-.5 2.2c3-1.1 3.9-.4 6.8 0-.7-.9.4-3.5 2.9-3.5 1.7.8-1 1.2 2.6 1-.8.8-1.7 1.8-3.3.6-.2.4-.8.8-1 1.2 1.9.9 4.7.2 6 .2-.5 0-1.1-.1-1.4-.3L25.1 1c.9.6.2 1-.4 1.4 1.1-.1 1.8-.7 3.4-.8-.4 1-.3 2.1-2.5 2.6l5-1.1c.3.4 2.6.4 1.9.7 2.8.6 6.8-2 10.5-1.2.2-.2.6-.4 1.4-.5 3.5.2 6.5 1.5 10.6.1L57.3 4c3.2-.4-3.1-2.3 2.2-3 3.8-.6 1.4 1.6 2.7 2.1 1.9-.9-.6-2.7 2.9-1.2-1 .3.7-.1.7-.1l4.6-.3c-3.2 1.7-5.5 1.1-3 2.5C67.7.3 77.7 6.1 78 2.6l1.8.8c-.5 0-.6.2-1.1.1 3.4 1.7 2.5-1.6 6.9-1.1.7.1 1.3.3 1.6.6 0 .2.1.5.1.8-.1 0-.1.1-.2.1l.2-.1c.4 2 .8 4.1 1.5 4.9-4.3 1.8 2.2 12.4-1.8 12.4l.2 6.2-.4-.2c-.3 3.9.7 5.3 1.7 6.7-.3.9-.6.2-.9.2-.8 5.8-.6 6.3 1.4 11.2l-1.3.5c.8 1.3-2 8.3-.5 14-.1-.1-.4-.2-.6-.6 1.5 6.4-3.6 15.2 1.1 17.5l1.2 4.2c.2-2.5.6-2.5 1.1-2-.1.8-.1 1.8 0 3zm0 0"/></svg>
	},
	{
		"icon": "Ball9",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M89.1 45c0-7.1-4.9-13-11.4-14.6 6.6-1.6 11.4-7.5 11.4-14.5 0-8.3-6.7-15-15-15-7.1 0-13 4.9-14.6 11.4C58 5.8 52.1.9 45 .9S32 5.8 30.4 12.3C28.8 5.8 22.9.9 15.9.9 7.6.9.9 7.6.9 15.9c0 7.1 4.9 13 11.4 14.5C5.8 32 .9 37.9.9 45s4.9 13 11.4 14.6C5.8 61.2.9 67.1.9 74.1c0 8.3 6.7 15 15 15 7.1 0 13-4.9 14.5-11.4C32 84.2 37.9 89.1 45 89.1s13-4.9 14.6-11.4c1.6 6.6 7.5 11.4 14.6 11.4 8.3 0 15-6.7 15-15 0-7.1-4.9-13-11.4-14.5C84.2 58 89.1 52.1 89.1 45zm0 0"/></svg>
	},
	{
		"icon": "Ball10", "transform": { "tl": 0, "tr": 90, "bl": 270, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M18.7 89c4 .9 8 .7 12.1.8 2.2 0 25 0 25 .1H74.7c3.2 0 6.5 0 9.7.1 1.4 0 4.5.6 5.4-1 .4-.8.2-1.9.1-2.7-.2-1.5-.4-2.9-.6-4.4-.4-2.9-.8-5.8-1.1-8.8-.6-6-.8-12.1-.8-18.1 0-8.2.3-16.3 1.8-24.4 1.2-6.8 1.9-13.7-1.9-19.9C84.4 6 79.5 2.6 74 1.4 70.3.6 66.4.6 62.6.4 58.3.1 54 0 49.8 0c-8.4 0-16.9.3-25.3.8-3.5.2-7.2.1-10.6 1.1-2.6.8-5 2.2-6.9 4C2.4 10.4.5 16.8.3 23.1c-.2 4 0 8-.1 12 0 4.2-.1 8.4-.1 12.6v12.6c0 3.6-.2 7.4.4 11 .9 5.6 4 10.5 8.7 13.7 2.9 1.9 6.1 3.3 9.5 4zm0 0"/></svg>
	},
	{
		"icon": "Ball11",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M15 .9c7.8 0 14.1 6 14.1 13.3v61.6c0 7.3-6.3 13.3-14.1 13.3S.9 83.1.9 75.8V14.2C.9 6.9 7.2.9 15 .9zm0 0M75 .9c7.8 0 14.1 6 14.1 13.3v61.6c0 7.3-6.3 13.3-14.1 13.3s-14.1-6-14.1-13.3V14.2C60.9 6.9 67.2.9 75 .9zm0 0M45 .9c7.8 0 14.1 6 14.1 13.3v61.6c0 7.3-6.3 13.3-14.1 13.3s-14.1-6-14.1-13.3V14.2C30.9 6.9 37.2.9 45 .9zm0 0"/></svg>
	},
	{
		"icon": "Ball12",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M89.1 15c0 7.8-6 14.1-13.3 14.1H14.2C6.9 29.1.9 22.8.9 15S6.9.9 14.2.9h61.6C83.1.9 89.1 7.2 89.1 15zm0 0M89.1 75c0 7.8-6 14.1-13.3 14.1H14.2C6.9 89.1.9 82.8.9 75s6-14.1 13.3-14.1h61.6c7.3 0 13.3 6.3 13.3 14.1zm0 0M89.1 45c0 7.8-6 14.1-13.3 14.1H14.2C6.9 59.1.9 52.8.9 45s6-14.1 13.3-14.1h61.6c7.3 0 13.3 6.3 13.3 14.1zm0 0"/></svg>
	},
	{
		"icon": "Ball13",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M89.1 45c0 24.4-19.8 44.1-44.1 44.1C20.6 89.1.9 69.4.9 45 .9 20.6 20.6.9 45 .9 69.4.9 89.1 20.6 89.1 45zm0 0"/></svg>
	},
	{
		"icon": "Ball14",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M24.6 90C11 90 0 79.2 0 65.9V24.1c0-4.2 1.1-8.1 3-11.5 1.8-3.3 4.4-6.1 7.5-8.3 4-2.8 8.9-4.4 14.1-4.4h40.8C79 0 90 10.8 90 24.1v41.8C90 79.2 79 90 65.4 90H24.6zm0 0"/></svg>
	},
	{
		"icon": "Ball15", "transform": { "tl": 0, "tr": 90, "bl": 270, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M19.3 90c-6 0-11.7-6.2-12.6-11.9L0 0l79.3 5.1c5.6.8 10 6.1 10 12.5L90 90H19.3zm0 0"/></svg>
	},
	{
		"icon": "Ball16", "transform": { "tl": 0, "tr": 90, "bl": 270, "br": 180 },
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M46 90L0 44.9V0h44.9L90 46v44H46zm0 0"/></svg>
	},
	{
		"icon": "Ball17",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M0 90C5.3 60.1 5.3 30 0 0c15 2.7 30.1 4 45 4 14.9 0 30-1.3 45-4-5.3 29.9-5.3 60 0 90-15-2.7-30.1-4-45-4-14.9 0-30 1.3-45 4zm0 0"/></svg>
	},
	{
		"icon": "Ball18",
		"data": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><path d="M3.9 0L0 26.1 26.1 30 30 3.9 3.9 0zm0 0M30 5.1L54.9 0 60 24.9 35.1 30 30 5.1zm0 0M60 3l27-3 3 27-30 3V3zm0 0M1.5 37.5l21-6 36 21-51 6-6-21zm0 0" /><path d="M19.5 18.9l-.9 50.7 49.8 1.8 2.4-51-51.3-1.5zm0 0" /><path d="M64.5 31.5l-3 24 24 3 3-24-24-3zm0 0M0 64.5L25.5 60 30 85.5 4.5 90 0 64.5zm0 0M30 63.6L56.4 60 60 86.4 33.6 90 30 63.6zm0 0M66.6 60L60 83.4 83.4 90 90 66.6 66.6 60zm0 0" /></svg>
	}
];
