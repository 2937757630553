import throwError from "@gqlapp/base/utils/throwError";

export default function TryCatch(callback: any, props: any = {}) {
	return new Promise(async (resolve, reject) => {
		let values = null;
		let _errors = [];
		try { values = await callback(); } catch (e) { reject(e); }
		const { errors, violations } = values || {};
		
		if (values?.errors && values?.errors.length) {
			reject(throwError(_errors, errors, props));
			return;
		}
		
		if (violations && violations.length) {
			reject(throwError(_errors, violations, props));
			return;
		}
		resolve(values);
	})
}