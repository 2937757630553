import { EdgeCutsBase } from "./EdgeCutsBase";
import { QRCode } from '../qrcode'

/**
 * @QrProvider(
 *   id = "edge_cuts",
 *   title = @Translation("RenderEdgeCuts"),
 *   type = 1
 * )
 */
export class RendererEdgeCuts extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		super({...$options, type: 1 }, $qrcode)
	}
}
