import React from "react";
import ModalClass from "@gqlapp/base/Modal/ModalClass";

import Header from "./Header";
import Footer from "./Footer";

const FormBase = ((props) => {
	
	let { sx, toolProps, className, errors, frameOptions, tabDefault } = props;
	const [ tabValue, setTab ] = React.useState(tabDefault || 'frame')
	sx = sx || {}
	
	let tabIndex = 1;
	
	errors = errors || {};
	
	frameOptions = (frameOptions || []).map(function (values){
		let errs = {};
		if(Object.keys(errors).length){
			Object.keys(errors).map(key=>{
				if((values?.errorKeys || []).includes(key)){
					errs[key] = errors[key];
				}
			})
		}
		return ({ ...values, errs });
	})
	
	let background = toolProps?.settings?.background || '#00000000';
	
	if(sx && background){
		Object.assign(sx, { '& .preview':{ background }})
	}

	return (<ModalClass
		{...props }
		{...{ tabValue, setTab, contentProps:{ sx }}}
		{...{ className, header: Header, footer: Footer }}
	>
		{frameOptions.map(({ Form, value}, key) =>
			<React.Fragment key={key}>{(tabValue === value) && <Form {...props} {...{ tabIndex }}/>}</React.Fragment>
		)}
	</ModalClass>)
})


export default FormBase;
