export const getPreview = (preview)=>{
	let [width, height ] = `${preview}`.split(':').map((v)=>parseInt(v, 10));
	width = Math.round(width);
	height = Math.round(height);
	return [ width, height ];
}

export default function autoImage(preview = '0:0', width, height){
	let [pw, ph] = getPreview(preview);

	let newWidth = 0, newHeight= 0;
	if(!!width && !!height){
		const ratioWidth = width / pw;
		const ratioHeight = height / ph;

		if (ratioWidth > ratioHeight) {
			newWidth = pw;
			newHeight = height / ratioWidth;
		}else{
			newWidth = width / ratioHeight;
			newHeight = ph;
		}
	}
	newWidth = newWidth || 0;
	newHeight= newHeight || 0;
	return [newWidth, newHeight]
}