/**
 *
 * @param preview
 */
export default function getPreview(preview: string){
  let [width, height ] = `${preview}`.split(':').map((v)=>parseInt(v, 10));
  width = Math.round(width);
  height = Math.round(height);
  return [ width, height ];
}
