/**
 * @param map
 * @param props
 * @param component
 * @param options
 * @param path
 */
export default function getProps(map: any, props: any, component: any, options: any, path: string = null){
  let getInitialProps = component?.getInitialProps;
  let newProps = {...props, ...options, component, getInitialProps}
  if(path){
    Object.assign(newProps, { path: map({ path }) })
  }

  return newProps
}
