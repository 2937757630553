import { QRPay } from "vietnam-qr-pay";

export default function getQrcodeData(content: any){
  content = content || {}
  let obj = {
    bankBin: content?.bankBin,
    bankNumber: content?.bankNumber
  }

  if(content?.amount){
    Object.assign(obj, { amount: content?.amount  }) // Số tiền
  }

  if(content?.purpose){
    Object.assign(obj, { purpose: content?.purpose  }) // Nội dung chuyển tiền
  }

  return QRPay.initVietQR(obj)?.build();
}