
import React from "react";
import Close from "@xem/icon/qrcode/Close.svg";
import Base from "../../../../components/QrView/QrView";
import Button from "reactstrap/lib/Button";
import ColorBox from "@xem/look/Color/ColorBox";
import deeperAssign from '@gqlapp/base/utils/deeperAssign';
import _ from 'lodash';
function getHeaderField(name, values){
	return function (colorProps){

		try {
			let obj = _.set({}, name, colorProps?.values?.color || '#00000000');
			values = deeperAssign({}, values);
			values = deeperAssign(values, obj);
		}catch(e){ debug(e); }

		return (<>
			<Button
				onClick={colorProps?.onClose} color={'default'} className={'p-0  modal-close'}
				style={{position: 'absolute', right: '10px', top: '8px'}}>
				<Close width={24} height={24}/>
			</Button>

			<Base
				{...{ values, styleWrap: ({ renderer }) => renderer }}
				{...{ viewStyle: { padding: '15px', height: '230px', backgroundColor: '#ffffff' }}}
			/>
		</>)
	}
}


export default function ColorField({ append, name, data_text, values, ...props}){
	let header = getHeaderField(name, values);

	return <ColorBox
		textfield
		{...props }
		{...{ name, header }}
		{...{ append: append() }}
		{...{ sx:{
				'& .MuiFormGroup-root > .MuiFormControl-root':{
					maxWidth: '120px',
					'&':{
						marginTop: 0,
						'& .MuiInputBase-root input':{
							paddingRight: '5px'
						}
					}
				}
			}
		}}
	/>
}