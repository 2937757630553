import getPreview from './getPreview';

/**
 *
 * @param preview
 * @param width
 * @param height
 */
export default function zoomTo(preview: string = '0:0', width: number, height: number){
	let [pw, ph] = getPreview(preview);
	let newWidth = 0, newHeight=0;
	if(!!width && !!height){
		const ratioWidth = width / pw;
		const ratioHeight = height / ph;

		if(ratioWidth * pw > width || ratioWidth * ph > height  ) {
			newWidth  = pw * ratioHeight;
			newHeight = ph * ratioHeight;
		}else{
			newWidth  = pw * ratioWidth;
			newHeight = ph * ratioWidth;
		}
	}

	newWidth = newWidth || 0;
	newHeight= newHeight || 0;
	return [newWidth, newHeight]
}
