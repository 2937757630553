import withUseStyles from "./Head.Styles";
import React from "react";

import clsx from "clsx";
import i18next from "i18next";
import settings from "@xem/config/settings";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { Helmet } from 'react-helmet';

export default withUseStyles(function Head(props) {
  const [htmlClass, setClassesName] = React.useState(null);
  let classToggle = props?.classToggle;
  const siteAction = props?.siteAction;

  classToggle = classToggle || 'with-panel-left-reveal';

  const setClasses = (value) => {
    setClassesName(htmlClass ? null : clsx('with-panel', value));
  };

  if (siteAction) {
    siteAction.callback = () => {
      try {
        setClasses(classToggle);
      } catch (e) {}
    };
  }

  // console.warn('########','route', '#########', props.route)
  const title = props.route?.metaTitle;
  const metatags = props?.route?.metatags || [];
  const hlinks = props?.route?.hlinks || [];
  const currentUser = props?.currentUser;
  const logined = currentUser && !!currentUser.id;

  let classNameOnPage = clsx(props?.classes?.root, props?.classNameOnPage, htmlClass);
  classNameOnPage = clsx(classNameOnPage, props?.webType);
  /**
   * set page classes
   */
  if (logined) {
    classNameOnPage = clsx(classNameOnPage, props?.loginClass);
  } else {
    classNameOnPage = clsx(classNameOnPage, props?.unloginedClass);
  }

  const lang = i18next.language;
  // if (__SERVER__ && process.env.API_SERVER_URL) {
  //   settings.setJS('apiURL', process.env.API_SERVER_URL);
  // }

  if (__SERVER__ && process.env.VERSION) {
    settings.setJS('version', process.env.VERSION);
  }

  if (__SERVER__ && process.env.SERVER_API) {
    settings.setJS('serverApi', process.env.SERVER_API);
  }

  if (__SERVER__ && props?.route?.origin) {
    settings.setJS('origin', props?.route?.origin);
  }

  let styles = `
  body{
    strong, b{
      font-weight: 500
    }
  }
  `;

  // debug(props?.baseStyle);

  // debug(useMediaQuery('(max-width:374px)'));
  return (
      <React.Fragment>
        <Helmet key={0} htmlAttributes={{lang, class: classNameOnPage}}>
          <meta charSet="utf-8"/>
          <meta name="theme-color" content="#ffffff"/>
          {/*<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />*/}
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta name="apple-mobile-web-app-capable" content="yes"/>
          <meta name="viewport" content="width=375px, user-scalable=no"/>
          {(!!useMediaQuery('(max-width:450px)')) && <meta name="viewport" content="width=375px, user-scalable=no"/>}
          {!!title && <title>{title}</title>}
          <style type="text/css">{styles}</style>
          {/*<body style="background-color: red"/>*/}
        </Helmet>

        {!!(metatags || []).length && (
          <Helmet key={1}>
              {metatags.map(({ key, value }, idx) => (
                  <meta key={idx} property={key} content={value} />
              ))}
            </Helmet>
        )}

        {!!(metatags || []).length && (
            <Helmet key={2}>
              {metatags.map(({ key, value }, idx) => (
                  <meta key={idx} name={key} content={value} />
              ))}
            </Helmet>
        )}

        {!!(hlinks || []).length && (
            <Helmet key={3}>
              {hlinks.map(({ key, value }, idx) => (
                  <link key={idx} rel={key} href={value} />
              ))}
            </Helmet>
        )}
        <Box component={'section'} sx={props?.baseStyle} id={'popup'} />
        <Box component={'section'} sx={props?.baseStyle} id={'switcher'}>
          {props?.children}
        </Box>
      </React.Fragment>
  );
});
