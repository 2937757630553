import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';
import Entity from '@xem/custom-page-common/classes/CustomPage';
import withCustomPage, { CUSTOM_PAGE_QUERY } from '@xem/custom-page-common/classes/withCustomPage';
import withFormApiSubmit from '@xem/custom-page-common/classes/formapi/withFormApiSubmit';
import onAction from "@xem/entity/classes/onAction";
import setCache from '@xem/entity/classes/setCache';


/**
 *
 * @param input
 * @param propsInput
 * @param propsBefore
 * @param imageSkip
 * @constructor
 */
export const ModulesCompose = (input?: any, propsInput?: any[], propsBefore?: any[], imageSkip?: boolean) => {

	return (Route: any) => {

		/**
		 * witRoute
		 */
		const witRoute = compose(
			withProps(input),
			withFormApiSubmit,
			withProps({ entity: Entity.instanse() }),

			withProps(({ formSubmit, entity, action })=>{
				const refetch = func(entity.refetch);
				action.callback = refetch
				return ({ refetch, onSubmit: onAction({ action: formSubmit })
				})
			}),
			...(propsBefore || []),
			withCustomPage(({ entity }) => ({ customPage: entity, imageSkip })),
			...(propsInput || []),
			withLoading
		)(Route);


		const bindCache = async (apolloClient: any, query: any, variables: any)=> {
			/** set link cache*/
			await setCache({ query, variables, apolloClient })
		}


		// @ts-ignore
		witRoute.getInitialProps = async ({ path, apolloClient}) => {
			let ctx = {};

			await bindCache(apolloClient, CUSTOM_PAGE_QUERY, { path, skip: imageSkip });

			return ({...ctx})
		}

		return witRoute;
	};
};

export default ModulesCompose;
