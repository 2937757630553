import withProps from "recompose/withProps";

export default withProps({
	sx: ({
		'& .container-logo':{
			'&':{
				'--space': '0',
				display: 'flex',
				justifyContent: 'center !important',
			},
			'&>.row':{
				// maxWidth: '430px',
				minWidth: '100%',
				// justifyContent: 'space-between',
				marginLeft: 'calc((-1 * var(--space)/2))',
				marginRight: 'calc(-1 * var(--space)/2)',
				'& > .col-auto':{
					paddingLeft: 'calc(var(--space)/2)',
					paddingRight: 'calc(var(--space)/2)',
				},
				'& .item':{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: `calc( 50px * var(--size) + var(--space) * (var(--size) - 1))`,
					borderRadius: '5px',
					backgroundColor: '#fff',
					marginBottom: '15px',
					textAlign: 'center'
				}
			}
		}
	})
});
