import React from "react";
import Button from "reactstrap/lib/Button";
import Box from "@mui/material/Box";
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import ColorIcon from './icons/Rectangle.svg'
import DropdownIcon from "./icons/Dropdown.svg";
import RenderField from "../Renders/Field/Color";
import Field from "@gqlapp/forms-client-react/FieldAdapter";


type Props = {
  labelSX: any,
  sx: any
}


let timeout: any = null;

export default function Color(props: any & Props){
  /**
   *
   */
  let action: any, { sx, mask, header, textfield, gradient, name, onChange: handleChange, onActions, value, tabIndex, disabled, callback, options } = props;
  options = { color: value, onSubmit, callback, header, ...options }

  action = (gradient)? onActions?.gradient: onActions?.color;

  tabIndex = tabIndex || 0;

  /**
   * change color
   * @param value
   */
  function onChange(value: string){
    handleChange(name, value);
  }

  function onSubmit({ onClose }: any, value: string){
    clearTimeout(timeout);
    timeout = setTimeout(()=>{ onChange(value); }, 150);
    onClose && onClose();
  }

  function onClick(){
    action && action(options);
  }

  return <Box sx={{
    ...sx,
    '& .btn': {
      '--btn-color': `transparent`,
      border: 'none',
      padding: 0,
      backgroundColor: '#f2f2f2',
      '& .dropdown':{ paddingRight: '10px' },
      '& .dropdown, & .color':{ display: 'inline' },
      '& .color':{
        padding: '10px',
        '& .color-inner':{
          svg:{
            border: '1px solid #e2e2e2',
            background: `${value || '#000000'}`
          }
        }
      }
    }
  }}>
    {(textfield && !gradient) && <>
      <Field
        mask={mask || ['#', /\w/i, /\w/i, /\w/i, /\w/i, /\w/i, /\w/i, /\w/i, /\w/i]}
        {...{ component: RenderField, inputProps: { tabIndex: tabIndex++ } }}
        {...{ onClick, name, value }}
      />
    </>}
    {!(textfield && !gradient) && <>
      <Button color={'default'} { ...{ disabled, onClick } }>
        <Row none>
          <Col xs={'auto'} className={'d-flex align-items-center'}>
            <Box className={'color'}>
              <Box className={'color-inner'}>
                <ColorIcon width={'50px'} />
              </Box>
            </Box>
          </Col>
          <Col className={'dropdown d-flex align-items-center'}>
            <DropdownIcon width={'10px'} height={'6px'} />
          </Col>
        </Row>
      </Button>
    </>}
  </Box>
}