import queryString from 'query-string';
import config from '../index';

class Settings{
  private options: any = {}
  private libs: any[] = []
  static createInstance = (options: any = {}) => new Settings(options)

  constructor(options = {}) {
    this.options = options || {};
  }

  public init(){
    this.options = {};
    this.libs = [];
    this.setJS('public', config.app.publicPath || '/')
  }

  // @ts-ignore
  public addJS(settings: any) {
    Object.assign(this.options, settings)
  }


  // @ts-ignore
  public setJS(name: string, settings: any) {
    let _OPTIONS = {};
    _OPTIONS[name] = settings;
    Object.assign(this.options, _OPTIONS);
  }


  public addLibrary(path: string, options: any = {}) {
    let publicPath = config.app.publicPath || '/';

    if((!hasSchema(path)) && (!path.match('/^\/(.*)/im'))){
      path = `${publicPath}${path}`;
    }

    if(!Object.keys(this.libs).includes(path)){
      this.libs[path] = options || { weight: 1};
    }
  }


  public toJSON() {
    return this.options;
  }

  public toString() {
    return JSON.stringify(this.options);
  }

  // @ts-ignore
  private hasHashtag = (url: string) => /\#/i.test(url);

  // @ts-ignore
  private hasPlus = (url: string) => /\?/i.test(url);

  // @ts-ignore
  public toLibs() {
    let libs: any[] = [];

    Object.keys(this.libs).map( value =>{
      if(this.hasHashtag(value)){
        let regexp = /#\S+/g;
        value = value.replace(regexp, '');
      }

      let { withoutTime, ...props} = this.libs[value];
      if(!withoutTime){
        value += this.hasPlus(value) ? '&': '?';
        value += queryString.stringify({ t: (new Date()).getTime() });
      }
      libs.push({ ...props, src: value});
    })

    return (libs || [])
        .sort((a, b)=> a.weight - b.weight)
        .map(({ path, type, weight, ...value })=>({
          ...value,
          type: type || 'text/javascript'
        }));
  }

  public headScripts() {
    return this.toLibs()
      .filter(({ kind })=>(kind === 'head'))
      .map(({ kind, ...props}) => props)
  }

  public footerScripts() {
    return this.toLibs()
      .filter(({ kind })=>(kind !== 'head'))
      .map(({ kind, ...props}) => props)
  }
}

export default Settings;