import Media from './Media';
import Entities from '@gqlapp/core-common/Entities';
import More from "@xem/entity/classes/fetchMore";

export default class Medias extends Entities {
  public _fetchMore: any = null;
  public subscribeToMore: any = null;
  public updateQuery: any = null;
  
  
  constructor() {
    super();
    this.interface = Media;
  }
  
  /** instanse */
  public static instanse() {
    return new this();
  }
  
  
  set fetchMore(value: any){
    this._fetchMore = value;
  }
  
  get fetchMore(){
    return More(this._fetchMore);
  }
  
}