import React from "react";

import Button from "reactstrap/lib/Button";
import Form from "reactstrap/lib/Form";
import Box from "@mui/material/Box";
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import Close from "@xem/icon/qrcode/Close.svg";

export const btnProps = {
	outline: false,
	block: true,
	className: 'rounded-0',
	color: 'white',
};

/**
 * Root
 */
export const root = {
	'& .MuiFormLabel-colorPrimary:not(.Mui-disabled)':{
		color: '#686868'
	},
	'& .MuiOutlinedInput-root:not(.Mui-focused)':{
		'& .MuiOutlinedInput-notchedOutline':{
			borderColor: 'var(--border-color, #E2E2E2)'
		}
	},
	'--modal-popup--bg': '#f2f2f2',
	'--btn-outline-border-secondary':'#f2f2f2',
	'& [data-modal-body]':{
		marginTop: '15px',
		marginBottom: '15px',
		backgroundColor: '#ffffff',
		boxShadow: '0px 0px 1px 0px rgba(176, 176, 176, 0.21)'
	},
	'& [data-modal-footer]':{
		boxShadow: '0px 0px 1px 1px rgba(176, 176, 176, 0.21)',
		paddingTop: '0',
		'&> .footer-inner':{
			backgroundColor: '#ffffff',
			boxShadow: '0px 0px 1px 1px rgba(176, 176, 176, 0.21)',
			'& .col':{
				'& .btn':{
					borderRadius: 0,
					marginBottom: '0px',
					border: 'none',
					minHeight: '40px',
				},
				'&+.col':{
					'& .btn':{
						borderLeft: 'none'
					},
				}
			}
		},
		'& .btn-group':{
			'& .btn':{
				'&:first-of-type':{
					borderLeft: 'none !important'
				},
				'&:last-of-type':{
					borderRight: 'none !important'
				}
			}
		}
	},
	'& [data-modal-header]':{
		paddingBottom: '0',
		backgroundColor: '#f2f2f2',
		'&, & .header-inner, & .header-inner > div':{
			'borderTopLeftRadius': 'var(--modal-popup--overlay-rounded)',
			'borderTopRightRadius': 'var(--modal-popup--overlay-rounded)'
		},
		'&> .header-inner':{
			backgroundColor: '#ffffff',
			'& .header-text, & > div > .header-text':{
				paddingLeft: '15px',
				paddingRight: '15px',
				paddingTop: 'calc(8px + env(safe-area-inset-top))',
				paddingBottom: '8px',
				fontSize: '1.12499625rem!important'
			}
		}
	}
}

/**
 * defaultConfig
 */
export const defaultConfig = {
	'& table':{
		'td, th':{
			borderColor: '#F2F2F2'
		}
	},
	'& div[data-modal-popup]':{
		'&.in':{
			zIndex: '100 !important',
		}
	},
	'& div[data-modal-header]':{
		'& .MuiFormControl-root':{
			marginTop: 0, marginBottom: 0, minHeight: '40px',
		},
		'.header-text':{
			boxShadow: '0px 1px 1px 0px rgba(176, 176, 176, 0.21)',
		},
		'& .MuiTabs-root':{
			backgroundColor: '#ffffff',
			marginTop: '0',
			'& .MuiTabs-flexContainer':{
				borderBottom: '2px solid #f2f2f2'
			},
			'& .MuiTabs-indicator':{
				zIndex: 2,
				height: '2px'
			}
		}
	},
	'& div[data-modal-footer]':{
		backgroundColor: '#ffffff',
	},
	'& div[data-modal-body]':{
		marginTop: '15px',
		boxShadow: '0px 0px 1px 1px rgba(176, 176, 176, 0.21)',
		minHeight: 'calc(100% - 30px)'
	},
	'& .MuiSelect':{
		'& .endAdornment':{
			zIndex: 10,
			position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
			cursor: 'pointer',
			'&> div, &> div > .btn':{
				width: '100% !important',
				height: '100% !important',
			},
			'&> div > .btn':{
				textAlign: 'right',
				paddingRight: '10px !important'
			}
		}
	},
	'& .control':{
		backgroundColor: '#ffffff',
		'& > .row':{ minHeight: '40px' },
		'& > .group':{
			padding: '15px',
			'&:not(:first-of-type)':{
				borderTop: '15px solid #f2f2f2',
			},
			'& > .row':{
				paddingBottom: '15px',
				'&:last-of-type':{
					paddingBottom: '0',
				},
				'& > [class*="col"], & > [class*="col-"]':{
					alignItems: 'center',
					display: 'flex'
				}
			}
		}
	},
	'& .btn': {
		'&.delete': {
			padding: 0
		}
	},
	'& .MuiFormControl-root':{
		marginTop: '8px', marginBottom: 0, minHeight: '54px',
		'& .MuiFormHelperText-root':{
			fontSize: '12px',
			marginTop: '2px',
			marginBottom: '-8px',
			lineHeight: '12px'
		}
	}
}

/**
 *
 */
type headerProps = {
	sx?: any;
	Wrap?: any;
	children?: any;
	isBefore?: boolean;
	closeBtn?: boolean;
	onClose?: any;
	title?: string;
}


/**
 *
 * @param props
 * @constructor
 */
export function Header(props: headerProps){
	/**
	 *
	 */
	const { sx, Wrap, children, isBefore, closeBtn } = props;

	/**
	 * title
	 */
	let title = <>{(!!props?.title) && <div className={'header-text'}>{props?.title}</div>}</>

	/**
	 * Wrapper
	 */
	const Wrapper = Wrap || (({ children })=> (<div className="header-inner">{children}</div>));

	/**
	 * ButtonClose
	 * @constructor
	 */
	const ButtonClose = () =>(<>
		<Button onClick={props?.onClose} color={'default'} className={'p-0  modal-close'} style={{ position: 'absolute', right: '10px', top: '8px', zIndex: 9999}}>
			<Close width={24} height={24} />
		</Button>
	</>)

	return (<>
		<Wrapper>
			<Box sx={sx} style={{ position: "relative", width: "100%"}}>
				{(isBefore) && <>{title}</>}
				{children}
				{(!isBefore) && <>{title}</>}
				{(closeBtn) && <ButtonClose />}
			</Box>
		</Wrapper>
	</>);
}



type footerProps = {
	sx?: any;
	prefix?: any;
	suffix?: any;
	onClick?: any;
	handleSubmit?: any;
	cancel?: string;
	apply?: string;
	btnApplyProps?: any;
	btnCancelProps?: any;
	colApplyProps?: any;
	colCancelProps?: any;
	children?: any;
};

/**
 * Footer
 * @param props
 * @constructor
 */

export const Footer = (props: footerProps)=>{
	let { sx, prefix, suffix, onClick, handleSubmit, cancel, apply, btnApplyProps, btnCancelProps } = props
	let { colApplyProps, colCancelProps, children } = props;
	cancel = cancel || `cancel`.t();
	apply = apply || `apply`.t();

	return (<>
		<Box sx={sx} className="footer-inner">
			<Form onSubmit={handleSubmit}>
				<Row none>
					{prefix}
					<Col {...colCancelProps}>
						<Button
							onClick={onClick}
							{...btnProps}
							{...btnCancelProps}
							className={'cancel'}
						>
							{cancel}
						</Button>
					</Col>
					{children}
					<Col {...colApplyProps}>
						<Button
							className={'apply'}
							type={'submit'}
							{...{ ...btnProps, color: 'primary' }}
							{...btnApplyProps}
						>
							{apply}
						</Button>
					</Col>
					{suffix}
				</Row>
			</Form>
		</Box>
	</>)
};


export default root;