import React from 'react';
import menu from "./helpers/menu";
import resources from './locales';
import Route from "@xem/router/classes/Route";
import List from './containers/ListContainer';
import Intro from './containers/IntroContainer';
import CustomPage from './containers/CustomPageContainer';
import ClientModule from "@gqlapp/module-client-react";
import { CustomEditRoute as ER, CustomPageRoute as PR, map } from "./helpers/config";
import getProps from "@xem/entity/classes/getProps";

export default new ClientModule(menu, {
  route: {
    CustomPageIntro : <Route {...getProps(map, ER, Intro, { load: false, blockMenus: ['createlink'] }, 'intro')} />,
    CustomPageList  : <Route {...getProps(map, ER, List, { load: false, blockMenus: ['createlink'] }, 'list')} />,
    CustomPage      : <Route {...getProps(map, PR, CustomPage, { unloginedClass: ''})} />
  },
  localization: [{ ns: 'page', resources, more: ['common'] }]
});
