import * as React from 'react';
import Row from "./Row";
import * as PropTypes from "prop-types";


function xepVienGach(mang, width) {
  let valueWidth = width;

  // Tạo một mang trống để lưu trữ các viên gạch đã được xếp
  let vienGachDaXep = [];

  // Duyệt qua mang các viên gạch
  for (let i = 0; i < mang.length; i++) {

    if(!mang[i]) continue;

    // Kiểm tra xem viên gạch có thể xếp được vào bức tường hay không
    if (valueWidth >= mang[i].width) {
      // Thêm viên gạch vào bức tường
      valueWidth -= mang[i].width;
      vienGachDaXep.push(mang[i]);
      delete mang[i];
    }
  }

  let count = 0;
  vienGachDaXep.map(({ width })=>{ count += width });
  if(count < width ){
    let two = null, one = null, index = null;
    for (let i = 0; i < mang.length; i++){
      let value = mang[i];
      if(value && (value.width > 1)){
        two = value;
        index = i;
        delete mang[i];
        break;
      }
    }

    if(two && index && vienGachDaXep.length){
      for (let i = vienGachDaXep.length; i >= 0; i--){
        let value = vienGachDaXep[i];
        if(value && value.width < 2){
          mang[index] = value;
          delete vienGachDaXep[i];
          vienGachDaXep.push(two)
          break;
        }
      }
    }
  }

  // Trả về mang các viên gạch đã được xếp
  return vienGachDaXep;
}

function sort(mang, width){
  let vienGachDaXep = [];

  let count = 0;

  let bucTuongGach = []
// Xếp các viên gạch
  do{
    let values = xepVienGach(mang, width);
    bucTuongGach.push(values)
    count++
  }
  while ( count < 10 && mang && mang.length > 0);

  bucTuongGach.map(values=>{
    values.map(value=> vienGachDaXep.push(value))
  })

  return vienGachDaXep;
}


function getSize({ width, size, minSpace }, level = 0){

  let widthSize = Math.floor((width / size) - level);

  let space = parseFloat(((width - (widthSize) * size)/(widthSize - 1)).toFixed(2));

  if(minSpace >= space && widthSize > level + 1){
    let values  = getSize({  width, size, minSpace  }, level + 1)
    widthSize = values.widthSize;
    space = values.space;
  }

  return { widthSize, space, size };
}

const Horizontal = React.forwardRef(function Horizontal({ minSpace, size, callback, ...props}, ref) {
  const { children } = props

  let width = 0;
  let itemSettings = []
  const innerRef = (node)=>{
    if(!!node){
      // node.classList.remove('resized')
      node.style.setProperty('--space', `0px`);
      width = node.clientWidth;


      let style = window.getComputedStyle(node);

      width += parseFloat(style.marginLeft.replace('px',''))
      width += parseFloat(style.marginRight.replace('px',''))

      // let widthSize = Math.floor(width / size);
      let { widthSize, space, size:s} = getSize({ width, size, minSpace }, 0);
      // debug('#####', 'Horizontal', { width, size, minSpace, space })
      node.style.setProperty('--space', `${space}px`);

      callback && callback(node, { width, widthSize, space, s })
      if(node.children) [...node.children].map((item, index)=>{
        item.style.removeProperty('--size')
        let itemWidth = item.clientWidth;
        let width = Math.floor(itemWidth/size) || 1;

        // debug('#####', 'Horizontal', { item, width, itemWidth, size });
        item.style.setProperty('--size', width)
        itemSettings.push({ width, index, itemWidth })
      })

      let arraySorted = sort(itemSettings, widthSize);

      arraySorted.map(({ index, width }, idx)=>{
        node.children[index].style.order = idx;
      });

      if(props.innerRef) props.innerRef(node);
    }
  }

  return React.createElement(Row, {...props, ref: innerRef }, children);
});



Horizontal.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func
};

export default Horizontal;
