import React from 'react';
import * as PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import BaseLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import clsx from "clsx";

function ControlLabel({ optionWrap, className, FormControlLabel,...props }) {
  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) { checked = radioGroup.value === props.value; }
  FormControlLabel = FormControlLabel || BaseLabel;
  className = clsx(className, {'Mui-checked': checked });
  return (<React.Fragment><FormControlLabel {...{ className }} {...props} /></React.Fragment>);
}


const RenderRadiosBox = (
  props)=>{
  let { sx, onChange, type, name, label, control, inline, margin, variant, disabled, options } = props;
  let { fullWidth, labelPlacement, value, classes, className, classNameFormLabel, classNameGroup, classNameLabel} = props;
  let { FormControlLabel, render, meta: { touched, error } } = props;


  value = `${value}`;
  return (
    <FormControl sx={sx} variant={variant} fullWidth={fullWidth} margin={margin} className={clsx(classes, className,{'d-flex': inline })}>
      {label && <label className={clsx('MuiFormLabel-root', classNameFormLabel)}>{label}</label>}
      <RadioGroup
        className={classNameGroup}
        onChange={(value )=> {
          onChange && onChange(name, value.target.value)
        }}
        {...{
          name,
          value,
          row:inline,
          type: type || 'radio',
          defaultValue: value
        }}
      >
        {options && options.map((option, index) => (
          <ControlLabel
            key={index}
            {...{
              FormControlLabel,
              id: option?.id,
              value: `${option?.value}`,
              label: typeof render === 'function' ? render(option): option?.label,
              className: clsx(option?.className, classNameLabel),
              control: control || <Radio />,
              disabled,
              labelPlacement,
            }}
          />
        ))}
      </RadioGroup>
      {touched && (error && <FormHelperText>{error}</FormHelperText>)}
    </FormControl>
  )
};

RenderRadiosBox.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  className: PropTypes.node,
  classNameGroup: PropTypes.node,
  classNameLabel: PropTypes.node,
  style: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.string,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object,
  labelPlacement: PropTypes.node,
  margin: PropTypes.node,
  fullWidth: PropTypes.bool
};

RenderRadiosBox.defaultProps = {
  labelPlacement: 'end',
  margin: 'normal',
  disabled: false
};

export default RenderRadiosBox;
