import getPreview from "../../utils/getPreview";
export { default as zoomTo } from "../../utils/zoomTo";
export { default as getPreview } from "../../utils/getPreview";
export { default as getRadio } from "../../utils/getRadio";


const getMaxMin = (size: number, preview: number) => {
	const max = Math.round(((size - preview)) /2);
	const min = -(max);
	let pecentMin = 50 - (max/size) * 100;
	let pecentMax = 50 + (max/size) * 100;

	return [max, min, pecentMax, pecentMin ]
}

export const getAttribute = (preview: string, width: number, height: number) =>{
	let [previewWidth, previewHeight ] = getPreview(preview);
	let [maxX, minX, pMaxX, pMinX] = getMaxMin(width, previewWidth);
	let [maxY, minY, pMaxY, pMinY] = getMaxMin(height, previewHeight);

	return [[maxX, minX, maxY, minY], [pMaxX, pMinX, pMaxY, pMinY]];
}


export function clamp(val: number, min: number, max: number) {
	return val < min ? min : val > max ? max : val;
}

export function getPoint({ x, y, width, height }: any){
	return {
		pointX: width * (50 - x)/100,
		pointY: height * (50 - y)/100
	}
}


function getPos(pos: number, min: number, max: number){
	if(pos > max){
		pos = max;
	}else if(pos < min){
		pos = min;
	}

	return pos;
}

export function clamped({ x, y, preview, width, height, ...props}: any){
	const [[], [maxX, minX, maxY, minY]]= getAttribute(preview, width, height);
	x = getPos(x, minX, maxX);
	y = getPos(y, minY, maxY);
	return { ...props, x, y, preview, width, height }
}
