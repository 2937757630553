import React, { Fragment } from "react";
import clsx from 'clsx';

import Box from "@mui/material/Box";
import Top from "@xem/icon/base/Top";
import Button from "reactstrap/lib/Button";
import WidgetBox from "@gqlapp/base/View/WidgetBox";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import RenderField from "@gqlapp/base/RenderField";
import RenderDropdownField from "@xem/look/Renders/Field/Dropdown";
import ControlRender from './Control';
// import { fieldConfig as BaseConfig} from "../Utils";
import { Frame } from "../../../../utils/Frame";
import Radios from "@gqlapp/base/RenderRadiosBox";
import addMap from '@xem/entity-client-react/helpers/addMap';
import Checked from "@xem/icon/base/Checked.svg";
import BaseConfig from '@xem/look/utils/fieldConfig';
const BaseForm = ((props) => {
	let { site, values, setFieldValue } = props;
	let { append, onActions, setScrollTop, zoomin } = props;
	let { frame } = values || {};
	let disableText = Frame[frame?.style]?.disableText;

	/**
	 * BaseField
	 * @param title
	 * @param name
	 * @param value
	 * @param valueLabel
	 * @param items
	 * @param props
	 * @returns {any}
	 */
	function BaseField({ title, name, value, label: valueLabel, items, ...props}){

		let menus = [{ type: 'group', items }]

		const sx = {
			'& div[data-modal-header] > div.header-inner, & div[data-modal-scroll], & div[data-modal-scroll] > div[data-modal-body]':{
				margin: 0,
				backgroundColor: '#ffffff'
			},
			'& div[data-modal-scroll] > div[data-modal-body]':{
				padding: '0 15px',
				'& .btn': {
					border: 'none',
					'& .row':{
						minHeight: '40px',
						'& > .col, & > .col-auto': {
							display: 'inline-flex',
							whiteSpace: 'nowrap',
							alignItems: 'center'
						}
					}
				}
			}
		}
		
		/*** BaseConfig */
		return BaseConfig({
			label: title,
			value: valueLabel,
			component: RenderDropdownField,
			onClick: function onClick(){
				func(onActions['clicks'])({
					sx, append: append(),
					selectProps:{ webType: 'web' },
					enableClose: true,
					title, value,
					items: menus
				})
			}
		});
	}

	/**
	 *
	 * @param value
	 * @param name
	 * @returns {{onClick: *, name, placeholder: string, value: string}}
	 */
	function fontConfig({ value, name }) {
		value = value || 0;

		let items = [];

		let def = [items, [setFieldValue, name, {
			withoutChange: true,
			wrapper: ({ children })=><>{children}</>
		}], value, null];

		let options =
			[
				'Roboto',
				'Dosis',
				'Open Sans',
				'Lobster',
				'Noto Serif',
				'Dancing Script'
			]

		options.map((name, value)=>addMap(...def, value, (function ({ isActive }){
			return (<Row medium sx={{ fontFamily: `${name}` }}>
				<Col className={'ellipsis ellipsis-one'}>{name}</Col>
				<Col xs={'auto'}>{site?.name}</Col>
				<Col xs={'auto'} sx={{ width: '40px'}}>{(isActive) && <><Checked/></>}</Col>
			</Row>)
		})))

		let label = options[value || 0];
		return BaseField({
			items, name, value, label, valueLabel: label,
			title: 'qr:qrcode.font.title'.t()
		})
	}

	let options = Frame.map((values, value)=>({
		...values, value
	}));

	const Control = ControlRender(options)

	return (<>
		<Box className={'frame'}>
			<Button
				onClick={() => setScrollTop(true)}
				color={'default'} className={clsx('p-0', 'circle-up', {'in': zoomin})}
				style={{ position: 'fixed', bottom: '60px', right: '2px', zIndex: 10 }}>
				<Top/>
			</Button>

			{(!!(frame?.style && !disableText) ) && <Box sx={{
				backgroundColor: '#ffffff',
				padding: '15px 15px 0',
				marginBottom: '15px',
				'& .MuiFormControl-root':{ marginTop: 0, marginBottom: 0 }
			}}>
				<Row small>
					<Col xs={'auto'} style={{ maxWidth: '150px' }} >
						<Field
							{...{ component: RenderDropdownField }}
							{...fontConfig({ value: frame?.font, name: `frame.font`})}
						/>
					</Col>
					<Col>
						<Field {...{
							component : RenderField,
							className: 'font',
							label: 'Chữ khung'.t(),
							name: `frame.text`,
							value: frame?.text,
						}} />
					</Col>
				</Row>
			</Box>}
			<>
				<WidgetBox
					{...{
						title: 'qr:title.frame'.t(),
						color: 'qrcode',
						className: 'qrcode-frame'
					}}
				>
					<Field
						{...{ component: Radios }}
						{...{ classNameGroup: 'frame-style qrcode-grid row row-small', classNameLabel: 'col-auto'}}
						{...{ value: frame?.style, name: 'frame.style', options, onChange: setFieldValue }}
						{...{ control: <Control /> }}
					/>
				</WidgetBox>
			</>
		</Box>
	</>)
})


export default BaseForm;