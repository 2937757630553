import ColorIcon from '../color/Icon.svg';
import Color from "../color/Color";

import Body, { BodyIcon } from '../body';
import Frame, { FrameIcon } from '../frame';
import Eye, { EyeIcon } from '../eye';
import Logo, { LogoIcon } from "../logo";

export const options = [

	{
		icon: FrameIcon,
		label: 'qr:label.frame'.t(),
		value: 'frame',
		render: Frame
	},

	{
		icon: BodyIcon,
		label: 'qr:label.data'.t(),
		value: 'body',
		render: Body
	},

	{
		icon: EyeIcon,
		label: 'qr:label.eye'.t(),
		value: 'eye',
		render: Eye,
	},
	{
		icon: LogoIcon,
		label: 'qr:label.logo'.t(),
		value: 'logo',
		render: Logo
	},

	{
		icon: ColorIcon,
		label: 'qr:label.colors'.t(),
		value: 'color',
		render: Color
	}
]

export default options
	.map(({ render, ...rest})=>
		({...rest, Form: render }));