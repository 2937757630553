import React from 'react';
import TextField from "./TextField";

/**
 *
 * @type {function(*=): *}
 */
const Arrow = function RenderArrow(props){
  let arrowProps = props?.arrowProps || {}

  if(props?.onClick){
    Object.assign(arrowProps, {
      onClick: props?.onClick
    })
  }

  return (<TextField {...props} {...{ arrowIcon:{ arrowProps }, endProps: {
    sx: {
    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2,
    cursor: 'pointer',
      '&> div, &> div > .btn':{
        width: '100% !important',
        height: '100% !important',
      },
      '&> div > .btn':{
        textAlign: 'right',
        paddingRight: '10px !important'
      },
  }} }} />)
};

export default Arrow;
