const getCurrency = require('@gqlapp/base/utils/getCurrency').default

Number.prototype.round = function (fractionDigits){
  fractionDigits = fractionDigits || 2;
  let num = this;
  return Number.isInteger(num) ? num : Number(num.toFixed(fractionDigits))
}


Number.prototype.toCurrency = function (args){
  args = args || {};
  let number = this;
  return getCurrency(number, args);
}

