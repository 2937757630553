import React from 'react';
import clsx from "clsx";
import Box from "@mui/material/Box";
import Scroll from "@gqlapp/base/Scroll";
import Map from "@gqlapp/base/Item/map";
import Button from 'reactstrap/lib/Button';
import SlideLeft from "@xem/entity-client-react/helpers/icons/SlideLeft.svg";
import SlideRight from "@xem/entity-client-react/helpers/icons/SlideRight.svg";

function Footer(props){
	const { tabValue, setTab, frameOptions } = props;

	function onClick(value){
		return function onClick(e){
			setTab(value);
		}
	}


	const sx = ({
		'&':{
			position: 'sticky',
			width: '100%',
			zIndex: 998,
			bottom: 0,
			left: 0,
			right: 0,
			top: 0,
			paddingTop: 0,
			backgroundColor: '#ffffff',
			'[data-scroll]':{ zIndex: 1 },
			'[data-arrow-end], [data-arrow-start]': {
				boxShadow: '0px 0px 3px rgba(176, 176, 176, 0.5)',
				position: 'absolute',
				top: 0,
				zIndex: 999,
				marginTop: '-3px',
				paddingBottom: '3px'
			},
			'[data-arrow-start]': { left: 0 },
			'[data-arrow-end]': { right: 0},
			'& .item':{
				display: 'flex',
				justifyContent: 'center',
			}
		},
	})

	return <>
		<Box sx={sx} className={'d-flex justify-content-evenly'}>
			<Scroll
				none
				className={'justify-content-evenly'}
				style={{ minWidth: '100%'}}
				scrollStart={<SlideLeft />}
				scrollEnd={<SlideRight />}>
				<Map items={(frameOptions)}>
					{({ icon: Icon, label, value }) =>{
						return <Box className={clsx('item', { 'active': value === tabValue})}>
							<Button color={'default'} onClick={onClick(value)}>
								<Box className={'w-100 text-center'}><Icon width={'24px'} height={'24px'} /></Box>
								<Box className={'w-100 text-center'}>{`${label}`.t()}</Box>
							</Button>
						</Box>
					}}
				</Map>
			</Scroll>
		</Box>
	</>
}

export default Footer;