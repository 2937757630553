import React  from 'react';
import * as PropTypes from 'prop-types';
import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
import Button from 'reactstrap/lib/Button';
import Dropzone from 'react-dropzone';

const UploadButton = ({ accept, maxFiles, style, children, name, width, height, type, multiple, callback, submitting: disabled }) => {
  return (
    <>
      <Dropzone
        {...{
          multiple,
          disabled,
          accept,
          maxFiles,
          onDrop: acceptedFiles => {
            acceptedFiles.map(async file => Object.assign(file, {
              preview: URL.createObjectURL(file)
            }))
            callback(name, acceptedFiles, type)
          }
        }}
      >
        {({ getRootProps, getInputProps })=>(<div
          {...{ className: 'dropzone',
            width: width,
            height: height
          }}
          style={{ height: 'auto', ...style }} {...getRootProps()}>
          <input {...getInputProps()} />
          {(children) && children}
          {(!children) &&<Button
            disabled={disabled}
            type={'button'}
            size={'sm'}
            style={{ minWidth: '150px'}}
            color={'orange'}
            className={'text-white text-uppercase'}
          >{'Chọn ảnh'.t()}</Button>}
        </div>)}
      </Dropzone>
    </>
  )
};

UploadButton.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func
};


/**
 * View File
 * @param t
 * @returns {function({file: *}): Promise<{file: *, url: *}>}
 */
export const onLoadFile = async ({ file }) => {
  let url = file.preview;
  return { url, file }
};


/**
 *
 */
export default compose(
  withProps(({ onPreview }) => ({ onPreview: onPreview || onLoadFile })),
  withProps(({ callback, onPreview, onChange, alert }) => ({
    callback: callback || (async (name, files, type = '', ...props) => {
      onPreview({ file: [...files].shift(), type })
        .then(({ url, file, ...rest} = {})=>{
          onChange(`${name}`, { ...rest, url })
          onChange('file', file)
        })
        .catch(e => {
          msg({ message: e.toString() });
        })
    })
  })
))(UploadButton);
