import React from 'react';

import Swipeable from "../Swipeable/Swipeable";
import Box from "@mui/material/Box";

export default class ModalClass extends React.Component {
  constructor(props) {
    super(props);
    this.body = this.body.bind(this);
    this.header = this.header.bind(this);
    this.footer = this.footer.bind(this);
    this.handlers = this.handlers.bind(this);
  }

  handlers(key){
    return { swipeProps: {...this.props?.swipeProps }, mkey: key, key }
  }

  header() {
    const { headerProps, header, headerRef, disableHeaderSwipeable} = this.props
    let handlers = { };

    if(!disableHeaderSwipeable){
      handlers = this.handlers('header');
    }

    return (<>
      {(!!header) && <Swipeable
        data-modal-header
        {...headerProps}
        {...handlers}
        innerRef={(self)=> { headerRef.current = self }}
      >
        {typeof header === 'function' && header(this.props)}
        {typeof header !== 'function' && header}
      </Swipeable>}
    </>)
  }


  footer() {
    const { footer, footerProps, footerRef, disableFooterSwipeable } = this.props
    let handlers = { };
    if(!disableFooterSwipeable){
      handlers = this.handlers('footer');
    }

    return (<>
      {(!!footer) && <Swipeable
        data-modal-footer
        {...footerProps}
        {...handlers}
        innerRef={(self)=> { footerRef.current = self }}
      >
        {typeof footer === 'function' && footer(this.props)}
        {typeof footer !== 'function' && footer}
      </Swipeable>}
    </>)
  }

  body() {
    const { children, scrollRef, contentRef, disableBodySwipeable, bodyProps } = this.props

    let handlers = { };
    if(!disableBodySwipeable){
      handlers = this.handlers('body');
    }

    const innerScrollRef = (node) =>{
      scrollRef.current = node;
      this.props?.innerScrollRef && this.props?.innerScrollRef(node)
    }

    return (<>
      {(!!children) && <Swipeable
        data-modal-scroll
        {...bodyProps}
        {...handlers}
        innerRef={innerScrollRef}
      >
        <div data-modal-body ref={contentRef}>
          {typeof children === 'function' && children(this.props)}
          {typeof children !== 'function' && children}
        </div>
      </Swipeable>}
    </>);
  }


  render() {
    let { className, contentProps, overlayRef } = this.props;
    return <>
      <Box
        key="overlay"
        aria-modal="true"
        role="dialog"
        data-modal-root
        data-modal-content
        data-modal-overlay
        tabIndex={-1}
        ref={overlayRef}
				className = {className}
        {...contentProps}
      >
      <this.header/>
      <this.body/>
      <this.footer/>
      </Box>
    </>;
  }
}
