export default class Media {
  public id: number;
  public image: any;
  private _edit: boolean = false;
  private _del: boolean = false;
  private _create: boolean = false;
  public type: number = 0;
  /**
   * instanse
   */
  public static instanse() {
    return new this();
  }

  get del(): boolean {
    return this._del;
  }

  get edit(): boolean {
    return this._edit;
  }

  get create(): boolean {
    return this._create;
  }

  /**
   * save
   */
  public save({ id, type, edit, delete:del, create, image }: any) {
    this.id = id;
    this._edit = edit;
    this._del = del;
    this._create = create;
    this.type = type;
    this.image = image;
  }
}