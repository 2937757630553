import React  from 'react';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import * as PropTypes from "prop-types";

const SortableItem = (({ value }) => (
  <>
    {value}
  </>
));


const renderBody = ({columns, datasource, sortableClassname, useDragHandle}) =>
  (datasource || []).map((entry, index) =>
    (<SortableItem
      className={sortableClassname}
      useDragHandle={useDragHandle}
      key={index}
      index={index}
      value={renderData(columns, entry)}
    />));

const renderData =
  (columns, entry) => (columns || [])
    .map(({ dataIndex, className, render }, key) =>
      (<Col
        key={key}
        className={className || `${dataIndex}`}
      >{render ? render(entry[dataIndex], entry) : entry[dataIndex]}
      </Col>));


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Grid = (props) => {
  /**
   *
   */
  let {
    tag: Tag,  sx, none, mini, small, big, medium,
    columns, datasource, onSortEnd, wrapRef, className, helperContainer, useDragHandle, ... attribute } = props;
  Tag = Tag || Row;
  return (<>
    <Tag {...{ ...attribute, sx, none, mini, small, big, medium }} ref={wrapRef} className={className}>
      {renderBody({...props, columns, datasource})}
    </Tag>
  </>)
};


Grid.defaultProps = {
  useDragHandle: true
}

Grid.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func
};


export default Grid;
