import React from "react";
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Style = ({ renderer, className }) => (
    <div className={clsx(className, 'Qr-item Qr-item-selected')}>
        <>{renderer}</>
    </div>
);

Style.propTypes = {
    renderer: PropTypes.object.isRequired
}

export default Style;
