import getPreview from "./getPreview";


/**
 * zoomTo
 * @param preview
 * @param newWidth
 */
function zoomTo(preview: string = '0:0', newWidth: number){
  let [width, height] = getPreview(preview);
  let newHeight=0;
  
  const ratio = newWidth / width;
  newHeight = height * ratio
  return [newWidth, newHeight]
}

/**
 *
 * @param options
 */
export default function autoSize(options: any[]){
  return function ({ style, windowWidth, width, height }){
    
    if(!style){
      style = 0;
      if(width > height){ style = 1; }
      else if(width < height){ style = 2; }
    }
    
    let [w, h] = options[style].split(':').map((v: string)=>parseFloat(v));
    
    if(windowWidth){
      let ww = (windowWidth > 375 && windowWidth <= 500) ? windowWidth:  375;
      
      if(style === 1){
        let [newW, newH] = zoomTo(options[style], ww);
        (w = newW);
        (h = newH);
      }
    }
    
    return `${w}:${h}`;
  }
}
