import React from 'react';
import * as PropTypes from "prop-types";
const IconRemove = (props) => (<>
    <svg aria-hidden="true" {...props} viewBox="0 0 24 24" fill={'currentColor'}>
        <path fill={'currentColor'}
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
</>);


IconRemove.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
}


IconRemove.defaultProps = {
    width: '20px',
    height: '20px'
}

export default IconRemove;
