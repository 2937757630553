import React from "react";

import ImageBase from "@gqlapp/base/Image/ImageBase";
import getRatio from "../../utils/getRadio";
import BaseCropping from "./BaseCropping";

class Cropping extends ImageBase {


	async componentDidUpdate(prevState: any, prevProps: any)
	{
		let self = this;
		let { src } : any = this.props;

		if(prevProps.src !== src ){
			await self.setState({ ...prevState, loaded: false });
			this.setPreloader(this, src);
		}
	}

	render() {
		let { children, preview, width: baseWidth, height:baseHeight, src, zoom, wrapWidth, wrapHeight, ...props }: any = this.props;
		let { onMove, onStart, onEnd, cropRef }: any = props;

		zoom = zoom || 1;
		let [width, height] = getRatio({ ...this.state, preview, baseWidth, baseHeight });
		width = width * zoom;
		height = height * zoom;
		// @ts-ignore
		src = this.state?.blob || src;

		const handleMove = (value: any) =>(onMove && onMove(value))
		const handleStart = (value: any) =>(onStart && onStart(value))
		const handleEnd = (value: any) => (onEnd && onEnd(value))

		return <>
			<BaseCropping
				{...props}
				{...{ preview, src, width, height, wrapWidth, wrapHeight }}
				{...{
					ref: cropRef,
					onMove: handleMove,
					onStart: handleStart,
					onEnd: handleEnd,
				}}
			/>
		</>
	}
}

export default Cropping;
