import React, {Fragment} from 'react';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Delete from "@xem/icon/qrcode/Delete.svg";
import Button from "reactstrap/lib/Button";
import ImageView from "@gqlapp/base/Image/Image";
import Box from "@mui/material/Box";
import style from "./Style";



/**
 *
 * @param RenderTools
 * @param mediaDelete
 * @param values
 * @param image
 * @param icon
 * @param setValues
 * @returns {function({id: *, enabled: *, url: *, del: *, xSize: *, wrapXsize: *, wrapYsize: *, svg: *, data: *, imgWidth: *, imgHeight: *}=): *}
 */
export function columnRender({ RenderTools, mediaDelete, values, values: { image, icon  }, setValues }) {
	return function ({ id, enabled, url, del, xSize, wrapXsize, wrapYsize, svg, data, imgWidth, imgHeight } = { }){
		
		let menus = [];
		menus.push({
			hidden: !(id && del),
			children: <Delete fill={'currentColor'} width={14} height={14}/>,
			handleClick: () => mediaDelete(id)
		});
		
		return (<>
			{(!!url || !id) && <>
				<Box className={'toolbar-hover'} sx={{
					position: 'absolute', zIndex: 4, top: -10, right: 0, padding: 0,
					borderRadius: '50%',
					'& .btn':{
						padding: 0,
						borderRadius: '50%',
						svg:{
							boxShadow: "var(--shadow-normal, 0px 1px 3px 0 rgba(69, 65, 78, 0.16)) !important"
						}
					}
				}}>
					{RenderTools({ matches: true, menus})}
				</Box>
				<Box
					className={'item'}
					sx={{
						width: `${wrapXsize}px`,
						height: `${wrapYsize}px`,
						boxShadow:  id === image ? null : 'rgba(0, 0, 0, 0.21) 0px 1px 3px 0px !important'
					}}
					onClick={() => {
						icon = {...icon, enabled, imgWidth, imgHeight, svg, src: data };
						if(!enabled) { icon = { ...icon, svg: null, src: null }; }
						setValues({ ...values, icon, image: id || 0 })
					}}>
					<ImageView
						notEspect isBackground withoutReload
						width={xSize} height={'auto'} src={url}
						style={{
							maxHeight: '100%',
							border: 'none',
							backgroundPosition: 'center',
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat'
						}}
					/>
				</Box>
			</>}
		</>)
	}
}


/**
 *
 * @param props
 * @returns {[{dataIndex: string, className: string, render: (function({id: *, enabled: *, url: *, del: *, xSize: *, ySize: *, wrapXsize: *, wrapYsize: *, svg: *, data: *, imgWidth: *, imgHeight: *}=): *)}]}
 */
export function getColumns(props){
	return [{
		dataIndex: 'item',
		className: 'col-auto col-item toolbars-item',
		render: columnRender({
			...props,
			RenderTools : ({ menus }) =>(<>{
				menus.map(({ children, handleClick, hidden }, idx)=><Fragment key={idx}>
					{(!hidden) && <Button
						{...{
							hidden,
							size: 'lg',
							color: 'default',
							onClick: handleClick,
							className: 'text-danger badge rounded-pill'
						}}
					>
						{children}
					</Button>}
				</Fragment>)
			}</>)
		})
	}]
}

let def = { id: 'new_file', xSize: 40, ySize: 40, wrapXsize: 50, wrapYsize: 50, width: 100, height: 100 }
export default compose(
	style,
	withProps(({ items, ...props})=>{
	let datasource = [];
	
	datasource.push({ item: { ...def,id: 0, url: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0.5 0.5 100 100' fill='%23ADADAD'%3E%3Cg%3E%3Cpath d='M50.5,0.5c-27.614,0-50,22.386-50,50s22.386,50,50,50s50-22.386,50-50S78.114,0.5,50.5,0.5z M50.5,90.5 c-22.091,0-40-17.908-40-40c0-22.091,17.909-40,40-40c22.092,0,40,17.909,40,40C90.5,72.592,72.592,90.5,50.5,90.5z'/%3E%3C/g%3E%3Crect x='-9.5' y='45.5' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -20.9176 50.5001)' width='120' height='10'/%3E%3C/svg%3E%0A" }});
	
	(items || []).map(({ id, del, image: { width, height, svg, ...image }  })=>{
		let { width: svgWidth, height: svgHeight} = svg || {}
		let imgWidth = Number(svgWidth || width);
		let imgHeight= Number(svgHeight || height);
		
		let xSize = imgWidth > imgHeight ? 95 : 40;
		let wrapXsize = imgWidth > imgHeight ? 109: 50;
		datasource.push({ id, item: { ...def, ...image, enabled: !!id, id, del, svg, xSize, wrapXsize, imgWidth, imgHeight }  })
	});
	
	return ({ datasource })
}))