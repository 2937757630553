import React, { Fragment } from "react";
import Base from './Base';
import { getTypeTable, pointCallback } from "./ultil";
import { QRPointType } from '../qrcode'

export class PointBase extends Base {
	/**
	 *
	 * @param $options
	 */
	public listPoints($options: any = {}): any[]{
		let { "isRotation": $isRotation, "out" : $out, "align" : $align, "pos" : $pos, "params" : $params } = $options || {};
		let { "size" : $size, "opacity" : $opacity, "otherColor" : $otherColor, "width" : $width } = $params || {};
		if (!this.$qrcode) return [];

		$align = $align || ((data: any)=>{})
		$pos = $pos || ((data: any)=>{})

		let $nCount = this.$qrcode.getModuleCount();
		let $typeTable = getTypeTable(this.$qrcode);
		let $pointList: any[] = [];
		$size = ($size || 100) / 100;
		$opacity = ($opacity || 100) / 100;
		$otherColor = $otherColor || 'black';

		if ($size <= 0) $size = 1.0;

		pointCallback(this.$qrcode, $nCount, $out, ($xp: number, $yp: number, $x: number, $y: number) => {
			let $data = {
				"isRotation" 		: $isRotation,
				"nCount"				: $nCount,
				"opacity"				: $opacity,
				"size" 					: $size,
				"x" 						: $xp,
				"y" 						: $yp,
				"width"					: $width,
				"otherColor" 		: $otherColor
			};

			try{
				switch ($typeTable[$x][$y]){
					case QRPointType.$TIMING:
						//Điểm quanh timing
						$pointList.push($align($data));
						break;

					case QRPointType.$ALIGN_CENTER:
					case QRPointType.$ALIGN_OTHER:
						// Điểm quanh mắt phụ
						$pointList.push($align($data));
						break;

					case QRPointType.$POS_CENTER:
					case QRPointType.$POS_OTHER:
						// Điểm quanh mắt chính
//							$pointList.push($pos($data));

						break;
					case QRPointType.$DATA:
						// Điểm dữ liệu
						$pointList.push($pos($data));
						break;
					default:
						$pointList.push($pos($data));
				}
			}catch (e){}

		});

		return $pointList.map((e, key)=> <Fragment key={key}>{e}</Fragment>);
	}
}

export default PointBase;
