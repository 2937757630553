import { QRCode } from '../qrcode'
import { pointOptions } from "./ultil";
import React from "react";
import PointBase from "./PointBase";

/**
 * @QrProvider(
 *   id = "dot_random",
 *   title = @Translation("Dot random")
 * )
 */
export class RendererDotRandom extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		$options = {...$options,
			"pos": ($options: any) => {
				let $opts = pointOptions($options, ["opacity", "fill", "cx", "cy", "r-random"]);
				let { "r-random": $r } = $opts || {};
				return <circle {...{...$opts, 'r': $r }} />;
			},
			"align": ($options: any) => {
				let $opts = pointOptions($options, ["opacity", "fill", "cx", "cy", "r"]);
				return <circle {...$opts} />;
			},
		}

		super($options, $qrcode);
	}

}
