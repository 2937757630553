import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "japanese",
 *   title = @Translation("Japanese"),
 *   type = 3
 * )
 */
export class RendererJapanese extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		super({...$options, type: 3 }, $qrcode)
	}
}
