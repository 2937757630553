import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "RendererLeaf",
 *   title = @Translation("Japanese"),
 *   type = 4
 * )
 */
export class RendererLeaf extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		if(!$qrcode){
			$qrcode = new QRCode()
		}
		super({...$options, type: 4 }, $qrcode)
	}
}
