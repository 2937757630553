import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import {graphql} from "react-apollo";
import QUERY from "../../graphql/file/Upload.graphql";
import loading from "@gqlapp/base/utils/loading";
import onUpload from "../onUpload";

/**
 * withUpload
 * @param name
 * @param f
 * @param b
 * @param type
 */
export default function withUpload(name: string, f: string, b: string, type: string) {
  return compose(
    graphql(QUERY, {
      props: ({ mutate, ownProps: { loadbar } }: any) => ({
        uploadFile: async ({ file }: any) =>
          loading(loadbar, async () => {
            try{
              let { data } = await mutate({
                variables: { file, field: f, bundle: b, type },
                fetchPolicy: 'no-cache'
              });
              
              return data?.res;
            }catch (e) {
              return { errors: [e.message] }
            }
          })
      })
    }),
    withProps(({ uploadFile, ...props})=>{
      let $_FUNCTION = {};
      $_FUNCTION[name] = onUpload(uploadFile, props)
      return $_FUNCTION;
    })
  )
}