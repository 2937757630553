const hasPlus = (url) => /\?/i.test(url);
const hasQrtags = (url: string) => /qrtag\=/i.test(url);


const qrcode = (url) =>{
	const REGEX = /\b(?:https?:\/\/)?(?:w{3}\.)?(.*)\/(?<ID>.[A-Za-z0-9_-]{0,20})\S*(?=\s|$)/i

	if(REGEX.test(url) && !hasQrtags(url)){
		if(hasPlus(url)){
			let vals = url?.split('?');
			vals[1] = '?qrtag=1&' + vals[1];
			url = vals.join('');
		}else{
			url = url + '?qrtag=1';
		}
	}
	return url;
}

export default qrcode;
