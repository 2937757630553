import React from 'react';
import * as PropTypes from "prop-types";
const Top = ({...props}) => (<>
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g filter="url(#filter0_d_126_227)">
            <path d="M26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26C20.6274 26 26 20.6274 26 14Z" fill="white"/>
        </g>
        <path d="M19.9057 14.4926L19.081 15.1198L14.592 11.8032V22H13.408V11.8106L8.909 15.1006L8.09431 14.4926L14 10.1391L19.9057 14.4926ZM20 7.87275H8L8 7H20V7.87275Z" fill="black"/>
        <defs>
            <filter id="filter0_d_126_227" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0.31 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_227"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_227" result="shape"/>
            </filter>
        </defs>
    </svg>
</>);


Top.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}


Top.defaultProps = {
    width: 36,
    height: 36
}

export default Top;