export default function isEmpty(vars) {
  if(typeof vars === 'boolean'){
    return !vars;
  }else{
    for (const key in vars) {
      if (vars.hasOwnProperty(key)) {
        return false;
      }
    }
  }
  return true;
}