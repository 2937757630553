import React from 'react';
import loadable from 'next/dynamic';
import withProps from "recompose/withProps";
import withRenderProcess from '@xem/entity/classes/withRenderProcess';
import GenCompose from "../helpers/gen/GenCompose";
import FilterWrapper from "../helpers/gen/FilterWrapper";
import popup from '../helpers/popup';
import Form from "../helpers/gen/Form";
import onSave from "@xem/entity/classes/onSave";
import withGenCreate from "@xem/gen-common/classes/withGenCreate";
import withGenUpdate from "@xem/gen-common/classes/withGenUpdate";

const CreateContainer = loadable(
	()=>import('../components/Gen/View').then(
		(c) => c.default)
);

export default GenCompose({},
	[
		withProps(({ entity })=> ({ refetch: func(entity.refetch) })),
		withProps(({ entity, genUpdate, genCreate })=> ({
			onSave: onSave(genCreate, genUpdate, { entity, errorMsg: 'gen:update.errorMsg'.t() }),
		})),
		withProps(()=>{
			let [genSelects, onGenSelects] = React.useState([])
			return { genSelects, onGenSelects }
		}),

		Form, popup, withRenderProcess(), FilterWrapper,
		// withProps(({ entity })=>debug({ entity }))
	],[withGenCreate, withGenUpdate]
)(CreateContainer);
