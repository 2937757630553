import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "rounded_pointed",
 *   title = @Translation("RoundedPointed"),
 *   type = 0
 * )
 */
export class RendererRoundedPointed extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		super({...$options, type: 0 }, $qrcode)
	}
}
