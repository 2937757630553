import * as Yup from "yup";
import {withFormik} from "formik";
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withUpload from "@xem/entity/classes/file/withUpload";
import Style from "./Style";
import {handleSubmit} from "@xem/custom-page-client-react/components/form/blocks/renderCallback";

export const DataForm = withFormik({
	enableReinitialize: true,
	displayName: `HTML`,
	validationSchema: ({}) => Yup.object().shape({}),
	// @ts-ignore
	handleSubmit: handleSubmit('image',
		async function dataCallback({ file, src, wrapSize, ...data }: any, { onBlockUpload }: any){
			let result = await onBlockUpload({ file });
			let id = result?.entity?.id;
			if(result?.entity?.id){
				Object.assign(data, { images: [{ id }]})
				return { data };
			}
		}),
	mapPropsToValues: ({ weight, data  }: any) => {
		let { } = data || {}
		return ({ })
	}
});

let timeout = null;

export default compose(
	Style,
	withUpload('onBlockUpload', 'images', 'page_block', 'page_block'),
	withProps(({ onBlockUpload })=>({
		onBlockUpload: ({ file }) => new Promise((resolve)=>onBlockUpload({ file })
			.then((v:any) =>resolve(v))
			.catch(errorCatch))
	})),
	DataForm,
	withProps(({ options, values, setFieldValue }: any)=>{
		let { size, src }: any = values;
		
		size = size || 0;
		let preview: string = '0:0';
		try {
			if(options && options?.length)
				preview = options[size]?.size;
		}catch (e) {}
		
		function deleteImage(){
			setFieldValue('src', null);
			setFieldValue('zoom', 1);
			setFieldValue('file', null);
			setFieldValue('upload', null);
			setFieldValue('wrapSize', [null, null]);
		}
		
		function onChangeSize(value: any){
			setFieldValue('wrapSize', value)
		}
		
		function onChange(name: string, value: any){
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				setFieldValue('wrapSize', [null, null]);
				setFieldValue('zoom', 1);
			}, 10)
			
			setFieldValue('file', value);
			setFieldValue('src', value?.preview)
		}
		
		return { src, preview, onChange, setSize: onChangeSize, deleteImage }
	}),
	// withProps(({ values })=>debug(values )),
	withProps({ setSize: function (){}})
)