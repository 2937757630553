import { graphql } from 'react-apollo';
import mapInput from '@gqlapp/base/utils/mapInput';
import compose from "recompose/compose";
import withProps from "recompose/withProps";

import MEDIAS_QUERY from '../graphql/MediasQuery.graphql';

/**
 * withMedias
 * @param input
 */
export const withMedias = (input: any) =>compose(
  withProps((props: any) => mapInput(input, props)),
  graphql(MEDIAS_QUERY, {
    skip: ({ skip }: any) => skip,
    options: ({ page, pageSize, orderBy, direction, filter, fetchPolicy }: any) => ({
      variables: { page, pageSize, orderBy, direction, filter },
      fetchPolicy: fetchPolicy || null,
    }),
    props({
            data: { loading, medias: contents, refetch, error, updateQuery, fetchMore, subscribeToMore },
            ownProps: { medias }
          }: any) {
      
      medias.refetch = refetch;
      medias.fetchMore = fetchMore;
      medias.subscribeToMore = subscribeToMore;
      medias.updateQuery = updateQuery;
      
      if (!loading && contents) {
        medias.update(contents);
      }
      
      return {
        loading,
        updateQuery,
        errors: error ? error.graphQLErrors : null
      };
    }
  })
);

export default withMedias;