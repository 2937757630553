import React from 'react'
import Base from "./Base";
import { withFormik } from "formik";

const DataForm = withFormik({
  enableReinitialize: true,
  displayName: `CroppingForm`,
  mapPropsToValues: ({ size, zoom, x, y }: any) => ({ size: size || 0, zoom: zoom || 1, x: x || 50, y: y || 50}),
  handleSubmit: async (values: any, { props: { onChange } }:{ props:{  } } & any) => onChange && onChange(values)
});


const CroppingForm = DataForm(({ setValues, handleSubmit, values, ...props }: any) => {
  return (<>
    <Base
      {...{ ...props, ...values }}
      {...{
        onMove: ({ x, y }: any)=>setValues({ ...values, x, y }),
        onEnd: handleSubmit
      }}
    />
  </>)
})

export default CroppingForm
