import React from 'react';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { provider  } from "@gqlapp/qrcode/nodevn";
import StyleWrap from "../../utils/style/Style";
import { QrValueMap} from "../../utils/QrValueMap";
import { Frame } from "../../utils/Frame";
import { withQrText } from "@xem/qr-common/classes";
import isString from '@gqlapp/base/utils/isString';
import { withFormik } from "formik";


const QrViewWithForm = withFormik({
	enableReinitialize: true,
	displayName: `QrViewForm`,
	mapPropsToValues: ({ values }) => values
});


const QrViewWithRef = React.forwardRef((props, ref) => {
	const WithForm = QrViewWithForm((props)=>{
		const setValues =  props?.setValues;
		const setFieldValue = props?.setFieldValue;

		// eslint-disable-next-line react-hooks/rules-of-hooks
		React.useImperativeHandle(ref, () => ({ setValues, setFieldValue }));

		let values = QrValueMap(props?.values);

		let Style = props?.styleWrap || StyleWrap
		let text = values?.frame.text || 'SCAN ME'.t();
		let font = values?.frame.font || 0;

		let { width, height }  = Frame[values?.frame?.style || 0];
		const QRView = compose(
			withQrText({ textProps: { text, font, width, height } }),
			withProps(({ svgText:text, values, values: { frame, selectedIndex, data_text } }) => {
				let style = 'frame0';
				try{
					let { style: $frameStyle } = frame || {}

					if(isString($frameStyle) && !Object.keys(Frame).includes($frameStyle)){
						style  = $frameStyle || style;
					}else {
						style  = Frame[$frameStyle].frame || style;
					}
				}catch (e) {}

				let isCircle = style === 'circle';

				try {
					if(selectedIndex >= Object.keys(provider).length || !selectedIndex){
						selectedIndex = 0;
					}
					let Base = provider[selectedIndex];

					const qrcode = new Base({ ...values, text, frame: { ...frame, text, style }, isCircle, data_text : `${data_text}` }, null);
					return { qrcode: qrcode.toSvg() }
				}catch (e) {
					console.warn(e)
				}
			}),
		)(({ qrcode: renderer, viewStyle: style, className, style:{ ...rest } = {} }) => (<>
			<div {...{ style, className }}>
				<Style {...{...rest, renderer}} />
			</div>
		</>));

		return <QRView {...props} {...{ values }} />
	});

	return <WithForm {...props} />
});

export const ref = { modules: null };

const onAppCreate = async (modules) => (ref.modules = modules);


const QrView = (props)=>{
	return <QrViewWithRef {...props} ref={onAppCreate} />
}

export default QrView;
