export { Base } from './Base';
export { EdgeCutsBase } from './EdgeCutsBase';
export { LineBase } from './LineBase';
export { PointBase } from './PointBase';
export { QRCode, QRPointType } from '../qrcode'

import { RendererRect } from "./RendererRect";
import { RendererMosaic } from "./RendererMosaic";
import { RendererDot } from "./RendererDot";
import { RendererDotRandom } from "./RendererDotRandom";
import { RendererLine2 } from "./RendererLine2";
import { RendererLine } from "./RendererLine";
import { RendererDotRounded } from "./RendererDotRounded";
import { RendererEdgeCuts } from "./RendererEdgeCuts";
import { RendererEdgeCutsSmooth } from "./RendererEdgeCutsSmooth";
import { RendererJapanese } from "./RendererJapanese";
import { RendererLeaf } from "./RendererLeaf";
import { RendererPointed } from "./RendererPointed";
import { RendererPointedEdgeCut } from "./RendererPointedEdgeCut";
import { RendererPointedIn } from "./RendererPointedIn";
import { RendererPointedInSmooth } from "./RendererPointedInSmooth";
import { RendererPointedMooth } from "./RendererPointedMooth";
import { RendererPointedRound } from "./RendererPointedRound";
import { RendererRoundIn } from "./RendererRoundIn";
import { RendererRoundInSmooth } from "./RendererRoundInSmooth";
import { RendererRoundedPointed } from "./RendererRoundedPointed";
import { RendererStar } from "./RendererStar";
import { RendererDiamond } from "./RendererDiamond";
import { RendererStar2 } from "./RendererStar2";
import { RendererCross } from "./RendererCross";
import { RendererHeart } from "./RendererHeart";


export const provider = [
    RendererRect,
    RendererMosaic,
    RendererDot,
    RendererDotRandom,
    RendererLine2,
    RendererLine,
    RendererDotRounded,
    RendererEdgeCuts,
    RendererEdgeCutsSmooth,
    RendererJapanese,
    RendererLeaf,
    RendererPointed,
    RendererPointedEdgeCut,
    RendererPointedIn,
    RendererPointedInSmooth,
    RendererPointedMooth,
    RendererPointedRound,
    RendererRoundIn,
    RendererRoundInSmooth,
    RendererRoundedPointed,
    RendererStar,
    RendererDiamond,
    RendererStar2,
    RendererCross,
    RendererHeart
]
