import withProps from "recompose/withProps";
import root from "@xem/look/utils/Popup";

export default withProps({ sx: ({
    ...root,
    '--modal-popup--bg': '#fff',
    '& [data-modal-overlay], & [data-modal-header]':{
      backgroundColor: '#ffffff'
    },
    '& [data-modal-scroll]':{
      '& [data-modal-body]':{
        boxShadow: 'none',
        minHeight: 'calc(100% - 50px)',
        padding: '0',
        '& .qrcode-data, & .qrcode-frame, & .qrcode-eye':{
          '&> .widget-body':{
            '& > div':{
              margin: 0
            }
          }
        },
        '& .frame-style, & .frame-body, & .frame-eye':{
          flexDirection: 'row',
          label:{
            '&:not(.Mui-checked) span':{
              boxShadow: 'rgba(0, 0, 0, 0.21) 0px 0px 3px 0px'
            },
            '& > span':{
              width: '50px',
              height: '50px',
              borderRadius: '0.25rem',
              svg:{ width: '35px', height: '35px', fill: '#000000' },
            }
          },
          '& label > span':{ padding: '11px' }
        },
        '& .frame-style label > span':{ padding: '7px'}
      },
    },
    '& [data-modal-footer]':{
      boxShadow: '0px 0px 1px 2px rgba(176, 176, 176, 0.21)',
      '& .item':{
        zIndex: 10,
        paddingTop: '3px',
        lineHeight: '50px',
        border: 0,
        borderTop: '3px solid #00000000',
        '&.active':{
          borderTopColor: '#0066CC'
        }
      },
      '& .btn':{
        padding: 0,
        minWidth: '74px',
        fontSize: '12px'
      }
    }
  }) });
