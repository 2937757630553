import { graphql } from 'react-apollo';
import { STATUS, STATUS_SAVE } from '../../graphql';
import removeTypename from '@gqlapp/base/utils/removeTypename';

export const withStatus = ({ fetchPolicy }: any) => (Component: any) =>
  graphql(STATUS, {
    options: { fetchPolicy: fetchPolicy || null, },
    props({ data: { loading, content, error }, ownProps: { status } }: any) {
      if(!loading){
        status.save(removeTypename(content|| {}));
      }
      return { loading, errors: error ? error.graphQLErrors : null };
    }
  })(Component);

/**
 * withLinkCreate
 * @param Component
 */
export const withStatusSave = () => (Component: any) =>
  graphql(STATUS_SAVE, {
    props: ({ mutate }: any) => ({
      statusSave: async (variables: any) => {
        const {
          data: { statusSave }
        }: any = await mutate({ variables });
        return statusSave;
      }
    })
  })(Component);
