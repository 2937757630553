import React from "react";
import withProps from "recompose/withProps";
import isEmpty from "@gqlapp/base/utils/isEmpty";
import Popup from "@gqlapp/base/Modal/Popup";
import uuid from "@gqlapp/base/utils/uuid";

/**
 * saveCallback
 * @param values
 */
export function saveCallback(values: any){
	Object.keys(values).map((key)=>{
		if(isEmpty(values[key])){ delete values[key]; }
	});
	return values;
}



/**
 * handleSubmit
 * @param type
 * @param dataCallback
 */
export function handleSubmit(type: string, dataCallback?: any): any{
	dataCallback  = dataCallback || (function (data: any){
		return { data };
	});

	return async function handleSubmit({ weight, ...data}: any, { props, props: { onSave, onClose, keyID: key }}){
		let values = await dataCallback(saveCallback(data), props);
		onSave({ ...values, key: key || uuid(), type, weight }, onClose);
	}
}

type Props = {
	dataRenders: any;
	blockRenders: any;
	blockActions: any[];
}

/**
 * indexCallback
 * @param ref
 * @param DataForm
 * @param icon
 * @param type
 * @param text
 */
export function indexCallback(ref: any, DataForm: any, icon: any, type: string, text: string){
	return withProps((props: any & Props) => {
		let dataRenders = props?.dataRenders || {}
		let blockActions = props?.blockActions || []

		blockActions.push({
			icon, text, callback: (rest: any) => {
				// @ts-ignore
				try { ref.current.open({ ...props, ...rest, saveCallback }); } catch (e) {}
			}
		})

		dataRenders[type] = (rest: any) => (<Popup {...{ ...props, ...rest, ref }} component={DataForm} />)

		return { dataRenders, blockActions }
	})
}


/**
 *
 * @param ref
 * @param blockType
 * @param Render
 */
// @ts-ignore
export default function renderCallback(ref?: any, blockType: string, Render: any) {
	return withProps((props: any) => {
		let blockRenders = props?.blockRenders || {}
		blockRenders[blockType] = function callback({ data: values, key: keyID, ...res }: any, toolProps: any = null, WrappedComponent?: any){
			values = saveCallback(values);
			let obj = { ...props, ...res, saveCallback, keyID, view: !ref, WrappedComponent, values }

			if(ref){
				Object.assign(obj, {
					onClick: (rest: any)=>{
						return function onClick(e: any){
							e.preventDefault();
							try { ref.current.open({ ...props, ...res, ...rest, saveCallback, keyID, toolProps, blockType });} catch (e) {}
						}
					}
				})
			}
			return ((rest: any) => React.createElement(Render, rest))(obj)
		}

		return ({ blockRenders })
	});
}