import React from 'react';
import * as PropTypes from "prop-types";
const Delete = ({...props}) => (<>
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M34 0H2C0.895431 0 0 0.895431 0 2V34C0 35.1046 0.895431 36 2 36H34C35.1046 36 36 35.1046 36 34V2C36 0.895431 35.1046 0 34 0Z" fill="#C21C21"/>
        <path d="M11.4999 25.0002C11.5016 25.5301 11.7128 26.0378 12.0875 26.4124C12.4621 26.7871 12.9698 26.9983 13.4997 27H21.4998C22.0297 26.9983 22.5374 26.7871 22.912 26.4124C23.2867 26.0378 23.4979 25.5301 23.4996 25.0002V12.9996H11.4999V25.0002ZM24.5004 9.9999H21.0003L20.0004 9H15L14.0001 9.9999H10.5V11.9997H24.5004V9.9999Z" fill="white"/>
    </svg>
</>);

Delete.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    className: PropTypes.node
}

Delete.defaultProps = {
    width: 36,
    height: 36
}

export default Delete;
