import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';

/**
 *
 * @param input
 * @param propsInput
 * @param propsBefore
 * @param imageSkip
 * @constructor
 */
export const ModulesCompose = (input?: any, propsInput?: any[], propsBefore?: any[], imageSkip?: boolean) => {

	return (Route: any) => {

		/**
		 * witRoute
		 */
		const witRoute = compose(
			withProps(input),
			...(propsBefore || []),
			...(propsInput || []),
			withLoading
		)(Route);


		// @ts-ignore
		witRoute.getInitialProps = async ({}) => {
			let ctx = {};
			return ({...ctx})
		}

		return witRoute;
	};
};

export default ModulesCompose;
