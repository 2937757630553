import React from "react";
import dynamic from "next/dynamic";
import renderCallback from "@xem/entity/classes/renderCallbackR";

const Form = dynamic(() => import('./Form').then((c) => c.default));

const DataForm = ((props) => (
	<>{!__SERVER__ && (!!props?.isOpen) && <><Form {...props} /></>}</>));

let refCreate = React.createRef();
export default renderCallback(refCreate, 'gen.theme', DataForm, { title: `gen:form.gen.theme`.t() })

