import React from 'react';
import TextField from "./TextField";

let timeout = null;
/**
 *
 * @type {function(*=): *}
 */
function RenderField({ ...newProps }){

  let { input, name, removeProps, onRemove } = newProps || {}
  removeProps = removeProps || {}

  Object.assign(newProps, { input })

  try{
    if( typeof onRemove !== 'undefined'){
      Object.assign(removeProps, { onClick: () => onRemove(name, '') })
      Object.assign(newProps, { removeIcon: { removeProps } })
    }
  }catch (e) {
    debug('Field', name, e);
  }

  /*** @return {JSX.Element}*/
  return (<TextField {...newProps} />)
}

export default RenderField;
