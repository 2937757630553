import saveStatus from '@gqlapp/base/utils/saveStatus';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Link from "@xem/link-common/classes/Link";
import withLink from "@xem/link-common/classes/withLink";
import preloadDomain from '@xem/domain-common/classes/preloadDomain';
import withLinkUpdate from "@xem/link-common/classes/withLinkUpdate";
import withLinkUpload from "@xem/link-common/classes/withLinkUpload";
import searchTags from "@xem/taxonomy-common/classes/searchTags";

/**
 * msgCallback
 * @param mes
 */
const msgCallback = (mes: string)=>`${mes}`.replace(/^\%type\:/i,'link:').t()

/**
 * @param Save
 * @param entity
 * @param errorMsg
 */
export const onUpdate = (Save: any, { entity, errorMsg }: any) => async ({ id, ...input}: any) => {
  id = id || entity?.id;
  let values: any;
  if(!id) return;
  values = await Save({ input, id });

  // @ts-ignore
  return saveStatus({ values, msgCallback, errorMsg: errorMsg || 'link:update.errorMsg'.t() });
};


/**
 * onDelete
 * @param Save
 * @param errorMsg
 */
export const onDelete  = (Save: any, { errorMsg }: any = {}) => async (input: any) => {
  let values = await Save(input);
  // @ts-ignore
  return saveStatus({ values, msgCallback, errorMsg: errorMsg || 'delete.errorMsg'.t() });
};


/**
 *
 * @param Save
 * @param refetch
 * @param errorMsg
 */
export const onCreate  = (Save: any, { refetch, errorMsg }: any = {}) => async (input: any) => {
  let values = await Save(input);
  try{ refetch() } catch (e) {}
  // @ts-ignore
  return saveStatus({ values, msgCallback, errorMsg: errorMsg || 'link:create.errorMsg'.t() });
};



/**
 *
 * @param Save
 */
export const onUpload = (Save: any, {}: any)  => async (input: any) => {
  const values = await Save(input);
  // @ts-ignore
  return saveStatus({ values, msgCallback, errorMsg: 'link:upload.errorMsg'.t() });
};


/**
 * View
 * @param t
 * @returns {function({file: *}): Promise<{file: *, url: *}>}
 */
export const onPreview = ({}: any)  => async ({ file }: any) => {
  let url = file.preview;
  return { url, file }
};




export const modalPreload= compose(
  withProps({ entity: Link.instanse() }),
  withLink(({ id, entity, isOpen }: any)=>({ fetchPolicy: 'no-cache', skip: (!isOpen), link: entity, path: id ? `/node/${id}`: null })),
  withProps(({ refetch, entity  })=>{
    return ({
      refetch: ()=> {
        try{ entity.refetch() }catch (e) { }
        try{ refetch() }catch (e) {}
      }
    })
  }),
)

export const preloadModalWithDomain  = compose(
  preloadDomain,
  modalPreload,
  withProps(({ entity, refetch, domains }: any)=>({
    refetch: ()=> {
      try{ domains.refetch() }catch (e) { }
      try{ refetch() }catch (e) {}
      try{ entity.refetch() }catch (e) {}
    }
  })),
)

/**
 * entityProps
 */
export const entityProps  = compose(
  withLinkUpdate,
  withLinkUpload,
  withProps(({ linkUpdate, uploadFile, ...props }: any) => {
    const propsEntity: any = {
      onUpdate: onUpdate(linkUpdate, props),
      onUpload: onUpload(uploadFile, props),
      onPreview: onPreview(props),
    }

    return {
      ...propsEntity,
      propsEntity:propsEntity
    };
  }),
)