/* tslint:disable:no-bitwise */
/* tslint:disable:only-arrow-functions */
export class QRMath {
  public static glog(n: number): number {
    if (n < 1) {
      throw new Error('log(' + n + ')');
    }
    return QRMath.LOG_TABLE[n];
  }

  public static gexp(n: number): number {
    while (n < 0) {
      n += 255;
    }
    while (n >= 256) {
      n -= 255;
    }
    return QRMath.EXP_TABLE[n];
  }

  private static EXP_TABLE: number[];
  private static LOG_TABLE: number[];

  // @ts-ignore
  private static initialize = (function() {
    QRMath.EXP_TABLE = [];
    QRMath.LOG_TABLE = [];
    for (let i = 0; i < 256; i += 1) {
      const rs = QRMath.EXP_TABLE[i - 4] ^ QRMath.EXP_TABLE[i - 5] ^ QRMath.EXP_TABLE[i - 6] ^ QRMath.EXP_TABLE[i - 8];
      QRMath.EXP_TABLE.push(i < 8 ? 1 << i : rs);
      QRMath.LOG_TABLE.push(0);
    }
    for (let i = 0; i < 255; i += 1) {
      QRMath.LOG_TABLE[QRMath.EXP_TABLE[i]] = i;
    }
  })();

  constructor() {
    // @ts-ignore
    QRMath.initialize();
  }
}
