export enum QRPointType{
  $POS_CENTER		= 1,
  $POS_OTHER		= 2,
  $ALIGN_CENTER	= 3,
  $ALIGN_OTHER	= 4,
  $TIMING				= 5,
  $FORMAT				= 6,
  $VERSION			= 7,
  $DATA 				= 8,
}

export default QRPointType;
