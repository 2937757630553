import React from 'react';
import TextField from "./TextField";
import createNumberMask from './createNumberMask';
/**
 *
 * @type {function(*=): *}
 */
const Price = function RenderPrice(props){

  let currency = props?.currency;
  let arrowProps = props?.arrowProps || {}
  let allowNegative = props?.allowNegative;

  if(props?.onClick){
    Object.assign(arrowProps, {
      onClick: props?.onClick
    })
  }

  let mask = createNumberMask({
    ...currency, allowNegative, symbol: ''
  });

  return (<TextField {...props} {...{ mask }} />)
};

export default Price;
