import React, { Fragment } from "react";
import clsx from 'clsx';

import Box from "@mui/material/Box";
import Top from "@xem/icon/base/Top";
import Button from "reactstrap/lib/Button";
import WidgetBox from "@gqlapp/base/View/WidgetBox";
import ControlRender from "../render/Control";
import Radios from "@gqlapp/base/RenderRadiosBox";
import { FieldAdapter as Field } from "@gqlapp/forms-client-react";
import { BodyShape as icons } from '../../../../utils/BodyShape';

const BaseForm = ((props) => {
	let { values, setFieldValue } = props;
	let { setScrollTop, zoomin } = props;
	let { selectedIndex } = values;

	let options = icons.map((values, value)=>({
		...values, value
	}));

	const Control = ControlRender(options)
	return (<>
		<Box>
			<Button
				onClick={() => setScrollTop(true)}
				color={'default'} className={clsx('p-0', 'circle-up', {'in': zoomin})}
				style={{ position: 'fixed', bottom: '60px', right: '2px', zIndex: 10 }}>
				<Top/>
			</Button>
			<>
				<WidgetBox
					{...{
						title: 'qr:title.data'.t(),
						color: 'qrcode',
						className: 'qrcode-data'
					}}
				>
					<Field
						{...{ component: Radios }}
						{...{ classNameGroup: 'frame-body qrcode-grid row row-small', classNameLabel: 'col-auto'}}
						{...{ value: selectedIndex, name: 'selectedIndex', options, onChange: setFieldValue }}
						{...{ control: <Control /> }}
					/>
				</WidgetBox>
			</>
		</Box>
	</>)
})


export default BaseForm;