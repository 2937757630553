import React from 'react';
import * as PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import Label from 'reactstrap/lib/Label';
import Slider from '@mui/material/Slider';
import { compose, withProps } from "recompose";
let timeout = null;

const RenderSlider = compose(withProps(({ name, onChange })=>({
  onChange: (event, value) =>{
    clearTimeout(timeout);
    timeout = setTimeout( () => onChange(name, value), 1);
  }
})))(({ className, label, input, input: { value }, onChange, formik, meta, ...props }) => <>
  <FormGroup>
    {label && <Label>{label}</Label>}
    <>
      <div className={className}>
        <Slider
          {...props}
          {...input}
          onChange={onChange}
          defaultValue={value}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
      </div>
    </>
  </FormGroup>
</>)

RenderSlider.propTypes = {
  input: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default RenderSlider;
