export let QRMode = {
  // tslint:disable-next-line:no-bitwise
  MODE_NUMBER: 1 << 0,
  // tslint:disable-next-line:no-bitwise
  MODE_ALPHA_NUM: 1 << 1,
  // tslint:disable-next-line:no-bitwise
  MODE_8BIT_BYTE: 1 << 2,
  // tslint:disable-next-line:no-bitwise
  MODE_KANJI: 1 << 3
};
