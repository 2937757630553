import throwError from '../throwError';

class SaveStatus {
  static instanse(values: any): SaveStatus {
    return new this(values);
  }

  _errors = [];

  constructor({ values, ...props }: any) {
    const { errors, violations } = values || {};
    if (errors && errors.length) {
      throw throwError(this._errors, errors, props);
    }

    if (violations && violations.length) {
      throw throwError(this._errors, violations, props);
    }
    return values;
  }

  toString() {
    return this._errors.join('\n');
  }
}

export const saveStatus = (props: any): SaveStatus => SaveStatus.instanse(props);
export default saveStatus;
