import { withFormik } from "formik";
import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";

export default  compose(
  withFormik({
    mapPropsToValues: ({ entity }: any)=> {
      let nodes = entity?.nodes || [];

      nodes = nodes.map(function ({ spouses, parents, ...node}){
        spouses = spouses || [];
        parents = parents || [];
        Object.assign(node, { spouses, parents })
        return node;
      })

      let border = 180;

      return {
        onSelect: 0,
        padding: { top: border, bottom: border, left: border, right: border },
        name: entity?.name,
        note: entity?.note,
        root: entity?.root || {},
        nodes, size: [0, 0, 0, 0], scale: 0.5
      }
    },
    handleSubmit: ({ padding, nodes, name, note }, { props: { onSave  }}: any & { props: any }) => {

      onSave( { nodes, title: name, body: note })
        .then(()=>msg({ message:'done'}))
        .catch(errorCatch)
      ;
    },
    enableReinitialize: true,
    displayName: 'FilterForm',
  }),
  withOnFilter
)