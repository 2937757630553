import React from 'react';
import * as PropTypes from 'prop-types';
import TextField, { getConfig, TextMaskCustom } from "../utils/RenderBase";

/**
 *
 * @param shrink
 * @param rows
 * @param multiline
 * @param mask
 * @param guide
 * @param InputProps
 * @param inputProps
 * @param InputLabelProps
 * @param required
 * @param input
 * @param value
 * @param props
 * @returns {*&{valid, input: *, touched: *, meta, error: *}}
 */
export const loadConfig = ({ shrink, rows, multiline, mask, guide, InputProps, inputProps, InputLabelProps, input, input: { value }, required, ...props }) => {
  inputProps = inputProps || {}
  InputProps = InputProps || {}
  InputLabelProps = InputLabelProps || {}

  if(mask){
    Object.assign(inputProps, { mask, guide })
    Object.assign(InputProps, { inputComponent: TextMaskCustom })
  }

  if(value){
    Object.assign(InputLabelProps, { shrink: true })
  }

  return getConfig({
    ...props, input, rows,
    multiline: rows ? true : multiline,
    InputLabelProps, InputProps, inputProps
  })
}


/**
 *
 * @type {function(*=): *}
 */
const RenderField = function RenderField(props){
  return (<TextField {...loadConfig(props)} />)
}

/**
 *
 * @type {{autoComplete: Requireable<ReactNodeLike>, prefix: Requireable<ReactNodeLike>, startAdornment: Requireable<ReactNodeLike>, description: Requireable<ReactNodeLike>, label: Requireable<any>, suffix: Requireable<ReactNodeLike>, type: Requireable<string>, required: Requireable<boolean>, input: Requireable<object>, inline: Requireable<boolean>, endAdornment: Requireable<ReactNodeLike>, children: Requireable<any[]>, meta: Requireable<object>, variant: Requireable<ReactNodeLike>, placeholder: Requireable<string>, value: Requireable<any>, mask: Requireable<any>}}
 */
RenderField.propTypes = {
  required: PropTypes.bool,
  variant: PropTypes.node,
  inline: PropTypes.bool,
  description: PropTypes.node,
  autoComplete: PropTypes.node,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  input: PropTypes.object,
  label: PropTypes.any,
  type: PropTypes.string,
  meta: PropTypes.object,
  mask: PropTypes.any,
  placeholder: PropTypes.string,
  children: PropTypes.array,
  value: PropTypes.any
};

RenderField.defaultProps = {
  meta: {},
  variant: 'outlined',
  margin: 'normal',
  required: false,
  guide: false,
  type: 'text',
  autoComplete:'off',
  size:'small'
};

export default RenderField;
