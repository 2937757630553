import React from 'react';
import MaskedInput from "react-text-mask";
import clsx from "clsx";
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

export const createElement = ({ tag, children, ...props }) =>
  children ? React.createElement(tag || Box, { ...props, children }) : null;

export const Adornment = (props)=>{
  let { InputProps } = props || {};
  let { prefix, startAdornment, startProps } = props || {};
  let { suffix, endAdornment, endProps } = props || {};
  InputProps = InputProps || {};

  startAdornment  = prefix || startAdornment;
  endAdornment    = suffix || endAdornment;

  if(startAdornment){
    Object.assign(InputProps, {
      startAdornment: createElement({
        children: startAdornment,
        className: 'startAdornment',
        ...startProps
      })
    });
  }

  if(endAdornment){
    Object.assign(InputProps, {
      endAdornment: createElement({
        children: endAdornment,
        className: 'endAdornment',
        ...endProps
      })
    });
  }

  return { ...props, InputProps }
}

export const getConfig = (props) =>{
  let { FormHelperTextProps, InputProps, inputProps, autoComplete } = Adornment(props) || {};
  let { multiline, InputLabelProps, type, variant, margin, required, size, placeholder, input, input: { value } = {}, id, style, label } = props || {};
  let { sx, inputMode, meta, meta: { touched, error } = {} } = props;

  value = typeof value === 'undefined' ? '': value;
  inputProps      = inputProps || {}
  InputProps      = InputProps || {}

  Object.assign(inputProps, { autoComplete })
  Object.assign(input, { FormHelperTextProps, InputLabelProps, InputProps, inputProps, multiline, size, type, variant, margin, required })
  Object.assign(input, { sx, inputMode, style, id, label, value, placeholder: placeholder || label || '' })
  return {...props, input, meta: meta || {}, touched, error, valid: !(touched && error)}
}

export const TextMaskCustom = ({ inputRef, name, inline, ...other }) => {
  inputRef = inputRef || (()=>{})
  return (
    <MaskedInput
      {...{
        ...other,
        name,
        id: name,
        ref: (ref) => inputRef(ref ? ref.inputElement : null)
      }}
    />
  )
};

export default ({ input, touched, error, valid, inline, className, classNameDesc, classes, description })=>{
  let helperText = touched && error ? error : null;

  return (<FormGroup className={clsx({'d-flex': inline})}>
    <TextField {...input} {...{ error: !valid, className, classes, helperText }} />
    {description && <div className={clsx(classNameDesc, "description")}>{description}</div>}
  </FormGroup>)
}
