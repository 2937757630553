import withProps from "recompose/withProps";
import compose from "recompose/compose";

// @ts-ignore
import Style from './Filter.Styles';

export default compose(
  Style,
  withProps(({ genSelects, onActions, setFieldValue })=>{
    const peopleCallback = function peopleCallback(values: any, { onClose }){
      onClose && onClose();
    }

    const callback = ({ name, note }: any, { onClose }) => {
      onClose && onClose();
      setFieldValue('name', name)
      setFieldValue('note', note)
      onActions['gen.people.add'] && onActions['gen.people.add']({ callback: peopleCallback })
    }

    const onGenCreate = function onGenCreate(e){
      e.preventDefault();
      onActions['gen.create'] && onActions['gen.create']({ callback })
    }

    const onGenSelect = function onGenCreate(e){
      e.preventDefault();
      debug({ genSelects })
    }

    return { onGenCreate, onGenSelect }
  })
);
