
/**
 *
 */
import Ball1 from './icon/ball/ball-1.svg';
import Ball2 from './icon/ball/ball-2.svg';
import Ball3 from './icon/ball/ball-3.svg';
import Ball4 from './icon/ball/ball-4.svg';
import Ball5 from './icon/ball/ball-5.svg';
import Ball6 from './icon/ball/ball-6.svg';
import Ball7 from './icon/ball/ball-7.svg';
import Ball8 from './icon/ball/ball-8.svg';
import Ball9 from './icon/ball/ball-9.svg';
import Ball10 from './icon/ball/ball-10.svg';
import Ball11 from './icon/ball/ball-11.svg';
import Ball12 from './icon/ball/ball-12.svg';
import Ball13 from './icon/ball/ball-13.svg';
import Ball14 from './icon/ball/ball-14.svg';
import Ball15 from './icon/ball/ball-15.svg';
import Ball16 from './icon/ball/ball-16.svg';
import Ball17 from './icon/ball/ball-17.svg';
import Ball18 from './icon/ball/ball-18.svg';
import Ball19 from './icon/ball/ball-19.svg';
import Ball20 from './icon/ball/ball-20.svg';
import Ball21 from './icon/ball/ball-21.svg';
import Ball22 from './icon/ball/ball-22.svg';
import Ball23 from './icon/ball/ball-23.svg';
import Ball24 from './icon/ball/ball-24.svg';
import Ball25 from './icon/ball/ball-25.svg';
import Ball26 from './icon/ball/ball-26.svg';
import Ball27 from './icon/ball/ball-27.svg';
import Ball28 from './icon/ball/ball-28.svg';
import Ball29 from './icon/ball/ball-29.svg';
import Ball30 from './icon/ball/ball-30.svg';
import Ball31 from './icon/ball/ball-31.svg';
import Ball32 from './icon/ball/ball-32.svg';
import Ball33 from './icon/ball/ball-33.svg';
import Square from './icon/Square.svg';
//** End **/


/**
 * EyeInnerJs
 * @type {({icon: *})[]}
 */
export const EyeInner = [
  { icon: Ball19 },
  { icon: Ball20 },
  { icon: Ball21 },
  { icon: Ball23 },
  { icon: Ball25 },
  { icon: Ball28 },
  { icon: Ball29 },
  { icon: Ball30 },
  { icon: Ball31 },
  { icon: Ball32 },
  { icon: Ball33 },
  { icon: Ball1 },
  { icon: Ball2},
  { icon: Ball3},
  { icon: Ball4},
  { icon: Ball5 },
  { icon: Ball6},
  { icon: Ball7 },
  { icon: Ball8 },
  { icon: Ball9 },
  { icon: Ball10},
  { icon: Ball11 },
  { icon: Ball12 },
  { icon: Ball13 },
  { icon: Ball14 },
  { icon: Ball15 },
  { icon: Ball16 },
  { icon: Ball17 },
  { icon: Ball18 },
]