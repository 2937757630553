'use strict';
/* tslint:disable:no-bitwise */
// ---------------------------------------------------------------------
// QRBitBuffer
// ---------------------------------------------------------------------
export class QRBitBuffer {
  private readonly buffer: number[];
  private length: number;

  public constructor() {
    this.buffer = [];
    this.length = 0;
  }

  public get(index: number): boolean {
    const bufIndex = Math.floor(index / 8);
    return ((this.buffer[bufIndex] >>> (7 - (index % 8))) & 1) === 1;
  }

  public put(num: number, length: number): void {
    for (let i = 0; i < length; i++) {
      this.putBit(((num >>> (length - i - 1)) & 1) === 1);
    }
  }

  public putBit(bit: boolean): void {
    const bufIndex = Math.floor(this.length / 8);
    if (this.buffer.length <= bufIndex) {
      this.buffer.push(0);
    }

    if (bit) {
      this.buffer[bufIndex] |= 0x80 >>> this.length % 8;
    }

    this.length++;
  }

  public getLengthInBits(): number {
    return this.length;
  }
}
