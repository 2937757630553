import getPreview from './getPreview';

export default function getRatio({ preview = '0:0', baseWidth, baseHeight, width, height}: any){
	let [pw, ph] = getPreview(preview);
	width = width || baseWidth;
	height = height || baseHeight;

	if(!!width && !!height){
		const ratioWidth = width / pw;
		const ratioHeight = height / ph;

		if(ratioWidth <= ratioHeight) {
			width = pw;
			height = height / ratioWidth;
		}else{
			width  = width / ratioHeight;
			height = ph;
		}
	}

	return [width, height]
}
