import settings from '@xem/config';
import Action from '@xem/entity/classes/Action';

const paths = {
  path: '/viet-bank.html'
}

export const map = ({ id, page, path }: any) => `${paths[path]}`.replace(/:id/, id).replace(/:page/, page);


export const defaultRoute = { ...settings.route, exact: true, action: Action.instanse(), classNameOnPage: 'theme-page', webType: 'page', runtimejs: false }
export default paths;
