import withProps from "recompose/withProps";
import root from "@xem/look/utils/Popup";
export default withProps({ sx: ({
		...root,
		'&':{
			'& .crop-zone':{
				width: '100%',
				maxHeight: '100%',
				minHeight: '400px',
				position: 'relative'
			},
			'&.full':{ '& .crop-zone': { height: '100%' } },
			'&:not(.full)':{ '& .crop-zone': { height: 'calc(100% - 153px)' } },
			'div[data-modal-body]':{
				marginTop: 0,
				minHeight: 'calc(100% - 15px)',
				height: 'calc(100% - 15px)',
				boxShadow: 'none !important',
				backgroundColor: '#ffffff',
				'& .crop-size':{
					flexDirection: 'row',
					'& > div >label':{
						'&':{
							marginLeft: 'inherit',
							marginRight: 'inherit',
							padding: '5px',
							width: '60px',
							height: '60px',
							borderRadius: '10px',
							flexDirection: 'column',
							border: '1px solid rgb(226, 226, 226)',
							backgroundColor: '#ffffff',
						},
						'&.Mui-checked':{
							border: '1px solid rgb(0, 102, 204)',
							color: 'rgb(0, 102, 204) !important',
						},
						
						'& > span':{
							justifyContent: 'center',
							display: 'flex',
							width: '100%',
							padding: 0,
							'&.MuiFormControlLabel-label':{
								fontSize: '12px',
								textAlign: 'center'
							}
						}
					}
				},
				'& .crop-slider':{ height: '40px', '& .MuiSlider-root':{ padding: '13px 0 0' } },
				'& .crop-radio':{
					'& .MuiFormGroup-root':{ margin: '0 -15px' },
					'& .MuiFormControlLabel-root':{
						marginLeft: 0,
						marginRight: 0,
						padding: '0 15px'
					}
				}
			},
		},
	}) });
