
/**
 * Body
 */
import Body1 from "./icon/body/body-1.svg";
import Body2 from "./icon/body/body-2.svg";
import Body3 from "./icon/body/body-3.svg";
import Body4 from "./icon/body/body-4.svg";
import Body5 from "./icon/body/body-5.svg";
import Body6 from "./icon/body/body-6.svg";
import Body7 from "./icon/body/body-7.svg";
import Body8 from "./icon/body/body-8.svg";
import Body9 from "./icon/body/body-9.svg";
import Body10 from "./icon/body/body-10.svg";
import Body11 from "./icon/body/body-11.svg";
import Body12 from "./icon/body/body-12.svg";
import Body13 from "./icon/body/body-13.svg";
import Body14 from "./icon/body/body-14.svg";
import Body15 from "./icon/body/body-15.svg";
import Body16 from "./icon/body/body-16.svg";
import Body17 from "./icon/body/body-17.svg";
import Body18 from "./icon/body/body-18.svg";
import Body19 from "./icon/body/body-19.svg";
import Body20 from "./icon/body/body-20.svg";
import Body21 from "./icon/body/body-21.svg";
import Body22 from "./icon/body/body-22.svg";
import Body23 from "./icon/body/body-23.svg";
import Body24 from "./icon/body/body-24.svg";
import Body25 from "./icon/body/body-25.svg";



/**
 * BodyShape
 * @type {({icon: *})[]}
 */
export const BodyShape = [
  { icon: Body1 },
  { icon: Body2 },
  { icon: Body3},
  { icon: Body4 },
  { icon: Body5 },
  { icon: Body6 },
  { icon: Body7 },
  { icon: Body8 },
  { icon: Body9 },
  { icon: Body10 },
  { icon: Body11 },
  { icon: Body12 },
  { icon: Body13 },
  { icon: Body14 },
  { icon: Body15 },
  { icon: Body16 },
  { icon: Body17 },
  { icon: Body18 },
  { icon: Body19 },
  { icon: Body20 },
  { icon: Body21 },
  { icon: Body22 },
  { icon: Body23 },
  { icon: Body24 },
  { icon: Body25 }
];