import { QRCode } from '../qrcode'
import LineBase from "./LineBase";

/**
 * @QrProvider(
 *   id = "line",
 *   title = @Translation("Line 1"),
 *   type = 1
 * )
 */
export class RendererLine extends LineBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		super({...$options, type: 1 }, $qrcode)
	}
}
