import ClientModule from '@gqlapp/module-client-react';
import { ApolloLink } from 'apollo-link';
import { apiUrl, apiServerUrl } from "@gqlapp/core-common/net";
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from "extract-files";
import { BatchHttpLink } from 'apollo-link-batch-http';

export default new ClientModule({
	createNetLink : apiLink =>
		ApolloLink.split(
			({ variables }) => (extractFiles(variables)?.files?.size > 0),
			createUploadLink({
				uri: apiUrl, credentials: 'include'
			}),
			// /*
			ApolloLink.split(
				({ operationName: op }) => {
					let data = ['qrText', 'textToPath', 'dataToSvg', 'dataToBase64'];
					let equal = !!data.f(op, { matchFrom: 'equal'}).length
					return !!equal
				},
				new BatchHttpLink({ uri: apiServerUrl, credentials: 'include'}),
				apiLink
			)
			/*/
			apiLink
			/***/
		)
});
