import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import isObject from '../utils/isObject'
import ImageBase from './ImageBase';
import autoImage from "./autoImage";

const Element =
  compose(
    withProps(({ className, notEspect, onLoadBg }) =>({ className: clsx(className, { 'aspect-ratio': !notEspect, 'isBg': !onLoadBg }) })),
    withProps(({ padding, tag, onLoad, className, elementStyle, width, height}) =>({
        elementProps: (typeof tag === 'symbol')? {}: {
          className,
          style: {
            width,
            height,
            padding: onLoad ? padding: null,
            ...elementStyle
          }
        }
      }))
  )(({ tag, children, elementProps }) => React.createElement(tag, elementProps, children));

Element.defaultProps = {
  tag: React.Fragment,
  className: 'd-inline-flex'
}

export const ImageRender = compose(
  withProps(({ src })=>({ src: isObject(src) ? null: `${src}`.replace(/^https?:/i, '')})),
  withProps(({ src, isBackground, loaded })=>({
    onLoadSrc: !!(loaded && src),
    onLoadBg: !!(isBackground && loaded && src),
    onLoad: !!(!isBackground && loaded && src)
  }))
)(({ autoSize, onLoad, onLoadBg, onLoadSrc, alt, width, height, src, style, ...props})=> {

  if(!__DEV__){
    onLoad = __SERVER__ || onLoad;
  }

  if(autoSize){
    let zoom = autoImage(autoSize, width, height);
    ([width, height] = zoom || []);
    style = style || {}
    Object.assign(style, { width, height })
  }
  width = width || null;
  height = height || null;
  alt = alt || ''
  return <>
    <Element {...props} {...{ onLoad, onLoadBg, onLoadSrc }}>
      <img alt={alt} {...{ src, style: { width, height, ...style }}} />
    </Element>
  </>
})

class ImageComponent extends ImageBase {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      width: props?.width,
      height: props?.height,
      src: null,
      blob: null
    };
  }

  render() {
    let props = { ...this.props };
    let { loaded, width, height } = this.state || {};
    Object.assign(props, { loaded })

    if(!props?.withoutReload){
      Object.assign(props, { loaded, width, height })
    }

    return (<ImageRender {...props} />);
  }
}

const Image = (({ ...props}) =><ImageComponent {...props} />)

Image.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
  isBackground: PropTypes.bool,
  notEspect: PropTypes.bool,
  updateOnResize: PropTypes.bool,
  className: PropTypes.node,
  src: PropTypes.string,
  alt: PropTypes.string,
  tag: PropTypes.any,
  withoutReload: PropTypes.bool
};


Image.defaultProps = {
  tag: React.Fragment,
  width: '100',
  height: '100',
  alt: '',
  src: null,
  notEspect: true,
  withoutReload: false
};

export default Image;
