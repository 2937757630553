import { graphql } from 'react-apollo';
import { CROP_QUERY, CROP_SAVE } from '../../graphql';
import mapInput from '@gqlapp/base/utils/mapInput';
// @ts-ignore
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

/**
 * withCropQuery
 * @param input
 */
export const withCropQuery = (input: any) => compose(
  withProps((props: any) => mapInput(input, props)),
  graphql(CROP_QUERY, {
    options: ({ id }: any) => {
      return {variables: {id }, fetchPolicy: 'no-cache'}
    },
    props : ({data: { postion, loading }}: any) => {
      return ({ loading, postion })
    }
  })
);

/**
 * withCrop
 * @param Component
 */
export const withCrop = graphql(CROP_SAVE, {
  props: ({ mutate }) => ({
    cropSave: async (variables:any) => {
      // @ts-ignore
      return await mutate({ variables });
    }
  })
});
