
/**
 * Eye
 */
import Eye1 from "./icon/eyes/eye1.svg";
import Eye2 from "./icon/eyes/eye2.svg";
import Eye3 from "./icon/eyes/eye3.svg";
import Eye4 from "./icon/eyes/eye4.svg";
import Eye5 from "./icon/eyes/eye5.svg";
import Eye6 from "./icon/eyes/eye6.svg";
import Eye7 from "./icon/eyes/eye7.svg";
import Eye8 from "./icon/eyes/eye8.svg";
import Eye9 from "./icon/eyes/eye9.svg";
import Eye10 from "./icon/eyes/eye10.svg";
import Eye11 from "./icon/eyes/eye11.svg";
import Eye12 from "./icon/eyes/eye12.svg";
import Eye13 from "./icon/eyes/eye13.svg";
import Eye14 from "./icon/eyes/eye14.svg";
import Eye15 from "./icon/eyes/eye15.svg";
import Eye16 from "./icon/eyes/eye16.svg";
import Eye17 from "./icon/eyes/eye17.svg";
import Eye18 from "./icon/eyes/eye18.svg";
//** End **/

/**
 *
 * @type {({icon})[]}
 */
export const EyeFrame = [
  { icon: Eye1 },
  { icon: Eye2 },
  { icon: Eye3 },
  { icon: Eye4 },
  { icon: Eye5 },
  { icon: Eye6 },
  { icon: Eye7 },
  { icon: Eye8 },
  { icon: Eye9 },
  { icon: Eye10 },
  { icon: Eye11 },
  { icon: Eye12 },
  { icon: Eye13 },
  { icon: Eye14 },
  { icon: Eye15 },
  { icon: Eye16 },
  { icon: Eye17 },
  { icon: Eye18 },
];