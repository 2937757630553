import RenderField from "@xem/look/Renders/Field";
/**
 *
 * @param props
 */
export default function fieldConfig (props: any) {
	let { setFieldValue, tabIndex, name, value, inputProps, ...rest }: any = props
	tabIndex = tabIndex || 1;
	return {
		...{ onRemove: setFieldValue },
		...{ component: RenderField, inputProps: { ...inputProps, tabIndex } },
		...{ name, value },
		...rest
	}
}
