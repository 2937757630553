import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";
import React from "react";

/**
 * @QrProvider(
 *   id = "dot",
 *   title = @Translation("Dot")
 * )
 */
export class RendererDot extends PointBase {
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "cx", "cy", "r"]);
			return <circle {...$opts} />;
		}
		// @ts-ignore
		$options = {...$options,
			"pos": pointRender,
			"align": pointRender,
		}

		// @ts-ignore
		super($options, $qrcode);
	}
}
