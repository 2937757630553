import React from "react";
import clsx from 'clsx';
import Field from "@gqlapp/forms-client-react/FieldAdapter";

import RenderSlider from "@gqlapp/base/RenderSlider";
import RenderRadiosBox from "@gqlapp/base/RenderRadiosBox";
import ControlRender from './Control';
import Col from "@gqlapp/base/Col";
import Button from 'reactstrap/lib/Button';
import { zoomTo } from "@xem/look/Cropping";
import Dropzone from "@xem/look/Cropping/UploadButton";
import Cloud from "@xem/icon/base/Cloud.svg";
import Delete from "@xem/icon/crop/Delete";

import CroppingForm from "@xem/look/Cropping/Base/CroppingForm";

import BaseLabel from '@mui/material/FormControlLabel';
let timeout = null;
let changeTimeout = null;
let updatetimeout = null;
let currentRef= React.createRef()


function FormControlLabel(props) {
	return <Col><BaseLabel {...props} /></Col>
}

/**
 *
 */
const BaseForm = ((props) => {
	let { src, disableZoom, preview, values, options  } = props;
	const { onPreview, onChange, setValues  } = props;
	const { deleteImage, setSize } = props;
	let { zoom, size, wrapSize  } = values;
	let [ wrapWidth, wrapHeight ] = wrapSize || [];
	
	size = size || 0;

	const SwipeRef = (node) =>{
		clearTimeout(timeout);
		timeout = setTimeout(()=>{
			if(node){
				let w = node?.clientWidth;
				let h = node?.clientHeight;
				if(( !(wrapWidth && wrapHeight)) && w && h){
					setSize([w, h])
				}
			}
		}, 800)
	}

	/**
	 * Điều chỉnh prevew chuẩn theo màn hình
	 */
	let [w, h ] = zoomTo(preview, wrapWidth, wrapHeight) || []
	w = w || wrapWidth;
	h = h || wrapHeight;

	preview = `${w}:${h}`;

	const updateValue = (rest) =>{
		setValues({ ...values, ...rest})
	}

	const Control = ControlRender(options)

	let hasOption = !!(options && options.length);

	return (<>
		<div ref={SwipeRef} className={'crop-zone'}>
			<Dropzone
				{...{ accept: {'image/*': []}, onPreview, onChange }}
				{...{ name: 'upload', multiple: false}}
				style={{
					position: 'absolute',
					bottom: '10px',
					left: '10px',
					zIndex: 99999, height: 'auto', padding: 0
				}}
			><Cloud/></Dropzone>
			<Button
				onClick={deleteImage}
				style={{
					position: 'absolute',
					bottom: '10px',
					right: '10px',
					zIndex: 99999, height: 'auto', padding: 0
				}}
				color={'default'}
			><Delete/></Button>
			{(!!(wrapWidth && wrapHeight)) && <CroppingForm {...{
				...values,
				onChange: updateValue, preview, src, wrapWidth, wrapHeight, cropRef: (node) => {
					(!!node) && (currentRef.current = node);
				}
			}} />}
		</div>
		{(!!(hasOption)) && <div  {...{ style: { width: 'calc(100%)'}}}>
			<div className={'d-flex align-items-center'}>
				<div className={"w-100"} style={{padding: '0 40px', height: '40px'}}>
					<Field {...{
						className: clsx('slider', 'crop-slider', 'd-flex align-items-center'),
						onBlur: () => {}, onFocus: () => {},
						component: RenderSlider,
						onChange: ({}, value) => updateValue({zoom: value}),
						name: 'zoom', value: zoom, min: 1, max: 5, step: 0.01
					}} />
				</div>
			</div>
			<div className={'px-3 d-flex justify-content-center border-top pt-3 pb-1'}>
				<Field
					{...{classNameGroup: 'crop-size row', classNameLabel: 'col'}}
					{...{component: RenderRadiosBox, FormControlLabel}}
					{...{value: size, name: 'size', options, control: <Control/>}}
					{...{
						onChange: function (name, value) {
							let size = parseInt(value);
							/**
							 * Update size
							 */
							updateValue({size})

							/**
							 * Update after change
							 */
							clearTimeout(updatetimeout);
							updatetimeout = setTimeout(() => {
								try {
									let {x, y} = currentRef.current.get();
									updateValue({size, x, y})
								} catch (e) {
								}
							}, 200)
						}
					}}
				/>
			</div>
		</div>}
	</>)
})

export default BaseForm;