import React from 'react';
import loadable from 'next/dynamic';
import UsersCompose from "../helpers/UsersCompose";
import withProps from "recompose/withProps";
import { map } from '../helpers/config';
import withFormFilter from '../helpers/users/Filter';

const ListContainer = loadable(
  ()=>import('./List').then(
    (c) => c.default)
);

export default UsersCompose(
	{},
	[
		withProps(({ query }) => ({
			pageProps: { path: map({ path: 'list' }), other: map({ path: 'listpage', page: ':page' }), query },
		})),
		withFormFilter,
		withProps(({ setFieldValue, entities, onDeletes, onUpdates })=>({
			onBlock: (id)=>onUpdates({ ids: [id], input: { status: false }}),
			onUnblock: (id)=>onUpdates({ ids: [id], input: { status: true }}),
			onDelete: (id)=> onDeletes({ ids: [id] }),
			refetch: () =>{
				try{ entities.refetch() }catch (e) {}
				try{ setFieldValue('page', 1) }catch (e) {}
			}
		}))
	]
)(ListContainer);
