
type errorProps = { message: any; field: string | number; }
type resProps = { [x: string]: any; toString: () => string; }


export default function throwError(_errors: any[], values: any, { errorMsg, msgCallback }) {
	_errors = _errors || [];
	return values.reduce(
		(res: resProps, error: errorProps) => {
			let message: string;
			try {
				message = typeof error === 'string' ? error : error.message;
			} catch (e) {}
			
			if(msgCallback && message){
				message = msgCallback(message);
			}else{
				message = `${message}`.t();
			}
			
			_errors?.push(message);
			
			if (error && error.field) {
				res[error.field] = message;
				res.toString = () => this.toString();
				return res;
			}
			
			return { errorMsg: message, toString: () => this.toString() };
		},
		{ errorMsg }
	);
}