import React from "react";
import { QRCode } from '../qrcode'
import { pointOptions } from "./ultil";
import PointBase from "./PointBase";

/**
 * @QrProvider(
 *   id = "star_2",
 *   title = @Translation("Star 2")
 * )
 */
export class RendererStar2 extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "x", "y", "scale"]);
			let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
			$scale = $scale * 16.5;
			delete $opts['x'];
			delete $opts['y'];
			delete $opts['scale'];
			$opts = {...$opts,
				"transform": `translate(${$x},${$y}) scale(${$scale})`
			}

			return <g {...$opts}><path d="M3.2,0.3l0.6,1.3C4,1.8,4.1,1.9,4.3,1.9l1.4,0.2c0.2,0,0.3,0.3,0.2,0.5l-1,1C4.7,3.7,4.7,3.9,4.7,4.1L5,5.5 c0,0.2-0.2,0.4-0.4,0.3L3.3,5.2c-0.2-0.1-0.4-0.1-0.6,0L1.4,5.8C1.2,5.9,1,5.8,1,5.5l0.2-1.4c0-0.2,0-0.4-0.2-0.5l-1-1 C-0.1,2.4,0,2.2,0.2,2.1l1.4-0.2c0.2,0,0.4-0.2,0.5-0.3l0.6-1.3C2.9,0.1,3.1,0.1,3.2,0.3z" /></g>;
		}

		$options = {...$options, "pos": pointRender, "align": pointRender,}

		super($options, $qrcode);
	}
}
