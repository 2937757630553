
import withProps from "recompose/withProps";
import Square from "@xem/icon/images/shape/Square";
import Horizontal from "@xem/icon/images/shape/Horizontal";
import Vertical from "@xem/icon/images/shape/Vertical";
import { saveImg, saveSvg, shareImg } from "@xem/qr-common";
import {outerHtml} from "@xem/qr-common/utils/util";

export const withQrOptions = withProps(({ onSvgDownload, onImgDownload, shareFile, ...props })=>{
	let filename = props?.entity?.url;

	onSvgDownload = () => saveSvg({  filename, value: 'QR', content: outerHtml(0) });
	onImgDownload = type => {
		return new Promise(() => {
			saveImg({ filename, value: 'QR', content: outerHtml(0), width: 1500, height: 1500, type }).then(() => {} )
		});
	}
	shareFile = type => async () =>{
		return new Promise(() => {
			shareImg({ filename, value: 'QR', content: outerHtml(0), width: 1500, height: 1500, type }).then(() => {} )
		});
	}

	return ({
		shareFile,
		options: [
			{
				value: 0,
				icon: Square,
				size: '315:315',
				label: 'image.size.square'.t()
			},
			{
				value: 1,
				icon: Horizontal,
				size: '562.5:294',
				label: 'image.size.horizontal'.t()
			},
			{
				value: 2,
				icon: Vertical,
				size: '250:400',
				label: 'image.size.vertical'.t()
			}
		],
		buttons: [
			{ onClick: () => onSvgDownload(), label: 'Download .SVG'.t() },
			{ onClick: () => onImgDownload('png'), label: 'Download .PNG'.t() }
		],

		correct_options: [
			{ value: 0, label: '7%'.t() },
			{ value: 1, label: '15%'.t() },
			{ value: 2, label: '25%'.t() },
			{ value: 3, label: '35%'.t() }
		],
		icon_type_options: [
			{ value: 0, label: 'Không'.t() },
			{ value: 1, label: 'custom'.t() }
		]
	})
	}
)

