import compose from "recompose/compose";
import Button from "./Button";
import Divider from "./Divider";
import Form from "./Form";
import Html from "./Html";
import Social from "./Social";
import Text from "./Text";
import Geo from "./Geo";
import BankQr from "./BankQr";
import Image from "./Image";

export default compose(
	Button, Html, Divider, Form, Social,
	Text, Geo, BankQr, Image
);
