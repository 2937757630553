import React, { Fragment } from "react";
import clsx from 'clsx';

import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import Box from "@mui/material/Box";
import Top from "@xem/icon/base/Top";
import Button from "reactstrap/lib/Button";
import WidgetBox from "@gqlapp/base/View/WidgetBox";
import ControlRender from "../render/Control";
import Radios from "@gqlapp/base/RenderRadiosBox";

import { FieldAdapter as Field } from "@gqlapp/forms-client-react";

import { EyeFrame } from '../../../../utils/EyeFrame';
import { EyeInner } from '../../../../utils/EyeInner';
import Tabs, {Tab} from "@gqlapp/base/Tabs";

const BaseForm = ((props) => {
	const [activeTab, tab] = React.useState(0 );
	const [activeCollapse, collapse] = React.useState(0 );

	let { values, setFieldValue, setScrollTop, zoomin } = props;
	let { eyeShape, eyeInner } = values;



	function tabToggle(value){
		setScrollTop(true);
		tab(value);
	}

	function collapseToggle(_, value){
		setScrollTop(true);
		collapse(parseInt(value));
	}

	function a11yProps(index){
		return  ({
			color: activeTab === index ? 'light':'default',
			value: `${index}`,
			style: { 'minWidth': '150px', lineHeight: '18px' },
			onClick: () => tabToggle(index)
		})
	}


	let options = EyeFrame.map((values, value)=>({
		...values, value
	}));

	const Control = ControlRender(options);


	let optionsFrame = EyeInner.map((values, value)=>({
		...values, value
	}));


	const ControlFrame = ControlRender(optionsFrame);

	function onChange(name, value){
		setFieldValue(`${name}.bottom_left.type`, value);
		setFieldValue(`${name}.top_left.type`, value);
		setFieldValue(`${name}.top_right.type`, value);
	}

	return (<>
		<Box>
			<Button
				onClick={() => setScrollTop(true)}
				color={'default'} className={clsx('p-0', 'circle-up', {'in': zoomin})}
				style={{ position: 'fixed', bottom: '60px', right: '2px', zIndex: 10 }}>
				<Top/>
			</Button>
			<>
				<div className={'d-flex justify-content-center'}>
					<div className={'px-0'}>
						<Box sx={{
							backgroundColor: `rgb(118, 118, 128, 0.12)`,
							padding: '2px',
							maxWidth: '360px',
							width: '100%',
							borderRadius: '5px'
						}}
						>
							<Row none>
								<Col xs={6}><Button {...a11yProps(0)}><>{'Một kiểu hoa văn'.t()}</>
								</Button></Col>
								<Col xs={6}><Button {...a11yProps(1)}><>{'Tuỳ chỉnh hoa văn'.t()}</>
								</Button></Col>
							</Row>
						</Box>
					</div>
				</div>
				{(!activeTab) && <>
					<Box sx={{
						paddingTop: '10px'
					}}>
						<WidgetBox {...{ title: 'qr:eyes.eyeShape'.t({ value: ''}), color: 'qrcode', className: 'qrcode-eye' }}>
							<Field
								{...{ classNameGroup: 'frame-eye qrcode-grid row row-small', classNameLabel: 'col-auto' }}
								{...{ component: Radios, name: 'eyeShape', options, control: <Control />}}
								{...{ onChange, value: (eyeShape['top_left'] || {}).type }}
							/>
						</WidgetBox>

						<WidgetBox {...{ title: 'qr:eyes.eyeInner'.t({ value: ''}), color: 'qrcode', className: 'qrcode-eye' }}>
							<Field
								{...{ classNameGroup: 'frame-eye qrcode-grid row row-small', classNameLabel: 'col-auto' }}
								{...{ component: Radios, name: 'eyeInner', options: optionsFrame, control: <ControlFrame />}}
								{...{ onChange, value: (eyeInner['top_left'] || {}).type }}
							/>
						</WidgetBox>
					</Box>
				</>}

				{(!!activeTab) && <>
					<Box sx={{ padding: '0 15px', margin: '15px 0'}} className={'px-3 mb-3'}>
						<Tabs className={'row row-none border-bottom'} style={{ minHeight: '36px', padding: '0px' }} value={activeCollapse} onChange={collapseToggle}>
							{['top_left', 'top_right', 'bottom_left'].map((value, idx)=>
								<Tab key={idx} className={clsx('btn col text-inneractive text-h4 font-weight-normal')} style={{ minHeight: '36px', padding: '0px' }}>{`qr:eyes.${value}`.t()}</Tab>)
							}
						</Tabs>
					</Box>
					<div>
						{['top_left', 'top_right', 'bottom_left'].map((val, idx) => <Fragment key={idx}>
							{(activeCollapse === idx) && <>
								<WidgetBox {...{
									title: 'qr:eyes.eyeShape'.t({value: (idx + 1)}),
									color: 'qrcode',
									className: 'qrcode-eye'
								}}>
									<Field
										{...{classNameGroup: 'frame-eye qrcode-grid row row-small', classNameLabel: 'col-auto'}}
										{...{component: Radios, name: `eyeShape.${val}.type`, options, control: <Control/>}}
										{...{value: eyeShape[val]?.type, onChange: setFieldValue}}
									/>
								</WidgetBox>

								<WidgetBox {...{
									title: 'qr:eyes.eyeInner'.t({value: (idx + 1)}),
									color: 'qrcode',
									className: 'qrcode-eye'
								}}>
									<Field
										{...{classNameGroup: 'frame-eye qrcode-grid row row-small', classNameLabel: 'col-auto'}}
										{...{
											component: Radios,
											name: `eyeInner.${val}.type`,
											options: optionsFrame,
											control: <ControlFrame/>
										}}
										{...{value: eyeInner[val]?.type, onChange: setFieldValue}}
									/>
								</WidgetBox>
							</>}
						</Fragment>)}
					</div>
				</>}
			</>
		</Box>
	</>)
})


export default BaseForm;