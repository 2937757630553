/**
 *
 * @param onChange
 * @param name
 * @param rest
 */
export const menuMap = (onChange: any, name: string, rest?: any) => (icon: any, data: any, title: string, disabled?: boolean) =>{
	let { withoutChange, handleClick, ...itemProps } = rest || {}
	let type = data;

	if(typeof data === 'object'){
		type = data?.value;
	}

	if(!withoutChange && typeof data !== 'object'){
		data = `${data}`.camelCase();
	}

	type = `${type}`.camelCase()

	handleClick = handleClick || ((name: string, type: string) => (e: any) =>{
		e && e.preventDefault();
		onChange(name, type)
	})

	return ({
		...itemProps,
		icon,
		title,
		data,
		disabled,
		tooltip: `tooltip-${type}`,
		handleClick: handleClick(name, data)
	})
}


/**
 *
 * @param args
 */
export const map = (...args: any[]) => {
	let [options, value, ...rest] = args;
	let [_, v] = rest;
	let isActive = (value === v)
	// @ts-ignore
	return ({ isActive, ...menuMap(...options)(...rest)})
}

/**
 * Map
 * @param args
 */
export function addMap(...args:any[]){
	let [items, ...rest] = args;
	items.push(map(...rest))
}

export default addMap;