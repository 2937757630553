import React from 'react';
import Base from '@mui/material/Tab';
import clsx from 'clsx';

const Tab = ({ Wrap, value, children, className, active, ...props}) => (<Base
	{...{ ...props, className: clsx(className, 'btn-tab', {'Mui-selected': active }),
		value: `${value}`
	}}
	label={children || null}
/>)

export default Tab;
