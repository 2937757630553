import React from "react";
import dynamic from "next/dynamic";
import compose from "recompose/compose";
import renderCallback from "@xem/entity/classes/renderCallbackR";

const Form = dynamic(() => import('./Form').then((c) => c.default));
// import Form from './Form'

const DataForm = ((props) => (
	<>{!__SERVER__ && (!!props?.isOpen) && <><Form {...props} /></>}</>));

let refCreate = React.createRef();
let refUpdate = React.createRef();
let refChildren = React.createRef();
let refSpouse = React.createRef();
export default compose(
	renderCallback(refCreate, 'gen.people.add', DataForm),
	renderCallback(refUpdate, 'gen.people.update', DataForm),
	renderCallback(refChildren, 'gen.people.children', DataForm),
	renderCallback(refSpouse, 'gen.people.spouses', DataForm),
)

