
/**
 * Import
 */
import FrameThumb0 from "./frame/frame0.svg";
import FrameThumb1 from "./frame/frame1.svg";
import FrameThumb2 from "./frame/frame2.svg";
import FrameThumb3 from "./frame/frame3.svg";
import FrameThumb4 from "./frame/frame4.svg";
import FrameThumb5 from "./frame/frame5.svg";
import FrameThumb6 from "./frame/frame6.svg";
import FrameThumb7 from "./frame/frame7.svg";
import FrameThumb8 from "./frame/frame8.svg";
import FrameThumb9 from "./frame/frame9.svg";
import FrameThumb10 from "./frame/frame10.svg";
import FrameThumb11 from "./frame/frame11.svg";
import FrameThumb12 from "./frame/frame12.svg";
import FrameThumb13 from "./frame/frame13.svg";
import FrameThumb14 from "./frame/frame14.svg";
import FrameThumb15 from "./frame/frame15.svg";
import FrameThumb16 from "./frame/frame16.svg";
import FrameThumb17 from "./frame/frame17.svg";
import FrameThumb18 from "./frame/frame18.svg";
import FrameThumb19 from "./frame/frame19.svg";
import FrameThumb20 from "./frame/frame20.svg";
import FrameThumb21 from "./frame/frame21.svg";
import FrameThumb22 from "./frame/frame22.svg";
import FrameThumb23 from "./frame/frame23.svg";
import FrameThumb24 from "./frame/frame24.svg";
import FrameCircle from "./frame/circle.svg";

/**
 * Frame
 * @type {({icon: *})[]}
 */
export const Frame = [
  { frame: 'frame0', icon: FrameThumb0, font: "0" },
  { frame: 'frame1', icon: FrameThumb1, font: "0", width: 1000, height: 200 },
  { frame: 'frame2', icon: FrameThumb2, font: "1", width: 800, height: 200 },
  { frame: 'frame3', icon: FrameThumb3, font: "2", width: 1000, height: 200 },
  { frame: 'frame4', icon: FrameThumb4, font: "3", width: 1000, height: 200 },
  { frame: 'frame5', icon: FrameThumb5, font: "4", width: 1000, height: 200 },
  { frame: 'frame6', icon: FrameThumb6, font: "5", width: 1000, height: 200 },
  { frame: 'frame7', icon: FrameThumb7, font: "1", width: 1000, height: 200 },
  { frame: 'frame8', icon: FrameThumb8, font: "2", width: 1000, height: 200 },
  { frame: 'frame9', icon: FrameThumb9, font: "3", width: 680, height: 200 },
  { frame: 'frame10', icon: FrameThumb10, font: "4", width: 1000, height: 200 },
  { frame: 'frame11', icon: FrameThumb11, font: "5", width: 1000, height: 200 },
  { frame: 'frame12', icon: FrameThumb12, font: "1", width: 1000, height: 200 },
  { frame: 'frame13', icon: FrameThumb13, font: "2", width: 1000, height: 200 },
  { frame: 'frame14', icon: FrameThumb14, font: "3", width: 1000, height: 200 },
  { frame: 'frame15', icon: FrameThumb15, font: "4", width: 1000, height: 200 },
  { frame: 'frame16', icon: FrameThumb16, font: "5", width: 1000, height: 200 },
  { frame: 'frame17', icon: FrameThumb17, font: "0", width: 1000, height: 200 },
  { frame: 'frame18', icon: FrameThumb18, font: "1", width: 1000, height: 200 },
  { frame: 'frame19', icon: FrameThumb19, font: "2", width: 1000, height: 200 },
  { frame: 'frame20', icon: FrameThumb20, font: "3", width: 1000, height: 200 },
  { frame: 'frame21', icon: FrameThumb21, font: "3", width: 1000, height: 200 },
  { frame: 'frame22', icon: FrameThumb22, font: "3", width: 1000, height: 200 },
  { frame: 'frame23', icon: FrameThumb23, font: "3", width: 1000, height: 200 },
  { frame: 'frame24', icon: FrameThumb24, font: "3", width: 1000, height: 200 },
  { frame: 'circle', icon: FrameCircle, font: "0", isCircle: true, disableText: true }
]
