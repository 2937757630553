import React from 'react';
import TextField from "./TextField";
/**
 *
 * @type {function(*=): *}
 */
const Color = function RenderColor(props){
  let colorProps = props?.colorProps || {}

  if(props?.onClick){
    Object.assign(colorProps, {
      onClick: props?.onClick
    })
  }

  return (<TextField
    {...props}
    {...{
      colorIcon: { colorProps },
      endProps: {
        sx: {
          zIndex: 2, cursor: 'pointer',
          '&> div > .btn':{
            borderRadius: 0,
            boxShadow: '0px 0px 1px 1px rgba(69, 65, 78, 0.16)',
            padding: 0,
            marginRight: 0,
            textAlign: 'right',
            color: props?.input?.value || '#000',
            svg:{
              width: '100%',
              height: '100%',
              padding: 0
            }
          },
        }}
    }} />)
};

export default Color;
