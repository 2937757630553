export const loading = async ({ start, done, catchCallback, ...props } = {}, funcCallback) => {
  funcCallback = funcCallback || (() => {});
  func(start)();

  let end = function(){
    func(done)();
  }

  return new Promise(async (resolve, reject) => {
    try {
      let res = await funcCallback();
      end(); resolve(res);
    } catch (e) {
      end();


      if(catchCallback){
        catchCallback(props, resolve, reject)(e);
      }else{
        resolve(null);
      }
    }
  });
};

export default loading;