const TC = require('./TryCatch').default

/**
 *
 * @type {(callback: any, props?: any) => Promise<unknown>}
 */
tryCatch = TC;


/**
 *
 * @param func
 * @returns {(function(*): void)|*}
 */
func = (func)=>{
	return function (...args){
		try{
			func && func(...args);
		}catch (e) {}
	}
}

/**
 *
 * @param type
 * @returns {boolean}
 */
isTrue = (type)=>{
	return `${process.env[type]}`.toLowerCase() === 'true'
}