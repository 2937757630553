import React from 'react';
import TextField from "./TextField";

/**
 *
 * @type {function(*=): *}
 */
const Dropdown = function RenderDropdown(props){
  let dropdownProps = props?.dropdownProps || {}

  if(props?.onClick && !props?.disabled){
    Object.assign(dropdownProps, {
      onClick: props?.onClick
    })
  }

  return (<TextField {...props} {...{ dropdownIcon:{ dropdownProps }, endProps: {
    sx: {
    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2,
    cursor: 'pointer',
      '&> div, &> div > .btn':{
        width: '100% !important',
        height: '100% !important',
      },
      '&> div > .btn':{
        textAlign: 'right',
        paddingRight: '10px !important'
      },
  }} }} />)
};

export default Dropdown;
