import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";
import React from "react";

/**
 * @QrProvider(
 *   id = "cross",
 *   title = @Translation("Cross")
 * )
 */
export class RendererCross extends PointBase {
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "x", "y", "scale"]);
			let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
			$scale = $scale * 15;
			delete $opts['x'];
			delete $opts['y'];
			delete $opts['scale'];
			$opts = {...$opts,
				"transform": `translate(${$x},${$y}) scale(${$scale})`
			}

			return <g {...$opts}><polygon points="6,1.5 4.5,1.5 4.5,0 1.5,0 1.5,1.5 0,1.5 0,4.5 1.5,4.5 1.5,6 4.5,6 4.5,4.5 6,4.5"/></g>;
		}

		$options = {...$options, "pos": pointRender, "align": pointRender,}

		super($options, $qrcode);
	}

}
