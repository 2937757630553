import React from 'react';
type Props = {
  src: string,
  width: string | number | null,
  height: string | number | null,
}

export default class ImageBase extends React.Component {
  protected observer: any;
  protected preloader: any;
  protected el: any;
  static get defaultProps() {
    return {
      debug: false,
      width: null,
      height: null,
      src: ''
    };
  }

  constructor(props: Props & any) {
    super(props);
    this.state = { loaded: false, src: null, blob: null }
    this.setObserver = this.setObserver.bind(this);
    this.setPreloader = this.setPreloader.bind(this);
  }

  setObserver() {
    const self = this;
    const { src }: any = self.props;
    let { observer }: any = this;
    observer = new IntersectionObserver(entries => {
      try {
        entries.forEach(entry => {
          if (entry.isIntersecting && !!src) {
            self.setPreloader(self, src);
            observer.disconnect();
          }
        });
      } catch (e) {}
    });

    observer.observe(this.el);
  }

  imagePreloader(src: string){
    let self = this;
    self.preloader = new Image();
    // @ts-ignore
    function onload (){
      self.setState({ loaded: true, src: this.src, width: this.width, height: this.height });
    }

    self.preloader.onload = onload;
    // @ts-ignore
    self.preloader.src = src
  }

  setPreloader(self: any, src: string) {
    if (typeof Image !== 'undefined') {
      self.preloader = new Image();
      if (!!src && !self.state.loaded) {
        self.imagePreloader(src);
      }
    }
  }


  componentDidUpdate({}, {}){
    // @ts-ignore
    let stateSrc = this.state?.src, propsSrc = this.props?.src
    if(stateSrc !== propsSrc){
      this.imagePreloader(propsSrc);
    }
  }

  componentDidMount() {
    const { lazy, src }: any = this.props;
    if (!!lazy && 'IntersectionObserver' in window) {
      this.setObserver();
    } else if (src) {
      this.setPreloader(this, src);
    }
  }

  UNSAFE_componentWillReceiveProps({ src }: any, {}: any) {
    if (!!src) {
      this.setPreloader(this, src);
    }
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
    this.setState({ ...this.state, loaded: false, src: null });
    if (this.preloader) {
      this.preloader.onload = null;
    }
  }

}
