import React from 'react';
import Style from "../helpers/style/Styles";
import Box from "@mui/material/Box";
import withBlocks from "./form/blocks/renders";
import { Helmet } from 'react-helmet';
import compose from "recompose/compose";

/**
 * PageView
 * @type {React.ComponentClass<unknown>}
 */
const PageView = compose(withBlocks, Style)((props) => {
  let { onActions, entity, onSubmit } = props;
  let blocks = entity?.blocks || [], settings = entity?.settings;

  const blockRenders = props?.blockRenders || {};

  let styles, bodySx= {}, entities = [];
  (blocks || []).map(({ key, ...value })=>
    entities.push({ key, value: {...value, nid: entity?.id, onSubmit, onActions } }));

  if(settings?.alignment){
    Object.assign(bodySx, {
      alignItems: settings?.alignment,
    })
  }

  /**
  styles = `
  html.theme-page{
    background: ${settings?.background};
    body{
      background-color: #ffffff00;
    }
   }
  `

  if(settings?.background){
    Object.assign(bodySx, {
      background: '#ffffff00',
    })
  }
  /*/

  if(settings?.background){
    Object.assign(bodySx, { background: settings?.background });
  }
  /**/

  let sx= {
    '&':{
      ...bodySx,
      width: '100%',
      display: 'flex',
      minHeight: 'calc(100%)',
      '& div.icon':{
        'WebkitMaskSize': 'contain',
        'WebkitMaskRepeat': 'no-repeat',
        'WebkitMaskPosition': 'center',
        'maskSize': 'contain',
        'maskRepeat': 'no-repeat',
        'maskPosition': 'center'
      }
    }
  }

  let keys = Object.keys(blockRenders);

  return (<>
      {/*<Helmet>*/}
      {/*  {(!!useMediaQuery('(max-width:450px)')) && <meta name="viewport" content="width=375px, user-scalable=no"/>}*/}
      {/*</Helmet>*/}
      {(!!settings?.schema && !!__SERVER__) && <Helmet encodeSpecialCharacters={false}>
        <script type="application/ld+json">
          {settings.schema}
        </script>
      </Helmet>}

      {(!!styles) && <Helmet encodeSpecialCharacters={false}>
        <style type="text/css">{styles}</style>
      </Helmet>}
      <Box sx={sx}>
        <Box sx={{
          width: '100%',
          '& .block-wrapper': {
            padding: '0',
            '&:first-of-type':{
              paddingTop: '0',
            },
            '&:last-of-type':{
              paddingBottom: '0',
            }
          }
        }}>
          {(!!(entities && entities.length)) && <>
            {entities.map(({ value, key }, index)=>{
              let render, { type } = value;
              if(keys.includes(type)){
                debug({ value })
                render = blockRenders[type](value);
              }
              return <React.Fragment key={index}>{render}</React.Fragment>
            })}
          </>}
        </Box>
      </Box>
    </>
  )
});

export default PageView;
