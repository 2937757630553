import React from "react";
import opt from "@gqlapp/base/utils/opt";
import Radio from "@mui/material/Radio";

const Control = function Control(options){
	return function Control(props){
		let optValue = parseInt(props?.value || 0);
		let value = opt(options, optValue);
		let Icon = value?.icon || (()=><></>);
		let IconData = <Icon />
		return <Radio
			{...props}
			{...{
				checkedIcon: IconData,
				icon: IconData
			}}
		/>
	}
}

export default Control;