const Router= require("next/router").default;
const isEmptyBase 		= require('@gqlapp/base/utils/isEmpty').default;
const isArrayBase 		= require('@gqlapp/base/utils/isArray').default;
const isObjectBase 		= require('@gqlapp/base/utils/isObject').default;
const isStringBase 		= require('@gqlapp/base/utils/isString').default;
const isDefinedBase 	= require('@gqlapp/base/utils/isDefined').default;
const isIntegerBase 	= require('@gqlapp/base/utils/isInteger').default;
const isNumberBase 		= require('@gqlapp/base/utils/isNumber').default;

isEmpty   = (value) => !!isEmptyBase(value);
isArray   = (value) => !!isArrayBase(value);
isObject  = (value) => !!isObjectBase(value);
isString  = (value) => !!isStringBase(value);
isDefined = (value) => !!isDefinedBase(value);
isInteger = (value) => !!isIntegerBase(value);
isNumber  = (value) => !!isNumberBase(value);

/**
 * message
 * @param value
 */
msg = (value) =>{
	console.warn({ value })
}

/**
 *
 * @param value
 * @returns {*|boolean}
 */
isHttps         = (value) => value && /^(https)/i.test(value);


/**
 *
 * @param value
 * @returns {*|boolean}
 */
hasSchema = function (value) {
	return ((/^(https)/i.test(value) || /^(http)/i.test(value)));
}


/**
 * historyPush
 * @param value
 */
historyPush = (value) =>{
	try{
		Router.replace(value).then(()=>{})
	}catch (e) {}
}

/**
 * errorCatch
 * @param value
 */
errorCatch = (value) =>{
	console.warn({ value })
}

/**
 *
 */
historyBack = () =>{
	historyPush('/')
}

$$ = (e) => { try { return document.querySelector(e) }catch (e) {}};


serverSideProps = ({ req }) =>{
	try{setUserAgent(req.headers['user-agent'])}catch (e) {}
}

getGraphQLContext = ({ req: { headers } = {} }) =>{
	return { context: { headers }}
}
