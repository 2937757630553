import url from 'url';
import settings from '@gqlapp/config';

export const serverPort =
  PLATFORM === 'server' && (process.env.PORT || (typeof __SERVER_PORT__ !== 'undefined' ? __SERVER_PORT__ : 3000));
export const isApiExternal = !!url.parse(__API_URL__).protocol;


const addPort = (baseurl: string) => {
  let port = process.env.PORT || __SERVER_PORT__ || 3000;
  if(PLATFORM === 'web'){
    port = window.location.port ? window.location.port : ''
  }
  if(`${port}`.length > 2){
    port = `:${port}`
  }
  return `${baseurl}${port}`;
}


const getApiUrl = (path:string, original: string) => {
  if(!!url.parse(`${original}`).protocol){
    return original;
  }

  let baseurl = 'http://localhost';
  if(PLATFORM === 'web'){
    baseurl = `${window.location.protocol}//${window.location.hostname}`
  }
  return `${addPort(baseurl)}${path}`;
}

// @ts-ignore
export const apiServerUrl: string = getApiUrl('/api/graphql', settings.app.server);

// @ts-ignore
export const apiUrl: string = getApiUrl('/graphql', __SERVER__? process.env.API_URL : null);

// @ts-ignore
export const apiUpload: string = getApiUrl('/graphql', settings.app.upload);


