import { graphql } from 'react-apollo';
import QR_UPLOAD from '../graphql/QrUpload.graphql';

export default graphql(QR_UPLOAD, {
	props: ({ mutate }: any) => ({
		uploadFile: async ({ file }: any) => {
			try {
				const { data: { res } }: any = await mutate({ variables: { file }, fetchPolicy: 'no-cache' });
				return res;
			} catch (e) {
				return null;
			}
		}
	})
});