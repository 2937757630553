import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

const styles = {
  /* Styles applied to the root element. */
  root: {},
};

const RenderCheckBox = React.forwardRef(function RenderCheckBox({ sx, inline, onChange, formik, input, children, id, tag,  classLabel, className, classes, indeterminate, name, label, meta: { touched, error }, value , ...props }, ref) {
  tag = tag || Checkbox;
  id = id || name;

  let color = 'normal';
  if (touched && error) {
    color = 'danger';
  }

  const handleChange = ({ target }) => {
    onChange(name, !!target.checked);
  }

  return <>
    <FormControlLabel
      label={label}
      className={className}
      color={color}
      sx={sx}
      control={React.createElement(tag, {
        ...props,
        id,
        name,
        ref,
        indeterminate,
        checked: !!value,
        type: 'checkbox',
        onChange: handleChange,
        classes,
        className: clsx(classes.root, 'RenderCheckBox', 'align-self-center', classLabel, className),
      }, children)}
    />
    {touched && (error && <FormHelperText>{error}</FormHelperText>)}
  </>
});


RenderCheckBox.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.data.json file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  classes: PropTypes.any,
  indeterminate: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  input: PropTypes.object,
  label: PropTypes.any,
  type: PropTypes.string,
  meta: PropTypes.object
};

export default withStyles(styles, { name: 'MuiRenderCheckBox' })(RenderCheckBox);
