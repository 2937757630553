import React from "react";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import Box from "@mui/material/Box";
import Button from "reactstrap/lib/Button";
import FormBase from "reactstrap/lib/Form";
import compose from "./Filter.Utils";
import GenAdd from '../icons/genAdd.svg';
import GenUpdate from '../icons/genUpdate.svg';
import GenSlect from '../icons/SelectPoint.svg';
export default compose((props) =>{
  let { sx, handleSubmit, onGenCreate, onGenSelect, values:{ nodes } } = props;
  nodes = nodes || []

  return (<>
    <>
      <FormBase onSubmit={handleSubmit}>
        <Box sx={sx}>
          <Box sx={{ '& .btn': { padding: 0 }, width: '100%'}}>
            <Row >
              <Col>
                <Button color={'default'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 14" fill="currentColor" width="100" height="14"><g id="logo" transform="translate(-160 -21)"><path id="xem" d="M9.614,52.99l5.3-5.213H11.133L7.739,51.118,4.345,47.776H.543L5.838,52.99.543,58.179h3.8l3.394-3.342,3.394,3.342h3.776Zm7.142-5.213v10.4h9.9l2.368-2.328H19.949v-1.8h5.758l2.152-2.116h-7.91V50.1h7.1l2.35-2.327Zm30.563,0H43.711l-4.566,6.2-4.589-6.2H30.961v10.4h3.193V51.531l3.946,5.36h2.062l3.964-5.36v6.647l3.193,1.8v-12.2Z" transform="translate(159.457 -24.977)" fill="#f28721" fillRule="evenodd"></path><path id="link" d="M365.409,45.305V35.432l-3.193,1.813V47.633h9.9l2.367-2.327Zm13.147-9.874-3.193,1.814v10.37l3.193.018v-12.2Zm15.443,0-3.193,1.818v6.2l-7.684-6.216h-2.235V47.615l3.193.018V41.568l7.5,6.065H394v-12.2Zm11.013,1.8L399.5,42.344V37.231h-3.193V47.616l3.193.018V45.957l3.406-3.163,2.351,2.311v4.325l3.193-1.781v-3l-3.691-3.629,4.035-3.795Z" transform="translate(-148.798 -14.432)" fill="#06c" fillRule="evenodd"></path><path id="Ellipse_34" data-name="Ellipse 34" d="M1.95,0A1.933,1.933,0,0,1,3.9,1.916,1.933,1.933,0,0,1,1.95,3.833,1.933,1.933,0,0,1,0,1.916,1.933,1.933,0,0,1,1.95,0Z" transform="translate(208.14 29.89)" fill="#212121"></path></g></svg>
                </Button>
              </Col>
              { (!!nodes.length) && <Col xs={'auto'}>
                <Button color={'default'} onClick={onGenSelect}>
                  <GenSlect />
                </Button>
              </Col>}

              { (!nodes.length) && <Col xs={'auto'}>
                <Button color={'default'} onClick={onGenCreate}>
                  <GenAdd />
                </Button>
              </Col>}
              { (!!nodes.length) && <Col xs={'auto'}>
                <Button color={'default'} type={'submit'}><GenUpdate /></Button>
              </Col>}
            </Row>
          </Box>
        </Box>
      </FormBase>
    </>
  </>)
})