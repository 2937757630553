import React from "react";
import PointBase from './PointBase';
import { QRCode } from '../qrcode';
import { pointOptions } from "./ultil";

export class RendererRect extends PointBase {
	/**
	 *
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		// console.warn('RendererRect', { $options })
		const pointRender = ($options: any) => {
			Object.assign($options, { extend: 1 })
			let $opts = pointOptions($options, ["opacity", "fill", "x", "y", "width", "height"]);
			return <rect {...$opts} />
		}

		$options = {...$options,
			"pos": pointRender,
			"align": pointRender,
		}
		super($options, $qrcode);
	}
}
