import React  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { get as getPath } from 'lodash';
import withProps from 'recompose/withProps';

const propTypes = {
  formik: PropTypes.object.isRequired,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  onChanged: PropTypes.func,
  onBlur: PropTypes.func,
  onBlurred: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.any || PropTypes.array || PropTypes.object || PropTypes.string,
  defaultValue: PropTypes.any || PropTypes.array || PropTypes.object || PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool
};

let timeout = null;

const FieldAdapter = withProps(({
               formik, touched, name, defaultValue, defaultChecked, value, checked, disabled,
               onChanged: handleChanged, onBlurred: handleBlurred, onBlur: handleBlur,
               onChange: handleChange, onChangeText: handleChangeText,
               ...props
  })=>{
    const meta = {
      touched: touched || getPath(formik.touched, name),
      error: getPath(formik.errors, name)
    };

    const onChange = e => {
      if (handleChange) {
        handleChange(e.target.value, e);
      } else {
        formik.handleChange(e);
      }
      handleChanged && handleChanged(e)
    };

    const onBlur = e => {
      clearTimeout(timeout);
      if (handleBlur) {
        handleBlur(e);
      } else {
        if (PLATFORM === 'mobile') {
          formik.setFieldTouched(name, true);
        } else {
          timeout = setTimeout(()=> formik.handleBlur(e), 100);
        }
      }
      handleBlurred && handleBlurred(e)
    }

    const onChangeText = value => {
      if (handleChange && !handleChangeText) {
        handleChange(value);
      } else if (handleChangeText) {
        handleChangeText(value);
      } else {
        formik.setFieldValue(name, value);
      }
    };

    const events = { onChange, onChangeText }
    const input = {
      onBlur,
      name,
      value,
      checked,
      defaultValue,
      defaultChecked,
      disabled
    };

    const changeEventHandler = PLATFORM === 'mobile' ? 'onChangeText' : 'onChange';
    input[changeEventHandler] = events[changeEventHandler];
    return {...props, input, meta, formik, name, value }
  })
  (({ component, ...props}) => React.createElement(component, props));

FieldAdapter.propTypes = propTypes;

export default connect(FieldAdapter);
