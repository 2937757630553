import {useEffect, useState} from "react";

/**
 *
 * @param initialScrollState
 * @param element
 * @returns {React.Dispatch<React.SetStateAction<unknown>>}
 */
export const useScrollToTop = ({ initialScrollState = false, element = null }) => {
  const [config, setConfig] = useState({ initialScrollState });

  const setScrollToTop = (initialScrollState)=> setConfig({...config, initialScrollState})
  let scrollTop = 0;

  useEffect(() => {
    if (config?.initialScrollState) {
      setConfig({ initialScrollState: false });
      try {
        element = element || window;
        element.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }

    const onScroll = () => {
      let scroll = element.scrollTop;
      setConfig({...config, scroll})
    };
   try{
     element.addEventListener("scroll", onScroll);
     return () => {
       element.removeEventListener("scroll", onScroll);
     };
   }catch (e) {
     
   }
  }, [config, setConfig, setScrollToTop]);


  return [ setScrollToTop, config?.scroll ];
};

export default useScrollToTop;
