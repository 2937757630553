import Info from "./form/Info";

import infoIcon from './form/Info.Icon.svg';

export const options = [
	{
		icon: infoIcon,
		label: 'Info',
		value: 'info',
		render: Info
	}
]

export default options.map(({ render, ...rest})=> ({...rest, Form: render }));