import React from "react";
import ModalClass from "@gqlapp/base/Modal/ModalClass";
import {Footer, Header} from "@xem/look/utils/Popup";
import Button from 'reactstrap/lib/Button';

import Col from "@gqlapp/base/Col";
import Delete from "@xem/icon/base/Delete";

import { default  as Base, Tab } from "@gqlapp/base/Tabs";
import Badge from '@mui/material/Badge';
import Row from "@gqlapp/base/Row";
import Crop from "@xem/entity-client-react/helpers/popup/image/Crop";

const FormBase = ((props) => {
	let { sx, View, cancel, apply, values, toolProps, onDelete, handleSubmit, className, errors } = props
	const [ tabValue, setTab ] = React.useState('content')
	sx = sx || {}

	const onChange = (e, value)=> setTab(value);

	return (<ModalClass
		{...props}
		{...{ contentProps:{ sx }, className }}
		
		{...{
			footer: ({ onClose }) => (<Footer {...{
				suffix: (<Col xs={'auto'} sx={{ '& .btn':{ color: '#C21C21', padding: 0, border: 'none' }}}>
					<Button onClick={onDelete} className={'delete'} color={'default'}><Delete /></Button>
				</Col>),
				handleSubmit, onClick: ()=>onClose(), cancel, apply
			}} />)
		}}
	>
		<Crop {...props} />
	</ModalClass>)
})


export default FormBase;
