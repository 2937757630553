import saveStatus from '@gqlapp/base/utils/saveStatus';
import getPreview from "@xem/look/utils/getPreview";

export function getSize(options: any[], size: number){
  let preview = options[size]?.size
  return getPreview(preview)
}



/**
 * onUpload
 * @param Save
 * @param errorMsg
 */
export default function onUpload(Save: any, { errorMsg }: any)  {
  return async (input: any) => {
    const values = await Save(input);
    return saveStatus({ values, errorMsg });
  }
}


export function cropAndSave({ onUpdate, cropSave, refetch }){
  return async function (onClose: any, id: number, values: any) {
    if(id) await cropSave({ id, ...values });
    onUpdate({ images: [{ id }] })
      .then(func(refetch))
      .then(func(onClose))
      .catch(errorCatch)
      .catch(func(onClose));
  }
}


/**
 * @param onUpload
 * @param cropAndSave
 * @constructor
 */
export function ImageSave({ onUpload, cropAndSave }){
  /** **/
  return async function ImageSave({ id, file, x, y, size, zoom }, { options, onClose }){
    let [ width, height ] = getSize(options, size);
    x = Math.round(x);
    y = Math.round(y);
    
    if(!( x && y && width && height )) return;
    
    height = parseInt(`${height}`, 10);
    width = parseInt(`${width}`, 10);
    
    if(file && file.preview && onUpload){
      onUpload({ file })
        .then( ({ entity: { id  }}: any) =>
          cropAndSave(onClose, id, { x, y, width, height, zoom }))
        .catch(errorCatch)
    }
    else {
      await cropAndSave(onClose, id, { x, y, width, height, zoom });
    }
  }
}