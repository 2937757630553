import compose from './Vietbank.utils';
import React from "react";
import useScrollToTop from "@gqlapp/base/utils/useScrollToTop";
import Form from "../helpers/popup/qrcode/render/Form";
let timeout = null;
export default compose(function (rest){
	let { className, ...props } = rest;
	
	let zoomin = false, { scrollRef } = props;
	/** scroll top */
	const [scrollTop, scroll] = useScrollToTop({ initialScrollState: true, element: scrollRef?.current });

	if(scrollRef?.current){
		zoomin = scroll > 5;
	}

	function append() {
		return $$('body');
	}


	function setScrollTop(value){
		clearTimeout(timeout);
		timeout = setTimeout(function (){
			scrollTop(value);
		}, 100)
	}


	return (<><Form {...props } {...{ zoomin, append, setScrollTop}} /></>)
})